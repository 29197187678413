import React, { useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import { SearchInput } from '@/components/searchInput/searchInput';
import { ThemeRowItem } from '@/pages/activities/activityPosts/expandablePanels/themeRowItem';
import { Theme } from '@/types';
import styles from './themesModal.module.css';

interface Props {
  highlightThemes: Theme[];
  themes: Theme[];
  open: HTMLButtonElement | null;
  onSelect: (theme: Theme) => void;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

export const ThemesModal: React.FC<Props> = ({
  highlightThemes,
  open,
  onSelect,
  setAnchorEl,
  themes,
}) => {
  const [projectThemes, setProjectThemes] = useState<Theme[]>(themes);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setProjectThemes(
      themes.filter((theme) => theme.Name.toLowerCase().includes(searchInput.toLowerCase())),
    );
  }, [themes, searchInput]);

  return (
    <Popover
      anchorEl={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={styles.popover}
      onClose={() => setAnchorEl(null)}
      open={!!open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={styles.themes}>
        <div className={styles.modalTitle}>Themes</div>
        <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} />
        {projectThemes.map((theme) => (
          <ThemeRowItem
            isChecked={highlightThemes.some(
              (highlightTheme) => highlightTheme.DiscussionThemeId === theme.DiscussionThemeId,
            )}
            key={theme.DiscussionThemeId}
            onSelect={onSelect}
            theme={theme}
          />
        ))}
      </div>
    </Popover>
  );
};
