import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type {
  Activity,
  ActivityDetails,
  ActivityPost,
  ActivityStats,
  CurrentUser,
  DiscussionsResponse,
  PostRequestBody,
  StatsOverview,
  User,
  UserSettings,
} from '@/types';
import { filterHiddenTags } from '@/utils/filterHiddenTags';
import { getBaseUrl } from '@/utils/getBaseUrl';
import { session } from '@/utils/session';

export interface PostBasicArgs {
  discussionId: string;
  threadId: string;
}

interface PostToggleMandatoryArgs extends PostBasicArgs {
  IsMandatory: boolean;
}

interface ActivityArgsInput {
  discussionId: string;
  activityId: string;
}

interface PostArgsInput extends ActivityArgsInput {
  body: PostRequestBody;
}

interface EditPostArgsInput extends ActivityArgsInput {
  body: ActivityPost;
  threadId: string;
}

interface TaskMembersArgs {
  discussionId: string;
  aggregationType?: string;
}

export const api = createApi({
  reducerPath: 'api',
  tagTypes: ['Posts'],
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
    prepareHeaders: (headers) => {
      headers.set('x-auth', session.personToken.get());
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserSettings: builder.query<UserSettings, string>({
      query: (id) => ({
        url: `v3/taskmembersettings/mine`,
        params: {
          id: id,
        },
      }),
    }),

    getUserInfo: builder.query<CurrentUser[], string>({
      query: (discussionId) => ({
        url: `discussions/${discussionId}/participants/me`,
        params: {
          realAnonymity: true,
        },
        method: 'GET',
      }),
    }),

    getTaskMembers: builder.query<User[], TaskMembersArgs>({
      query: ({ discussionId, aggregationType }) => ({
        url: `v3/discussions/${discussionId}/taskmembers`,
        method: 'GET',
        params: {
          AggregationType: aggregationType || '',
        },
      }),
      transformResponse: (users: User[]) =>
        users.map((user) => ({ ...user, Tags: filterHiddenTags(user.Tags) })),
    }),

    getDiscussions: builder.query<DiscussionsResponse, string>({
      query: (discussionId) => ({
        url: `v3/discussions/${discussionId}`,
        method: 'GET',
      }),
    }),

    getStatsOverview: builder.query<StatsOverview, string>({
      query: (discussionId) => ({
        url: `v3/discussions/${discussionId}/overviewstats`,
        method: 'GET',
      }),
    }),

    getActivities: builder.query<Activity[], string>({
      query: (discussionId) => ({
        url: `v3/discussions/${discussionId}/activities`,
        method: 'GET',
      }),
    }),

    getActivityDetails: builder.query<ActivityDetails[], ActivityArgsInput>({
      query: ({ discussionId, activityId }) => ({
        url: `v3/discussions/${discussionId}/activities/${activityId}`,
        method: 'GET',
      }),
      providesTags: ['Posts'],
    }),

    getFormActivityStats: builder.query<ActivityStats, ActivityArgsInput>({
      query: ({ discussionId, activityId }) => ({
        url: `v3/discussions/${discussionId}/activities/${activityId}/stats`,
        method: 'GET',
      }),
    }),

    getPosts: builder.query<ActivityPost[], string>({
      query: (discussionId) => ({
        url: `v3/discussions/${discussionId}/threads`,
        method: 'GET',
      }),
    }),

    getActivityPosts: builder.query<ActivityPost[], ActivityArgsInput>({
      query: ({ discussionId, activityId }) => ({
        url: `v3/discussions/${discussionId}/activities/${activityId}/posts`,
        method: 'GET',
      }),
      providesTags: ['Posts'],
    }),

    sendActivityPost: builder.mutation<ActivityPost, PostArgsInput>({
      query: ({ discussionId, activityId, body }) => ({
        url: `v3/discussions/${discussionId}/activities/socialpost/${activityId}`,
        body,
        method: 'POST',
      }),
    }),

    editActivityPost: builder.mutation<ActivityPost, EditPostArgsInput>({
      query: ({ discussionId, activityId, threadId, body }) => ({
        url: `v3/discussions/${discussionId}/activities/socialpost/${activityId}`,
        body: { ...body, ThreadId: threadId },
        method: 'PUT',
      }),
    }),

    deletePost: builder.mutation<void, PostBasicArgs>({
      query: ({ discussionId, threadId }) => ({
        url: `v3/discussions/${discussionId}/threads/${threadId}`,
        method: 'DELETE',
      }),
    }),

    togglePinActivityPost: builder.mutation<void, PostBasicArgs>({
      query: ({ discussionId, threadId }) => ({
        url: `v3/discussions/${discussionId}/thread/togglepin`,
        method: 'PUT',
        body: { ThreadId: threadId },
      }),
    }),

    markAsReadPost: builder.mutation<void, PostBasicArgs>({
      query: ({ discussionId, threadId }) => ({
        url: `v3/discussions/${discussionId}/thread/markasdone`,
        method: 'PUT',
        body: { ThreadId: threadId },
      }),
    }),

    toggleResponseMandatory: builder.mutation<void, PostToggleMandatoryArgs>({
      query: ({ discussionId, threadId, IsMandatory }) => ({
        url: `v3/discussions/${discussionId}/thread/markasmandatory`,
        method: 'PUT',
        body: { ThreadId: threadId, IsMandatory },
      }),
    }),
  }),
});

export const {
  useGetUserSettingsQuery,
  useGetUserInfoQuery,
  useGetTaskMembersQuery,
  useGetDiscussionsQuery,
  useGetStatsOverviewQuery,
  useGetActivitiesQuery,
  useGetActivityPostsQuery,
  useGetPostsQuery,
  useGetActivityDetailsQuery,
  useGetFormActivityStatsQuery,
  useSendActivityPostMutation,
  useEditActivityPostMutation,
  useDeletePostMutation,
  useTogglePinActivityPostMutation,
  useMarkAsReadPostMutation,
  useToggleResponseMandatoryMutation,
} = api;
