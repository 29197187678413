import { api } from '.';

interface UserFilesData {
  Key: string;
  FileId: string;
  SignedS3Link: string;
}

interface SignatureData {
  policy: string;
  signature: string;
}

export const { useGetUserFilesS3Mutation, useGetS3SignatureMutation, useUploadToAmazonMutation } =
  api.injectEndpoints({
    endpoints: (builder) => ({
      getUserFilesS3: builder.mutation<UserFilesData, FormData>({
        query: (fileData) => ({
          url: '/userfiles/s3',
          method: 'POST',
          body: fileData,
        }),
      }),
      getS3Signature: builder.mutation<SignatureData, { expiration: string; conditions: any[] }>({
        query: ({ expiration, conditions }) => ({
          url: '/s3/signature',
          method: 'POST',
          params: { v4: true },
          body: { expiration, conditions },
        }),
      }),
      uploadToAmazon: builder.mutation<void, FormData>({
        query: (formData) => ({
          url: process.env.REACT_APP_AMAZON_URL as string,
          method: 'POST',
          body: formData,
          prepareHeaders: (headers: Headers) => {
            headers.set('Content-Type', 'multipart/form-data');
            return headers;
          },
        }),
      }),
    }),
  });
