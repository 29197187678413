import { Color, PostHighlight, PostHighlightInputBody, SuggestedHighlight } from '@/types';
import { api, PostBasicArgs } from '.';

interface ThreadHighlightsOutput {
  ThreadId: string;
  Highlights: PostHighlight[];
  Notes: string[];
  SuggestedHighlights: SuggestedHighlight[];
}

interface ThreadHighlightInput extends PostBasicArgs {
  body: PostHighlightInputBody;
}

interface ActivityHighlightsArgs {
  discussionId: string;
  activityId: string;
}

export const {
  useGetActivityHighlightsQuery,
  useGetHighlightColorsQuery,
  useGetSuggestedHighlightsQuery,
  useCreateNewHighlightMutation,
  useDeleteHighlightMutation,
  useDeleteSuggestedHighlightMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getHighlightColors: builder.query<Color[], string>({
      query: (discussionId) => ({
        url: `v3/discussions/${discussionId}/highlightcolors`,
        method: 'GET',
      }),
    }),

    getActivityHighlights: builder.query<PostHighlight[], ActivityHighlightsArgs>({
      query: ({ discussionId, activityId }) => ({
        url: `v3/discussions/${discussionId}/activities/${activityId}/threads/highlights`,
        method: 'GET',
      }),
    }),

    getSuggestedHighlights: builder.query<ThreadHighlightsOutput, PostBasicArgs>({
      query: ({ discussionId, threadId }) => ({
        url: `v3/discussions/${discussionId}/threads/${threadId}/moderationdata`,
        method: 'GET',
      }),
    }),

    deleteSuggestedHighlight: builder.mutation<void, PostBasicArgs & { suggestionId: string }>({
      query: ({ discussionId, threadId, suggestionId }) => ({
        url: `v3/discussions/${discussionId}/threads/${threadId}/summaries/keyPoints/${suggestionId}`,
        method: 'DELETE',
      }),
    }),

    createNewHighlight: builder.mutation<PostHighlight, ThreadHighlightInput>({
      query: ({ discussionId, threadId, body }) => ({
        url: `v3/discussions/${discussionId}/threads/${threadId}/highlights`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'POST',
        body: new URLSearchParams(body as any).toString(),
      }),
    }),

    deleteHighlight: builder.mutation<void, PostBasicArgs & { highlightId: string }>({
      query: ({ discussionId, threadId, highlightId }) => ({
        url: `v3/discussions/${discussionId}/threads/${threadId}/highlights/${highlightId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
