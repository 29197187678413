import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FirstPage as FirstPageIcon } from '@mui/icons-material';
import { Button, Collapse } from '@mui/material';
import cn from 'classnames';
import h337 from 'heatmap.js';
import { useAppSelector, useWindowDimensions } from '@/hooks';
import { ActivityDetails, ActivityPost, HeatmapData } from '@/types';
import styles from './heatmapImage.module.css';

interface Props {
  activityDetails: ActivityDetails;
  pinPosts: ActivityPost[];
}

export const HeatmapImage: React.FC<Props> = ({ activityDetails, pinPosts }) => {
  const { width: windowWidth } = useWindowDimensions();
  const imageRef = useRef<HTMLImageElement>(null);
  const heatmapRef = useRef(null);
  const [heatmapInstance, setHeatmapInstance] = useState<any>();
  const [showImage, setShowImage] = useState(true);
  const [heatmapData, setHeatmapData] = useState<HeatmapData[]>([]);
  const discussions = useAppSelector((state) => state.userSettings.discussions);
  const isRowLayout = discussions?.ActivitiesLayout === 4;

  useEffect(() => {
    if (heatmapRef.current) {
      setHeatmapInstance(
        h337.create({
          container: heatmapRef.current,
          maxOpacity: 0.5,
        }),
      );
    }
  }, []);

  useLayoutEffect(() => {
    if (imageRef.current) {
      const width = imageRef.current.getBoundingClientRect().width;
      const height = imageRef.current.getBoundingClientRect().height;

      const data = pinPosts.map(({ HeatMapX, HeatMapY }) => ({
        x: Math.round(HeatMapX * width),
        y: Math.round(HeatMapY * height),
        value: 1,
      }));
      setHeatmapData(data);
    }
  }, [windowWidth, pinPosts]);

  useEffect(() => {
    if (heatmapInstance) {
      const data = {
        min: 1,
        max: 1,
        data: heatmapData,
      };

      if (showImage) {
        heatmapInstance.setData(data);
      } else {
        heatmapInstance.setData({ data: [] });
      }
    }
  }, [heatmapData, heatmapInstance, showImage]);

  return (
    <div className={cn({ [styles.heatmapRowLayout]: isRowLayout })}>
      <div className={styles.heatmapImageWrapper} ref={heatmapRef}>
        <Collapse in={showImage}>
          <img
            alt="heatmap"
            className={styles.heatmapImage}
            ref={imageRef}
            src={activityDetails.HeatmapOptions?.HeatMapImageUrl}
          />
        </Collapse>
      </div>
      <Button
        className={styles.pinsImageToggleBtn}
        disableRipple
        onClick={() => setShowImage((prev) => !prev)}
        variant="text"
      >
        <FirstPageIcon className={cn(styles.iconHide, { [styles.iconHideActive]: showImage })} />
        {showImage ? 'Hide heatmap' : 'Show heatmap'}
      </Button>
    </div>
  );
};
