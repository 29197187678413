import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { BorderColor, DeleteForever } from '@mui/icons-material';
import { Button, Popover } from '@mui/material';
import { DeleteModal } from '@/components/deleteModal';
import { useAppSelector } from '@/hooks';
import { useDeleteHighlightMutation } from '@/store/api/highlights';
import { ClickPosition, PostHighlight } from '@/types';
import { EditHighlightModal } from './editHighlightModal';
import styles from './highlightCard.module.css';

interface Props {
  clickPosition?: ClickPosition;
  controlsAnchorEl: HTMLElement | null;
  highlight: PostHighlight;
  setHighlights: React.Dispatch<React.SetStateAction<PostHighlight[]>>;
  setControlsAnchorEl: (arg: null) => void;
}

export const HighlightsPopover: React.FC<Props> = ({
  clickPosition,
  controlsAnchorEl,
  highlight,
  setHighlights,
  setControlsAnchorEl,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const [deleteHighlight, { isLoading: isDeleting }] = useDeleteHighlightMutation();
  const toggleEditModal = () => {
    setShowEditModal((prev) => !prev);
    setControlsAnchorEl(null);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
    setControlsAnchorEl(null);
  };

  const onDelete = useCallback(
    (highlight: PostHighlight) => {
      setHighlights((prev) =>
        prev.filter(({ HighlightId }) => HighlightId !== highlight.HighlightId),
      );
    },
    [setHighlights],
  );

  const onEdit = useCallback(
    (editedHighlight: PostHighlight) => {
      setHighlights((prev) =>
        prev.map((highlight) =>
          highlight.HighlightId === editedHighlight.HighlightId ? editedHighlight : highlight,
        ),
      );
    },
    [setHighlights],
  );

  const handleDeleteHighlight = async () => {
    const res = await deleteHighlight({
      discussionId,
      threadId: highlight.ThreadId,
      highlightId: highlight.HighlightId,
    });

    if ('error' in res) {
      toast.error('Error: Unable to delete highlight. Please try again later.');
      return;
    }

    if (onDelete) {
      onDelete(highlight);
    }

    toggleDeleteModal();
  };

  return (
    <>
      <Popover
        anchorEl={controlsAnchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        anchorPosition={clickPosition ? clickPosition : { top: 0, left: 0 }}
        anchorReference={clickPosition ? 'anchorPosition' : 'anchorEl'}
        classes={{ paper: 'popover' }}
        onClose={() => setControlsAnchorEl(null)}
        open={!!controlsAnchorEl}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <div className={styles.popoverContent}>
          <Button className={styles.popoverButton} onClick={toggleEditModal}>
            <BorderColor className={styles.popoverIcon} />
            Edit
          </Button>
          <Button className={styles.popoverButton} onClick={toggleDeleteModal}>
            <DeleteForever className={styles.popoverIcon} />
            Delete
          </Button>
        </div>
      </Popover>

      <EditHighlightModal
        highlight={highlight}
        isOpen={showEditModal}
        onClose={toggleEditModal}
        onEdit={onEdit}
        postId={highlight.ThreadId}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        loading={isDeleting}
        objectName="highlight"
        onClose={toggleDeleteModal}
        onConfirm={handleDeleteHighlight}
      />
    </>
  );
};
