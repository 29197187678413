import { useMemo } from 'react';
import cn from 'classnames';
import { Word } from '@/types';
import styles from './subtitleWord.module.css';

interface Props {
  word: Word;
  progressTime: number;
  selectionStart: number | null;
  selectionEnd: number | null;
}

const TIME_OFFSET = 0.3;

export const SubtitleWord: React.FC<Props> = ({
  word,
  progressTime,
  selectionStart,
  selectionEnd,
}) => {
  const isCurrentWord = useMemo(() => {
    return Math.abs(progressTime - word.Time / 1000) < TIME_OFFSET;
  }, [progressTime, word.Time]);

  const isHighlighted = useMemo(() => {
    if (selectionStart && selectionEnd) {
      return word.Position >= selectionStart && word.Position <= selectionEnd;
    }

    return false;
  }, [selectionStart, selectionEnd, word.Position]);

  return (
    <div
      className={cn(styles.word, {
        [styles.wordPassed]: progressTime > word.Time / 1000 + TIME_OFFSET,
        [styles.currentWord]: isCurrentWord,
        [styles.highlightedWord]: isHighlighted,
      })}
      id={word.Position.toString()} // id is used to match a word position in handleSelection function
      key={word.WordId}
    >
      {word.Name}
    </div>
  );
};
