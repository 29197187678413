import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {
  AlternateEmail,
  AttachFile,
  AudioFileOutlined,
  Clear,
  HighlightOff,
  ImageOutlined,
  PictureAsPdf,
  PlayCircleOutline,
  SentimentSatisfiedAlt,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Avatar, Button, IconButton, Popover, Snackbar, Tooltip } from '@mui/material';
import cn from 'classnames';
import { addMinutes } from 'date-fns';
import { Loading } from '@/components/loading';
import { NOTIFICATION_DURATION, userRoles } from '@/constants';
import { useAppSelector, useClickOutside, useWindowDimensions } from '@/hooks';
import {
  useGetS3SignatureMutation,
  useGetUserFilesS3Mutation,
  useUploadToAmazonMutation,
} from '@/store/api/uploads';
import {
  ActivityPost,
  Attachment,
  Emoji,
  MentionList,
  MentionRequestInfo,
  ThreadData,
} from '@/types';
import { getFileTypes } from '@/utils/getFileTypes';
import { session } from '@/utils/session';
import styles from './threadForm.module.css';

interface Props {
  addNewPost?: boolean;
  attachments?: Attachment[];
  comments?: ActivityPost[];
  edit?: boolean;
  loading?: boolean;
  isChat?: boolean;
  onCancel?: () => void;
  onSubmit: (threadData: ThreadData) => void;
  placeholder?: string;
  post?: ActivityPost;
  submitButtonText?: string;
  text?: string;
  withoutAttachments?: boolean;
}

export const ThreadForm: React.FC<Props> = ({
  attachments: threadAttachments = [],
  addNewPost,
  comments,
  edit,
  loading,
  isChat,
  onCancel,
  onSubmit,
  placeholder = isChat ? 'Chat about this post...' : 'Reply to this post...',
  post,
  submitButtonText = 'Post',
  text: threadText,
  withoutAttachments,
}) => {
  const currentUserId = useAppSelector((store) => store.user.PersonId);
  const [text, setText] = useState(threadText || '');
  const [textareaHeight, setTextareaHeight] = useState(50);
  const [textAreaHasScroll, setTextAreaHasScroll] = useState(false);
  const [attachments, setAttachments] = useState(threadAttachments);
  const [attachmentUploading, setAttachmentUploading] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [mentionAnchorEl, setMentionAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isHideFormTooltips, setHideFormTooltips] = useState(true);
  const [mentionInfo, setMentionInfo] = useState<MentionRequestInfo | null>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const formRef = useRef<HTMLFormElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const emojiContainerRef = useClickOutside(() => setShowEmojiPicker(false));
  const { width: windowWidth } = useWindowDimensions();
  const emojisAmountInOneRow = windowWidth < 768 ? 6 : 8;

  useEffect(() => {
    const maxHeight = 150;
    if (textareaRef.current) {
      textareaRef.current.style.height = isChat ? '30px' : '50px';
      const newHeight = textareaRef.current.scrollHeight;

      if (newHeight > maxHeight) {
        setTextAreaHasScroll(true);
        textareaRef.current.style.overflowY = 'scroll';
        setTextareaHeight(maxHeight);
      } else {
        setTextAreaHasScroll(false);
        textareaRef.current.style.overflowY = 'hidden';
        setTextareaHeight(newHeight);
      }

      textareaRef.current.style.height = `${textareaHeight}px`;
    }
  }, [text, textareaHeight, isChat]);

  const mentionUserList = useMemo(() => {
    let userList: MentionList[] = [];

    if (post) {
      userList.push({
        userId: post.ThreadCreatorId,
        userName: post.ThreadCreatorName,
        userImage: post.ThreadCreatorUserImage,
        userMemberStatus: post.ThreadCreatorMembershipStatus,
      });
    }

    if (comments) {
      comments.forEach((comment) => {
        if (userList.find((user) => user.userId === comment.PostedById)) {
          return;
        }

        userList.push({
          userId: comment.PostedById,
          userName: comment.ThreadCreatorName,
          userImage: comment.ThreadCreatorUserImage,
          userMemberStatus: comment.ThreadCreatorMembershipStatus,
        });
      });
    }

    userList = userList.filter((user) => user.userId !== currentUserId);

    return userList;
  }, [post, comments, currentUserId]);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const hiddenImageInput = useRef<HTMLInputElement>(null);
  const handleAttachImageClick = () => {
    if (hiddenImageInput.current) {
      hiddenImageInput.current.click();
    }
  };

  const hiddenVideoInput = useRef<HTMLInputElement>(null);
  const handleAttachVideoClick = () => {
    if (hiddenVideoInput.current) {
      hiddenVideoInput.current.click();
    }
  };

  const hiddenAudioInput = useRef<HTMLInputElement>(null);
  const handleAttachAudioClick = () => {
    if (hiddenAudioInput.current) {
      hiddenAudioInput.current.click();
    }
  };

  const hiddenDocumentInput = useRef<HTMLInputElement>(null);
  const handleAttachDocumentClick = () => {
    if (hiddenDocumentInput.current) {
      hiddenDocumentInput.current.click();
    }
  };

  const [fetchUserFilesData] = useGetUserFilesS3Mutation();
  const [fetchSignatureData] = useGetS3SignatureMutation();
  const [uploadToAmazon] = useUploadToAmazonMutation();

  const uploadFile = async (file: File) => {
    const userFilesFormData = new FormData();
    userFilesFormData.set('Endpoint', process.env.REACT_APP_AMAZON_URL as string);
    userFilesFormData.set('Folder', 'userfiles/');
    userFilesFormData.set('Name', file.name);

    const userFilesRes = await fetchUserFilesData(userFilesFormData);

    if (!('data' in userFilesRes)) {
      throw new Error('Can not fetch user files data');
    }
    const userFilesData = userFilesRes.data;

    const currentDate = new Date();
    const filteredDate = currentDate.toISOString().replace(/[^a-zA-Z0-9]/g, '');
    const createDate = currentDate.toISOString();
    const expiryDate = addMinutes(currentDate, 5).toISOString();

    const signatureDataRes = await fetchSignatureData({
      expiration: expiryDate,
      conditions: [
        { acl: 'private' },
        { bucket: 'qualzy-staging-london' },
        { 'Content-Type': file.type },
        { success_action_status: '200' },
        { 'x-amz-algorithm': 'AWS4-HMAC-SHA256' },
        { key: userFilesData.Key },
        { 'x-amz-credential': process.env.REACT_APP_AMAZON_CREDENTIAL as string },
        { 'x-amz-date': filteredDate },
        { 'x-amz-meta-qqfilename': file.name },
        ['content-length-range', '0', '10485760'],
      ],
    });

    if (!('data' in signatureDataRes)) {
      throw new Error('Can not fetch signature data');
    }
    const signatureData = signatureDataRes.data;

    const formData = new FormData();
    formData.set('key', userFilesData.Key);
    formData.set('Content-Type', file.type);
    formData.set('success_action_status', '200');
    formData.set('acl', 'private');
    formData.set('x-amz-meta-qqfilename', file.name);
    formData.set('x-amz-algorithm', 'AWS4-HMAC-SHA256');
    formData.set('x-amz-credential', process.env.REACT_APP_AMAZON_CREDENTIAL as string);
    formData.set('x-amz-date', filteredDate);
    formData.set('policy', signatureData.policy);
    formData.set('x-amz-signature', signatureData.signature);
    formData.set('file', file);

    await uploadToAmazon(formData);

    const { fileType, innerFileType } = getFileTypes(file.type);

    return {
      ClientContentId: session.personToken.get(),
      Title: file.name,
      FileType: fileType,
      CreateDate: createDate,
      HasThumbnail: false,
      HasHQVersion: false,
      Files: [
        {
          UserFileId: userFilesData.FileId,
          Name: file.name,
          Extension: file.name.split('.').pop(),
          Link: userFilesData.SignedS3Link,
          Type: innerFileType,
          CreateDate: createDate,
        },
      ],
    } as Attachment;
  };

  const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }

    const newAttachments: Attachment[] = Array.from(event.target.files).map((file) => {
      const { fileType, innerFileType } = getFileTypes(file.type);
      const createDate = new Date().toISOString();

      return {
        rawFile: file,
        ClientContentId: session.personToken.get(),
        Title: file.name,
        FileType: fileType,
        CreateDate: createDate,
        HasThumbnail: false,
        HasHQVersion: false,
        Files: [
          {
            UserFileId: createDate,
            Name: file.name,
            Extension: file.name.split('.').pop(),
            Link: URL.createObjectURL(file),
            Type: innerFileType,
            CreateDate: createDate,
          },
        ],
      } as unknown as Attachment;
    });

    setAttachments((prev) => prev.concat(newAttachments));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!text.trim()) {
      return;
    }

    try {
      setAttachmentUploading(true);
      const oldAttachments = attachments.filter(({ rawFile }) => !rawFile);

      const requests = attachments
        .filter(({ rawFile }) => rawFile)
        .map(({ rawFile }) => uploadFile(rawFile as unknown as File));
      const newAttachments = await Promise.all(requests);

      onSubmit({
        text,
        attachments: oldAttachments.concat(newAttachments),
        mentionInfo,
      });

      setText('');
      setAttachments([]);
      setMentionInfo(null);
      setHideFormTooltips(true);

      if (textareaRef.current) {
        textareaRef.current.blur();
      }
    } catch {
      setShowErrorNotification(true);
    } finally {
      setAttachmentUploading(false);
    }
  };

  const removeAttachment = (fileId: string) => {
    setAttachments((prev) =>
      prev.filter((attachment) => attachment.Files[0].UserFileId !== fileId),
    );
  };

  const emojiPickerToggle = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
    setShowEmojiPicker((prevState) => !prevState);
  };

  const handleEmojiSelect = (emoji: Emoji) => {
    const ref = textareaRef.current;
    if (ref) {
      ref.focus();
      const start = text.slice(0, ref.selectionStart);
      const end = text.slice(ref.selectionStart);
      const textWithEmoji = start + emoji.native + end;
      setText(textWithEmoji);
      setCursorPosition(start.length + emoji.native.length);
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.selectionEnd = cursorPosition;
    }
  }, [cursorPosition]);

  const showFormTooltips = () => {
    setHideFormTooltips(false);
  };

  const hideFormTooltips = () => {
    setText('');
    setHideFormTooltips(true);
    if (onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    setText(threadText || '');
    if (threadText) {
      showFormTooltips();
    }
  }, [threadText]);

  const openMentionPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMentionAnchorEl(event.currentTarget);
  };

  const closeMentionPopover = () => {
    setMentionAnchorEl(null);
  };

  const handleMentionChoice = useCallback((userName: string, userId: string | null) => {
    setMentionInfo({
      userName,
      userId,
    });

    closeMentionPopover();
  }, []);

  useEffect(() => {
    if (isChat) {
      handleMentionChoice('Moderators & Observers', null);
    }
  }, [isHideFormTooltips, handleMentionChoice, isChat]);

  const handleErrorNotificationClose = () => {
    setShowErrorNotification(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();

      if (formRef.current) {
        formRef.current.requestSubmit();
      }
    }
  };

  useEffect(() => {
    if (textareaRef.current && (edit || addNewPost || isChat)) {
      const textarea = textareaRef.current;
      textarea.selectionStart = textarea.value.length;
      textarea.selectionEnd = textarea.value.length;
      textarea.focus();
    }
  }, [addNewPost, edit, isChat]);

  const isLoading = loading || attachmentUploading;

  return (
    <div className={styles.threadForm}>
      {isLoading && <Loading noOffset overContent />}
      <form
        className={cn(styles.form, {
          [styles.focusedForm]: !isHideFormTooltips,
          [styles.chatForm]: isChat,
        })}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <div className={styles.textAreaWrapper}>
          <textarea
            className={styles.input}
            onChange={handleTextChange}
            onFocus={showFormTooltips}
            onKeyDown={handleKeyPress}
            placeholder={placeholder}
            ref={textareaRef}
            required
            value={text}
          />
          {!isHideFormTooltips && (
            <Button
              className={cn(styles.commentFormButton, styles.clearTextButton, {
                [styles.clearBtnWithScroll]: textAreaHasScroll,
              })}
              onClick={hideFormTooltips}
            >
              <HighlightOff />
            </Button>
          )}
        </div>
        <div className={cn(styles.formBottomBar, { [styles.hidden]: isHideFormTooltips })}>
          {mentionInfo && (
            <div className={styles.mention}>
              <div>
                Comment will be visible{' '}
                <span className={styles.mentionUser}>{`only to ${mentionInfo?.userName}`}</span>
              </div>
              {!isChat && (
                <Button
                  className={cn(styles.commentFormButton, styles.clearMentions)}
                  onClick={() => setMentionInfo(null)}
                >
                  <HighlightOff />
                </Button>
              )}
            </div>
          )}

          <div
            className={cn(styles.formControls, {
              [styles.show]: !isHideFormTooltips,
            })}
          >
            {!withoutAttachments && (
              <div className={styles.tooltips}>
                <Tooltip title="Attach image">
                  <Button
                    aria-label="attach image"
                    className={styles.commentFormButton}
                    onClick={handleAttachImageClick}
                  >
                    <ImageOutlined />
                  </Button>
                </Tooltip>
                <input
                  accept="image/*"
                  className={styles.fileInput}
                  multiple
                  onChange={handleFileInput}
                  ref={hiddenImageInput}
                  type="file"
                />

                <Tooltip title="Attach video">
                  <Button
                    aria-label="attach video"
                    className={styles.commentFormButton}
                    onClick={handleAttachVideoClick}
                  >
                    <PlayCircleOutline />
                  </Button>
                </Tooltip>
                <input
                  accept="video/mp4, video/x-m4v, video/*"
                  className={styles.fileInput}
                  onChange={handleFileInput}
                  ref={hiddenVideoInput}
                  type="file"
                />

                {!isChat && (
                  <>
                    <Tooltip title="Attach audio (.mp3)">
                      <Button
                        aria-label="attach file"
                        className={styles.commentFormButton}
                        onClick={handleAttachAudioClick}
                      >
                        <AudioFileOutlined />
                      </Button>
                    </Tooltip>
                    <input
                      accept=".mp3"
                      className={styles.fileInput}
                      onChange={handleFileInput}
                      ref={hiddenAudioInput}
                      type="file"
                    />

                    <Tooltip title="Attach document (.pdf)">
                      <Button
                        aria-label="attach file"
                        className={styles.commentFormButton}
                        onClick={handleAttachDocumentClick}
                      >
                        <AttachFile />
                      </Button>
                    </Tooltip>
                    <input
                      accept=".pdf"
                      className={styles.fileInput}
                      onChange={handleFileInput}
                      ref={hiddenDocumentInput}
                      type="file"
                    />
                  </>
                )}

                <Tooltip title="Mention a user">
                  <Button
                    aria-label="mention person"
                    className={styles.commentFormButton}
                    onClick={openMentionPopover}
                  >
                    <AlternateEmail />
                  </Button>
                </Tooltip>

                <div className={styles.emojiContainer} ref={emojiContainerRef}>
                  <Tooltip title="Emoji">
                    <Button className={styles.commentFormButton} onClick={emojiPickerToggle}>
                      <SentimentSatisfiedAlt />
                    </Button>
                  </Tooltip>

                  {showEmojiPicker && (
                    <div className={styles.emojiPicker}>
                      <Picker
                        data={data}
                        onEmojiSelect={handleEmojiSelect}
                        perLine={emojisAmountInOneRow}
                      />
                    </div>
                  )}
                </div>

                <Popover
                  anchorEl={mentionAnchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  classes={{ paper: 'popover' }}
                  onClose={closeMentionPopover}
                  open={Boolean(mentionAnchorEl)}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className={styles.mentionPopoverContent}>
                    {!isChat && (
                      <>
                        {mentionUserList.map((user, index) => (
                          <div
                            className={styles.mentionItem}
                            key={user.userId + index}
                            onClick={() => handleMentionChoice(user.userName, user.userId)}
                          >
                            <Avatar className={styles.mentionAvatar} src={user.userImage} />
                            <div className={styles.mentionInfo}>
                              <div className={styles.mentionName}>{user.userName}</div>
                              <div className={styles.mentionRole}>
                                {userRoles[user.userMemberStatus]}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    <div
                      className={cn(styles.mentionItem, styles.mentionModerators)}
                      onClick={() => handleMentionChoice('Moderators & Observers', null)}
                    >
                      <div className={styles.mentionInfo}>
                        <div className={styles.mentionName}>Moderators & Observers</div>
                      </div>
                    </div>
                    {isChat && (
                      <>
                        <div
                          className={cn(styles.mentionItem, styles.mentionModerators)}
                          onClick={() => handleMentionChoice('Moderators', null)}
                        >
                          <div className={styles.mentionInfo}>
                            <div className={styles.mentionName}>Moderators</div>
                          </div>
                        </div>
                        <div
                          className={cn(styles.mentionItem, styles.mentionModerators)}
                          onClick={() => handleMentionChoice('Moderators and Qualzy', null)}
                        >
                          <div className={styles.mentionInfo}>
                            <div className={styles.mentionName}>Qualzy Support</div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Popover>
              </div>
            )}

            {!isChat && (
              <LoadingButton
                className={cn(styles.formButton, styles.submitButton)}
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                <span>{submitButtonText}</span>
              </LoadingButton>
            )}
          </div>
        </div>
      </form>

      {!!attachments.length && (
        <div className={styles.attachmentsContainer}>
          {attachments.map((attachment) => (
            <div className={styles.attachment} key={attachment.Files[0].UserFileId}>
              <IconButton
                className={styles.attachmentRemoveButton}
                onClick={() => removeAttachment(attachment.Files[0].UserFileId)}
              >
                <Clear />
              </IconButton>
              {attachment.FileType === 0 && (
                <img
                  alt="Post image"
                  className={styles.attachmentImage}
                  src={attachment.Files[0].Link}
                />
              )}

              {attachment.FileType === 1 && (
                <div className={styles.attachmentFiller}>
                  <PlayCircleOutline className={styles.attachmentIcon} />
                  <p className={styles.attachmentName}>{attachment.Files[0].Name}</p>
                </div>
              )}

              {attachment.FileType === 19 && (
                <div className={styles.attachmentFiller}>
                  <AudioFileOutlined className={styles.attachmentIcon} />
                  <p className={styles.attachmentName}>{attachment.Files[0].Name}</p>
                </div>
              )}

              {attachment.FileType === 2 && (
                <div className={styles.attachmentFiller}>
                  <PictureAsPdf className={styles.attachmentIcon} />
                  <p className={styles.attachmentName}>{attachment.Files[0].Name}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={NOTIFICATION_DURATION}
        onClose={handleErrorNotificationClose}
        open={showErrorNotification}
      >
        <Alert onClose={handleErrorNotificationClose} severity="error" sx={{ width: '100%' }}>
          Error. Please try again later
        </Alert>
      </Snackbar>
    </div>
  );
};
