import { NavLink } from 'react-router-dom';
import { Close, Menu } from '@mui/icons-material';
import { Button, Drawer } from '@mui/material';
import cn from 'classnames';
import { ReactComponent as Activities } from '@/assets/icons/activities.svg';
import { ReactComponent as People } from '@/assets/icons/people.svg';
import { useActions, useAppSelector } from '@/hooks';
import styles from './navigation.module.css';

interface Props {
  open?: boolean;
  className?: string;
}

export const Navigation: React.FC<Props> = ({ className, open = false }) => {
  const actions = useActions();
  const isOpen = useAppSelector((state) => state.drawer.isOpen);

  const toggleSidebar = () => {
    actions.toggleDrawer();
  };

  const handleNavigation = () => {
    actions.closeDrawer();
    actions.resetSelectedActivity();
    actions.resetSelectedUser();
  };

  return (
    <>
      <Drawer
        className={cn(styles.drawer, className, {
          [styles.drawerOpened]: isOpen,
          [styles.drawerMobile]: open,
        })}
        open={isOpen}
        variant="permanent"
      >
        <div>
          <nav className={styles.navigation}>
            <Button
              className={cn(styles.linkIconContainer, styles.closeSidebarButton)}
              onClick={toggleSidebar}
            >
              {isOpen ? <Close /> : <Menu />}
            </Button>
            <NavLink
              className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive })}
              onClick={handleNavigation}
              to="/activities"
            >
              <div className={styles.linkIconContainer}>
                <Activities className={styles.linkIcon} />
              </div>
            </NavLink>

            <NavLink
              className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive })}
              onClick={handleNavigation}
              to="/people"
            >
              <div className={styles.linkIconContainer}>
                <People className={styles.linkIcon} />
              </div>
            </NavLink>
          </nav>
        </div>
      </Drawer>
    </>
  );
};
