import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '@/hooks';
import { ActivityCard } from '@/pages/activities/activityPosts/activityCard';
import { HeatmapImage } from '@/pages/activities/heatmapSection/heatmapImage';
import { PinPost } from '@/pages/activities/pinsSection/pinPost';
import { useGetActivityDetailsQuery } from '@/store/api';
import { ImageSize } from '@/types';
import styles from './heatmapSection.module.css';

interface Props {
  activityId: string;
}

export const HeatmapSection: React.FC<Props> = ({ activityId }) => {
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const posts = useAppSelector((state) => state.posts);
  const { data: activityDetails } = useGetActivityDetailsQuery({
    discussionId,
    activityId,
  });
  const [pinsImageOriginalSize, setPinsImageOriginalSize] = useState<ImageSize | null>(null);

  useEffect(() => {
    if (activityDetails) {
      const img = new Image();
      img.onload = () => {
        setPinsImageOriginalSize({ width: img.naturalWidth, height: img.naturalHeight });
      };
      img.src = activityDetails[0].HeatmapOptions?.HeatMapImageUrl || '';
    }
  }, [activityDetails]);

  const pinPosts = useMemo(() => {
    return posts.filter((post) => post.HeatMapX && post.HeatMapY);
  }, [posts]);

  return (
    <div className={styles.heatmapSection}>
      {activityDetails && <HeatmapImage activityDetails={activityDetails[0]} pinPosts={pinPosts} />}
      {activityDetails && pinsImageOriginalSize && !!posts.length && (
        <div className={styles.postsWrapper}>
          {posts.map((post) => {
            if (post.HeatMapX && post.HeatMapY) {
              return (
                <PinPost
                  imageUrl={activityDetails[0].HeatmapOptions?.HeatMapImageUrl || ''}
                  key={post.TopicThreadId}
                  pinsImageOriginalSize={pinsImageOriginalSize}
                  post={post}
                />
              );
            }
            return <ActivityCard isPinsActivity key={post.TopicThreadId} post={post} />;
          })}
        </div>
      )}
    </div>
  );
};
