import { useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { FirstPage as FirstPageIcon } from '@mui/icons-material';
import { Button, Collapse } from '@mui/material';
import cn from 'classnames';
import { VideoAttachment } from '@/types';
import { VideoTranscript } from './videoTranscript';
import styles from './video.module.css';

interface Props {
  videoAttachment: VideoAttachment;
}

export const Video: React.FC<Props> = ({ videoAttachment }) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progressTime, setProgressTime] = useState(0);
  const [showTranscript, setShowTranscript] = useState(false);

  const videoUrl = useMemo(() => {
    return videoAttachment.Files.find((file) => file.Type === 6)?.Link || '';
  }, [videoAttachment.Files]);

  const handleSentencePlay = (startTime: number, isCurrentSentence: boolean) => {
    if (isCurrentSentence) {
      setIsPlaying((prev) => !prev);
      return;
    }

    if (playerRef.current) {
      playerRef.current.seekTo(startTime);
      setIsPlaying(true);
    }
  };

  return (
    <>
      <ReactPlayer
        className={styles.videoPlayer}
        controls
        height="100%"
        onPause={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
        onProgress={({ playedSeconds }) => {
          setProgressTime(playedSeconds);
        }}
        playing={isPlaying}
        progressInterval={500} // It updates progressTime state every 0.5s
        ref={playerRef}
        url={videoUrl}
        width="100%"
      />
      <Collapse in={showTranscript}>
        <VideoTranscript
          className={styles.postVideoTranscript}
          isPlaying={isPlaying}
          onSentencePlay={handleSentencePlay}
          progressTime={progressTime}
          transcriptSentences={videoAttachment.Sentences}
        />
      </Collapse>
      <Button
        className={styles.videoTranscriptCollapseBtn}
        disableRipple
        onClick={() => setShowTranscript((prev) => !prev)}
        variant="text"
      >
        <FirstPageIcon
          className={cn(styles.iconHide, { [styles.iconHideActive]: showTranscript })}
        />
        {showTranscript ? 'Hide transcript' : 'Show transcript'}
      </Button>
    </>
  );
};
