import { useMemo, useState } from 'react';
import { Masonry } from '@mui/lab';
import { ImageCarousel } from '@/components/imagesGallery/imageCarousel';
import { Loading } from '@/components/loading';
import { useAppSelector, useWindowDimensions } from '@/hooks';
import { ImageObject } from '@/types';
import { ImageCard } from './imageCard';
import styles from './imagesSection.module.css';

interface Props {
  rowLayout?: boolean;
}

export const ImagesSection: React.FC<Props> = ({ rowLayout }) => {
  const { width: windowWidth } = useWindowDimensions();
  const posts = useAppSelector((state) => state.posts);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);

  const handleOnLoad = () => {
    setLoadedImagesCount((prev) => prev + 1);
  };

  const images = useMemo(() => {
    const imagesList: ImageObject[] = [];
    posts.forEach((post) => {
      const postCreatorName = post.ThreadCreatorName;
      const postCreatorAvatar = post.ThreadCreatorUserImage;
      if (post.Attachments.length) {
        post.Attachments.forEach((attachment) => {
          const attachmentId = attachment.AttachmentId;
          if ([0, 12].includes(attachment.FileType) && attachment.Files.length) {
            imagesList.push({
              attachmentId,
              postCreatorName,
              postCreatorAvatar,
              ...attachment.Files[0],
            });
          }
        });
      }
    });
    return imagesList;
  }, [posts]);

  const isLoading = images.length !== loadedImagesCount;

  const columns = useMemo(() => {
    switch (true) {
      case windowWidth < 480:
        return 1;
      case windowWidth < 768:
        return 2;
      case windowWidth < 1024 || rowLayout:
        return 3;
      default:
        return 4;
    }
  }, [windowWidth, rowLayout]);

  return (
    <>
      <div
        className={styles.imagesSection}
        style={{ backgroundColor: isLoading || rowLayout ? 'none' : '#eff1f5' }}
      >
        {isLoading && <Loading />}
        <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
          <Masonry columns={columns} spacing={1.25}>
            {images.map((image, index) => (
              <ImageCard
                image={image}
                key={image.attachmentId}
                onClick={() => setSelectedImageIndex(index)}
                onLoad={handleOnLoad}
              />
            ))}
          </Masonry>
        </div>
      </div>

      <ImageCarousel
        images={images.map(({ Link }) => Link)}
        onClose={() => setSelectedImageIndex(null)}
        selectedImageIndex={selectedImageIndex}
      />
    </>
  );
};
