import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { Loading } from '@/components/loading';
import { NavigationDrawer } from '@/components/navigation/navigationDrawer';
import { TopSection } from '@/components/topSection';
import { useActions, useAppSelector, useWindowDimensions } from '@/hooks';
import { HeatmapSection } from '@/pages/activities/heatmapSection';
import { PinsSection } from '@/pages/activities/pinsSection';
import { VideosSection } from '@/pages/activities/videosSection';
import { useGetActivitiesQuery, useGetActivityPostsQuery } from '@/store/api';
import { ActivitySection } from '@/types';
import { ActivityDrawer } from './activityList/activityDrawer';
import { ActivityList } from './activityList';
import { ActivityPosts } from './activityPosts';
import { HighlightsSection } from './highlightsSection';
import { ImagesSection } from './imagesSection';
import styles from './activities.module.css';

export const Activities = () => {
  const actions = useActions();
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const discussions = useAppSelector((state) => state.userSettings.discussions);
  const selectedActivityId = useAppSelector((state) => state.selectedActivity.id);
  const activeSection = useAppSelector((state) => state.topSection.activeSection);
  const [openActivityDrawer, setOpenActivityDrawer] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: postsRes, isFetching } = useGetActivityPostsQuery(
    { discussionId, activityId: selectedActivityId },
    { skip: !discussionId || !selectedActivityId },
  );

  useLayoutEffect(() => {
    if (!postsRes) {
      return;
    }
    actions.setPosts(postsRes);
  }, [postsRes, actions]);

  const { width: windowWidth } = useWindowDimensions();
  const isDrawerOpen = useAppSelector((state) => state.drawer.isOpen) && windowWidth < 1440;

  const activityLayout = useMemo(() => {
    const layout = discussions?.ActivitiesLayout || 0;
    if (layout === 4 && windowWidth < 1024) {
      return 3; //row layout on mobile
    }
    return layout;
  }, [discussions, windowWidth]);

  const rowLayout = activityLayout === 4;

  const { data: activities, isFetching: isActivitiesFetching } = useGetActivitiesQuery(
    discussionId,
    {
      skip: !discussionId,
    },
  );

  useEffect(() => {
    if (activities) {
      actions.setActivities(activities);
      const { totalMessages, unreadMessages } = activities.reduce(
        ({ totalMessages, unreadMessages }, activity) => ({
          totalMessages: totalMessages + (activity.TotalPostsCount || 0),
          unreadMessages: unreadMessages + activity.ToDoCount,
        }),
        { totalMessages: 0, unreadMessages: 0 },
      );

      actions.setTotal(totalMessages);
      actions.setUnread(unreadMessages);
    }
  }, [activities, actions]);

  useEffect(() => {
    if (activities) {
      let activityID = searchParams.get('activityID');

      if (!activityID && rowLayout) {
        return;
      }

      if (!activityID) {
        const defaultActivity = activities.find((activity) => !activity.IsGroup);
        activityID = defaultActivity?.DiscussionTopicId || '';
        setSearchParams({ activityID });
      }

      const selectedActivity = activities.find(
        (activity) => activity.DiscussionTopicId === activityID,
      );

      if (selectedActivity) {
        actions.setSelectedActivity({
          id: activityID,
          name: selectedActivity.Title,
          type: selectedActivity.ContentType,
          unreadPosts: selectedActivity.ToDoCount,
        });
      }
    }
  }, [activities, searchParams, actions, setSearchParams, rowLayout]);

  const handleActivitySelect = (activityID: string) => {
    if (rowLayout) {
      setOpenActivityDrawer(true);
    }
    actions.closeDrawer();
    setSearchParams({ activityID });
  };

  const closeActivityDrawer = () => {
    setOpenActivityDrawer(false);
  };

  const handleToggleDrawer = () => {
    actions.toggleDrawer();
  };

  const activeSectionContent = useMemo(() => {
    return {
      [ActivitySection.Posts]: <ActivityPosts rowLayout={rowLayout} />,
      [ActivitySection.Highlights]: <HighlightsSection />,
      [ActivitySection.Images]: <ImagesSection rowLayout={rowLayout} />,
      [ActivitySection.Videos]: <VideosSection rowLayout={rowLayout} />,
      [ActivitySection.Pins]: <PinsSection activityId={selectedActivityId} />,
      [ActivitySection.Heatmap]: <HeatmapSection activityId={selectedActivityId} />,
    }[activeSection];
  }, [activeSection, rowLayout, selectedActivityId]);

  return (
    <div className={styles.wrapper}>
      {!rowLayout && <TopSection title="Activities" />}
      <NavigationDrawer onClose={handleToggleDrawer} open={isDrawerOpen}>
        <div className={styles.drawerList}>
          <h2 className={styles.drawerTitle}>Activities</h2>
          {activities && (
            <ActivityList
              activityLayout={activityLayout}
              data={activities}
              onActivitySelect={handleActivitySelect}
              rowLayout={rowLayout}
              selectedId={selectedActivityId}
            />
          )}
        </div>
      </NavigationDrawer>
      <div className={styles.main}>
        <div className={cn(styles.listContainer, { [styles.rowListContainer]: rowLayout })}>
          {isActivitiesFetching && <Loading overContent />}
          {activities && (
            <ActivityList
              activityLayout={activityLayout}
              data={activities}
              onActivitySelect={handleActivitySelect}
              rowLayout={rowLayout}
              selectedId={selectedActivityId}
            />
          )}
        </div>
        {rowLayout ? (
          <ActivityDrawer onClose={closeActivityDrawer} open={openActivityDrawer}>
            <TopSection onClose={closeActivityDrawer} rowLayout title="Activities" />
            {isFetching ? (
              <Loading className={styles.loader} />
            ) : (
              <div className={styles.contentWrapper}>{activeSectionContent}</div>
            )}
          </ActivityDrawer>
        ) : (
          <>
            {isFetching ? (
              <Loading className={styles.loader} />
            ) : (
              <div className={styles.contentWrapper}>{activeSectionContent}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
