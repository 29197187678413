import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedActivityState {
  id: string;
  name: string;
  type: number;
  unreadPosts: number;
}

const initialState: SelectedActivityState = {
  id: '',
  name: '',
  type: 0,
  unreadPosts: 0,
};

export const selectedActivitySlice = createSlice({
  name: 'selectedActivity',
  initialState,
  reducers: {
    setSelectedActivity(state, action: PayloadAction<SelectedActivityState>) {
      return { ...state, ...action.payload };
    },
    resetSelectedActivity() {
      return initialState;
    },
  },
});
