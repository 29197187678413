import { useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useWindowDimensions } from '@/hooks';
import { PinPostPopup } from '@/pages/activities/pinsSection/pinsImage/pinPostPopup';
import { ActivityPost, ImageSize } from '@/types';
import { calculatePinPopupPosition } from '@/utils/calculatePinPopupPosition';
import styles from './pin.module.css';

interface Props {
  post: ActivityPost;
  imageSize: ImageSize;
}

export const Pin: React.FC<Props> = ({ post, imageSize }) => {
  const { width: windowWidth } = useWindowDimensions();
  const pinBtnRef = useRef<HTMLButtonElement>(null);
  const [showPinPost, setShowPinPost] = useState(false);

  const pinCoordinates = useMemo(() => {
    return {
      x: imageSize.width * post.HeatMapX,
      y: imageSize.height * post.HeatMapY,
    };
  }, [imageSize, post.HeatMapX, post.HeatMapY]);

  const popupCoordinates = useMemo(
    () => calculatePinPopupPosition(post, imageSize, windowWidth),
    [post, imageSize, windowWidth],
  );

  return (
    <div className={styles.pinContainer} style={{ top: pinCoordinates.y, left: pinCoordinates.x }}>
      <button
        className={cn(styles.pinBtn, { [styles.pinActive]: showPinPost })}
        onClick={() => setShowPinPost((prev) => !prev)}
        ref={pinBtnRef}
        style={{ backgroundColor: post.Tags[0].TagColor }}
      />
      {showPinPost && (
        <PinPostPopup
          onClose={() => setShowPinPost(false)}
          outsideTriggerBtnRef={pinBtnRef}
          post={post}
          style={popupCoordinates}
        />
      )}
    </div>
  );
};
