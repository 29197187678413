import { Drawer } from '@mui/material';
import cn from 'classnames';
import { Navigation } from './navigation';
import styles from './navigation.module.css';

interface Props {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode | React.ReactNode[];
}

export const NavigationDrawer: React.FC<Props> = ({ open, onClose, children }) => {
  return (
    <Drawer
      className={cn('hideOnDesktop', styles.navigationDrawer, {
        [styles.drawerOpened]: open,
      })}
      onClose={onClose}
      open={open}
    >
      <div className={styles.wrapper}>
        <Navigation open />
        <div className={styles.contentWrapper}>{children}</div>
      </div>
    </Drawer>
  );
};
