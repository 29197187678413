import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import { Button, IconButton } from '@mui/material';
import cn from 'classnames';
import { ColorButton } from '@/components/topSection/colorButton';
import { HighlightingColorModal } from '@/components/topSection/highlightingColorModal';
import { useAppSelector } from '@/hooks';
import {
  useCreateNewHighlightMutation,
  useDeleteSuggestedHighlightMutation,
} from '@/store/api/highlights';
import {
  ActivityPost,
  Color,
  PostHighlight,
  PostHighlightInputBody,
  SuggestedHighlight,
} from '@/types';
import styles from './suggestedHighlightCard.module.css';

interface Props {
  suggestedHighlight: SuggestedHighlight;
  onCreate: (newHighlight: PostHighlight) => void;
  onDelete: (suggestedHighlight: SuggestedHighlight) => void;
  post: ActivityPost;
}

export const SuggestedHighlightCard: React.FC<Props> = ({
  suggestedHighlight,
  onCreate,
  onDelete,
  post,
}) => {
  const [createNewHighlight] = useCreateNewHighlightMutation();
  const [deleteSuggestedHighlight] = useDeleteSuggestedHighlightMutation();
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const colorsMap = useAppSelector((state) => state.topSection.colorsMap);
  const [selectedColor, setSelectedColor] = useState<Color | null>(null);
  const colorModalBtnRef = useRef<HTMLButtonElement>(null);
  const [showColorModal, setShowColorModal] = useState(false);
  const isModeratorPost = post.ThreadCreatorMembershipStatus === 6;

  const { Start, End, ThreadId, HighlightText, QuestionId, SuggestionId } = suggestedHighlight;

  const handleConfirm = async () => {
    if (!selectedColor) {
      toast.warning('Please select a color');
      return;
    }
    const body: PostHighlightInputBody = {
      Start,
      End,
      ThreadId,
      Text: HighlightText,
      QuestionId: QuestionId ?? '',
      'Color[HighlightColorId]': selectedColor.HighlightColorId,
    };

    if (suggestedHighlight) {
      const response = await createNewHighlight({
        discussionId,
        threadId: ThreadId,
        body,
      });

      if ('data' in response) {
        onCreate(response.data);
        await handleDelete();
      }
    }
  };

  const handleDelete = async () => {
    const response = await deleteSuggestedHighlight({
      discussionId,
      threadId: ThreadId,
      suggestionId: SuggestionId,
    });

    if ('error' in response) {
      toast.error('Error: Unable to delete suggested highlight. Please try again later.');
      return;
    }

    onDelete(suggestedHighlight);
  };

  const handleHighlightColor = (color: Color) => {
    setSelectedColor(color);
    setShowColorModal(false);
  };

  return (
    <>
      <div
        className={cn(styles.suggestedHighlightCard, {
          [styles.suggestedHighlightCardModerator]: isModeratorPost,
        })}
      >
        <div className={styles.text}>{HighlightText}</div>
        <div className={styles.footer}>
          <div className={styles.colorsContainer}>
            {colorsMap.slice(0, 6).map((color) => (
              <ColorButton
                active={selectedColor?.HighlightColorId === color.HighlightColorId}
                color={color}
                key={color.HighlightColorId}
                onClick={setSelectedColor}
                size={16}
              />
            ))}
            <div className={styles.colorModalWrapper}>
              <IconButton
                className={styles.controlsButton}
                onClick={() => setShowColorModal((prev) => !prev)}
                ref={colorModalBtnRef}
              >
                <MoreVertSharpIcon className={styles.controlsIcon} />
              </IconButton>
              <HighlightingColorModal
                highlightModalBtnRef={colorModalBtnRef}
                isOpen={showColorModal}
                onClose={() => setShowColorModal(false)}
                onColorSelect={handleHighlightColor}
                suggestedHighlightColor={selectedColor}
              />
            </div>
          </div>

          <Button className={styles.buttonConfirm} onClick={handleConfirm} variant="contained">
            Confirm
          </Button>
        </div>

        <Button className={styles.buttonRemove} onClick={handleDelete}>
          Remove Suggested Highlight
        </Button>
      </div>
    </>
  );
};
