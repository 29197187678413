import { useMemo } from 'react';
import { ActivityPost } from '@/types';

export const usePostComments = (comments: ActivityPost[]) =>
  useMemo(() => {
    const mainComments: ActivityPost[] = [];
    const chatComments: ActivityPost[] = [];
    comments.forEach((comment) => {
      if (!comment.Visibility || comment.VisibleToId) {
        mainComments.push(comment);
      }
      if (comment.Visibility && !comment.VisibleToId) {
        chatComments.push(comment);
      }
    });

    return { mainComments, chatComments };
  }, [comments]);
