import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import { LikesCounter } from '@/components/likesCounter';
import { Loading } from '@/components/loading';
import { ThreadForm } from '@/components/threadForm';
import { REPLY_SUGGESTIONS_COLLAPSE_DURATION } from '@/constants';
import { useActions, useAppSelector, useWindowDimensions } from '@/hooks';
import { usePostComments } from '@/hooks/usePostComments';
import { ActivityComment } from '@/pages/activities/activityPosts/activityComment';
import { PostControls } from '@/pages/activities/activityPosts/postControls';
import { RepliesChat } from '@/pages/activities/activityPosts/repliesChat';
import { ReplySuggestions } from '@/pages/activities/activityPosts/replySuggestions';
import { PinPostTag } from '@/pages/activities/pinsSection/pinPostTag';
import { useEditActivityPostMutation } from '@/store/api';
import { useGetPostRepliesQuery, useSendReplyMutation } from '@/store/api/replies';
import { ActivityPost, ImageSize, ThreadData } from '@/types';
import { formatDateTime } from '@/utils/formatDateTime';
import styles from './pinPost.module.css';

interface Props {
  imageUrl: string;
  post: ActivityPost;
  pinsImageOriginalSize: ImageSize;
}

export const PinPost: React.FC<Props> = ({ pinsImageOriginalSize, post, imageUrl }) => {
  const actions = useActions();
  const { width: windowWidth } = useWindowDimensions();
  const [edit, setEdit] = useState(false);
  const [editText, setEditText] = useState('');
  const [comments, setComments] = useState<ActivityPost[]>([]);
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageSize, setImageSize] = useState<ImageSize | null>(null);
  const [suggestReplyText, setSuggestReplyText] = useState('');
  const tagColor = post.Tags[0].TagColor;
  const [postRef, inView] = useInView({ triggerOnce: true });
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const threadFormContainer = useRef<HTMLDivElement | null>(null);
  const [isRepliesLoading, setIsRepliesLoading] = useState(false);
  const [isChatRepliesLoading, setIsChatRepliesLoading] = useState(false);

  const { id: activityId } = useAppSelector((state) => state.selectedActivity);
  const postCreationDate = formatDateTime(post.CreateDate);

  const [editPost, { isLoading: isPostUpdating }] = useEditActivityPostMutation();

  useEffect(() => {
    if (imageRef.current) {
      const width = imageRef.current.getBoundingClientRect().width;
      const height = imageRef.current.getBoundingClientRect().height;
      setImageSize({ width, height });
    }
  }, [windowWidth]);

  const pinPositionInPixels = useMemo(() => {
    return {
      x: -Math.round(post.HeatMapX * pinsImageOriginalSize.width),
      y: -Math.round(post.HeatMapY * pinsImageOriginalSize.height),
    };
  }, [post, pinsImageOriginalSize]);

  const imagePosition = useMemo(() => {
    if (imageSize) {
      let { x, y } = pinPositionInPixels;

      if (Math.abs(x) >= imageSize.width / 2) {
        x += imageSize.width / 2;
        if (pinsImageOriginalSize.width - Math.abs(x) < imageSize.width) {
          x = imageSize.width - pinsImageOriginalSize.width;
        }
      } else {
        x = 0;
      }

      if (Math.abs(y) >= imageSize.height / 2) {
        y += imageSize.height / 2;
        if (pinsImageOriginalSize.height - Math.abs(y) < imageSize.height) {
          y = imageSize.height - pinsImageOriginalSize.height;
        }
      } else {
        y = 0;
      }

      if (x) {
        x = Math.round(x);
      }
      if (y) {
        y = Math.round(y);
      }

      return `${x}px ${y}px`;
    }

    return '0 0';
  }, [pinPositionInPixels, pinsImageOriginalSize, imageSize]);

  const pinCoordinate = useMemo(() => {
    let x = Math.abs(pinPositionInPixels.x);
    let y = Math.abs(pinPositionInPixels.y);
    if (imageSize) {
      if (x >= imageSize.width / 2) {
        if (pinsImageOriginalSize.width - x < imageSize.width / 2) {
          x = imageSize.width - pinsImageOriginalSize.width + x;
        } else {
          x = Math.round(imageSize.width / 2);
        }
      }

      if (y >= imageSize.height / 2) {
        if (pinsImageOriginalSize.height - Math.abs(y) < imageSize.height / 2) {
          y = imageSize.height - pinsImageOriginalSize.height + y;
        } else {
          y = Math.round(imageSize.height / 2);
        }
      }
    }

    return { x: x ? x + 'px' : x, y: y ? y + 'px' : y };
  }, [imageSize, pinsImageOriginalSize, pinPositionInPixels]);

  const { data: commentsData, isFetching } = useGetPostRepliesQuery(
    { discussionId, threadId: post.TopicThreadId },
    { skip: !inView },
  );
  const [sendReply] = useSendReplyMutation();

  useEffect(() => {
    if (commentsData) {
      setComments(
        commentsData
          .slice()
          .sort(
            (commentA: ActivityPost, commentB: ActivityPost) =>
              Number(new Date(commentA.CreateDate)) - Number(new Date(commentB.CreateDate)),
          ),
      );
    }
  }, [commentsData]);

  const { mainComments, chatComments } = usePostComments(comments);

  const handleCommentEdit = useCallback(
    (comment: ActivityPost, remove?: boolean) => {
      setComments((prev) => {
        if (remove) {
          return prev.filter((item) => item.TopicThreadId !== comment.TopicThreadId);
        }
        return prev.map((item) => (item.TopicThreadId === comment.TopicThreadId ? comment : item));
      });
    },
    [setComments],
  );

  const applySuggestionText = useCallback(
    (text: string) => {
      setSuggestReplyText(text);
      setTimeout(() => {
        //* Expecting the suggestions to finish their collapse animation before focusing on Input */
        threadFormContainer.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, REPLY_SUGGESTIONS_COLLAPSE_DURATION);
    },
    [setSuggestReplyText],
  );

  const handleSend = async ({ text, attachments, mentionInfo }: ThreadData) => {
    const body = {
      ThreadContent: text.trim(),
      Attachments: attachments,
      Visibility: 0,
      VisibleToId: '',
      VisibleToFullName: '',
    };

    if (mentionInfo) {
      body.Visibility = 1;
    }

    if (mentionInfo?.userId) {
      body.VisibleToId = mentionInfo.userId;
      body.VisibleToFullName = mentionInfo.userName;
    }

    if (!mentionInfo || mentionInfo?.userId) {
      setIsRepliesLoading(true);
    } else {
      setIsChatRepliesLoading(true);
    }

    const res = await sendReply({
      discussionId,
      activityId,
      threadId: post.TopicThreadId,
      body,
    });

    if ('data' in res) {
      setComments((comments) => [...comments, res.data]);
      setIsRepliesLoading(false);
      setIsChatRepliesLoading(false);
    } else {
      console.error(res.error);
      toast.error('Something went wrong...');
    }
  };

  const toggleEdit = () => {
    setEdit((prevState) => !prevState);
    setEditText(post.ThreadContent);
  };

  const handlePostEdit = async ({ text, attachments }: ThreadData) => {
    const res = await editPost({
      body: {
        ...post,
        ThreadContent: text,
        Attachments: attachments,
      },
      discussionId,
      activityId,
      threadId: post.TopicThreadId,
    });

    if ('data' in res) {
      actions.updatePost(res.data);
      setEditText('');
      setEdit(false);
    } else {
      console.error(res.error);
      toast.error('Something went wrong...');
    }
  };

  return (
    <div>
      <div className={styles.post} ref={postRef} style={{ borderColor: tagColor }}>
        <div className={styles.postContainer}>
          <div className={styles.postInfo}>
            <div className={styles.userContainer}>
              <div className={styles.avatarWrapper}>
                <Avatar
                  alt="Avatar"
                  className={styles.userAvatar}
                  src={post.ThreadCreatorUserImage}
                  variant="square"
                />
              </div>
              <div className={styles.userInfo}>
                <div className={styles.userName}>{post.ThreadCreatorName}</div>
                <div className={styles.postCreationDate}>{postCreationDate}</div>
              </div>
            </div>
            <PinPostTag tag={post.Tags[0]} />

            {edit ? (
              <ThreadForm
                attachments={post.Attachments}
                edit={edit}
                loading={isPostUpdating}
                onCancel={() => setEdit(false)}
                onSubmit={handlePostEdit}
                submitButtonText="Confirm"
                text={editText}
              />
            ) : (
              <div className={styles.postText}>{post.ThreadContent}</div>
            )}
            <LikesCounter />
          </div>
          <div
            className={styles.pinImageContainer}
            ref={imageRef}
            style={{
              backgroundImage: `url("${imageUrl}")`,
              backgroundPosition: imagePosition,
            }}
          >
            <div
              className={styles.pinDot}
              style={{ top: pinCoordinate.y, left: pinCoordinate.x, backgroundColor: tagColor }}
            />
            <div className={styles.postControlsWrapper}>
              <PostControls onEdit={toggleEdit} post={post} />
            </div>
          </div>
        </div>
        <div className={styles.commentsContainer}>
          <div className={styles.comments}>
            {isFetching && <Loading overContent />}
            {mainComments.map((comment, index) => (
              <React.Fragment key={comment.TopicThreadId}>
                {index === 0 && <div className={styles.horizontalDivider}></div>}
                <ActivityComment
                  comment={comment}
                  isAuthor={post.ThreadCreatorId === comment.ThreadCreatorId}
                  onEdit={handleCommentEdit}
                />
              </React.Fragment>
            ))}
          </div>
          <ReplySuggestions onSelect={applySuggestionText} post={post} />
          <div ref={threadFormContainer}>
            <ThreadForm
              comments={comments}
              loading={isRepliesLoading}
              onCancel={() => setSuggestReplyText('')}
              onSubmit={handleSend}
              post={post}
              text={suggestReplyText}
            />
          </div>
        </div>
      </div>
      <RepliesChat
        chatComments={chatComments}
        isChatRepliesLoading={isChatRepliesLoading}
        onEdit={handleCommentEdit}
        onSubmit={handleSend}
        post={post}
      />
    </div>
  );
};
