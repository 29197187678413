import { HighlightOff } from '@mui/icons-material';
import { Button } from '@mui/material';
import cn from 'classnames';
import styles from './searchInput.module.css';

interface Props {
  searchInput: string;
  setSearchInput: (arg: string) => void;
}

export const SearchInput: React.FC<Props> = ({ searchInput, setSearchInput }) => {
  return (
    <div className={styles.inputWrapper}>
      <input
        className={cn(styles.input, styles.searchInput)}
        onChange={(event) => setSearchInput(event.target.value)}
        placeholder="Search"
        type="text"
        value={searchInput}
      />
      {searchInput && (
        <Button className={styles.clearTextButton} onClick={() => setSearchInput('')}>
          <HighlightOff />
        </Button>
      )}
    </div>
  );
};
