import React, { CSSProperties, RefObject, useState } from 'react';
import { Avatar, IconButton } from '@mui/material';
import { ReactComponent as TrashBin } from '@/assets/icons/trashBin.svg';
import { DeleteModal } from '@/components/deleteModal';
import { useActions, useAppSelector, useClickOutside } from '@/hooks';
import { useDeletePostMutation } from '@/store/api';
import { ActivityPost } from '@/types';
import { formatDateTime } from '@/utils/formatDateTime';
import { PinPostTag } from '../pinPostTag';
import styles from './pinPostPopup.module.css';

interface Props {
  post: ActivityPost;
  onClose: () => void;
  outsideTriggerBtnRef: RefObject<HTMLElement>;
  style?: CSSProperties;
}

export const PinPostPopup: React.FC<Props> = ({ post, outsideTriggerBtnRef, onClose, style }) => {
  const actions = useActions();
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletePost, { isLoading: isPostDeleting }] = useDeletePostMutation();
  const postCreationDate = formatDateTime(post.CreateDate);

  const handlePostDelete = async () => {
    await deletePost({ discussionId, threadId: post.TopicThreadId });
    actions.deletePost(post);
    setShowDeleteModal(false);
  };

  const popupRef = useClickOutside(onClose, outsideTriggerBtnRef);

  return (
    <div className={styles.pinPostPopup} ref={popupRef} style={style}>
      <div className={styles.pinPostPopupHeader}>
        <div className={styles.userContainer}>
          <div className={styles.avatarWrapper}>
            <Avatar
              alt="Avatar"
              className={styles.userAvatar}
              src={post.ThreadCreatorUserImage}
              variant="square"
            />
          </div>
          <div className={styles.userInfo}>
            <div className={styles.userName}>{post.ThreadCreatorName}</div>
            <div className={styles.postCreationDate}>{postCreationDate}</div>
          </div>
        </div>
        <IconButton className={styles.deletePostButton} onClick={() => setShowDeleteModal(true)}>
          <TrashBin />
        </IconButton>
      </div>
      <div className={styles.mainContent}>
        <PinPostTag tag={post.Tags[0]} />
        {post.ThreadContent}
      </div>
      <DeleteModal
        isOpen={showDeleteModal}
        loading={isPostDeleting}
        objectName="pin post"
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handlePostDelete}
      />
    </div>
  );
};
