import React from 'react';
import { Button } from '@mui/material';
import cn from 'classnames';
import { ReactComponent as Like } from '@/assets/icons/likeBtn.svg';
import { ReactComponent as LikesCount } from '@/assets/icons/likesCount.svg';
import styles from './likesCounter.module.css';

interface Props {
  className?: string;
}

export const LikesCounter: React.FC<Props> = ({ className }) => {
  return (
    <div className={cn(styles.likesContainer, className)}>
      <div className={styles.likesCount}>
        <LikesCount />
        12
      </div>
      <Button className={styles.likeBtn}>
        <Like />
        Like
      </Button>
    </div>
  );
};
