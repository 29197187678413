import { useEffect, useRef, useState } from 'react';
import { HighlightOff } from '@mui/icons-material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, IconButton } from '@mui/material';
import cn from 'classnames';
import { FilterPostByTagPopup } from '@/components/filterPostByTag/filterPostByTagPopup';
import { Tag } from '@/types';
import styles from './filterPostByTag.module.css';

interface Props {
  tags: Tag[];
  onFilter: (selectedTags: Tag[]) => void;
}

export const FilterPostByTag: React.FC<Props> = ({ tags, onFilter }) => {
  const modalTriggerBtnRef = useRef<HTMLButtonElement>(null);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  useEffect(() => {
    onFilter(selectedTags);
  }, [selectedTags]);

  const handleTogglePopup = () => {
    setShowFilterPopup((prev) => !prev);
  };

  const toggleTagHandler = (selectedTag: Tag, checked: boolean) => {
    if (checked) {
      setSelectedTags((tags) => [...tags, selectedTag]);
    } else {
      setSelectedTags((tags) =>
        tags.filter((tag) => tag.ContentTagId !== selectedTag.ContentTagId),
      );
    }
  };

  const handleRemoveTag = (tag: Tag) => {
    setSelectedTags((selectedTags) =>
      selectedTags.filter((selectedTag) => selectedTag.ContentTagId !== tag.ContentTagId),
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.selectedTags}>
        {selectedTags.map((tag) => (
          <div className={styles.selectedTag} key={tag.ContentTagId}>
            {tag.TagValue}
            <Button className={styles.removeSelectedTagBtn} onClick={() => handleRemoveTag(tag)}>
              <HighlightOff />
            </Button>
          </div>
        ))}
      </div>
      <div className={styles.popupWrapper}>
        <IconButton
          className={cn(styles.filterPostButton, {
            [styles.filterPostButtonActive]: showFilterPopup,
          })}
          onClick={handleTogglePopup}
          ref={modalTriggerBtnRef}
        >
          <FilterAltIcon />
        </IconButton>
        {showFilterPopup && (
          <FilterPostByTagPopup
            onClose={() => setShowFilterPopup(false)}
            onToggle={toggleTagHandler}
            outsideTriggerBtnRef={modalTriggerBtnRef}
            selectedTags={selectedTags}
            tags={tags}
          />
        )}
      </div>
    </div>
  );
};
