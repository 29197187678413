import { useMemo, useState } from 'react';
import { Equalizer as EqualizerIcon, ManageSearch as ManageSearchIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { ReactComponent as InfoIcon } from '@/assets/icons/projectInfo.svg';
import { StatisticsTab } from '@/components/activityDetailsDrawer/tabs/statisticsTab';
import { SidebarDrawer } from '@/components/sidebarDrawer';
import { useAppSelector } from '@/hooks';
import { SidebarTab } from '@/types';
import { OverviewTab } from './overviewTab';
import styles from '../header.module.css';

const title = 'About Project';

export const ProjectInfoBtn = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const activities = useAppSelector((state) => state.activities);
  const formActivity = useMemo(
    () => activities.find((activity) => activity.ContentType === 6) || activities[0],
    [activities],
  );

  const sidebarTabs: SidebarTab[] = useMemo(
    () => [
      {
        title: 'Overview',
        icon: <ManageSearchIcon />,
        content: <OverviewTab />,
      },
      {
        title: 'Statistics',
        icon: <EqualizerIcon />,
        content: <StatisticsTab activity={formActivity} />,
      },
    ],
    [formActivity],
  );

  return (
    <>
      <Tooltip title={title}>
        <IconButton className={styles.badgeWrapper} onClick={() => setIsDrawerOpen(true)}>
          <InfoIcon />
        </IconButton>
      </Tooltip>

      <SidebarDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        tabs={sidebarTabs}
        title={title}
      />
    </>
  );
};
