import { useEffect, useMemo, useRef, useState } from 'react';
import { AddCircleOutline, Close, Menu, Search } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import cn from 'classnames';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrowDown.svg';
import { ReactComponent as LayoutIcon } from '@/assets/icons/layout.svg';
import { ReactComponent as PenIcon } from '@/assets/icons/pen.svg';
import { TabButton } from '@/components/activityDetailsDrawer/tabButton';
import { NewPostModal } from '@/components/topSection/newPostModal';
import { SelectSectionModal } from '@/components/topSection/selectSectionModal';
import { activitySectionIconsMap } from '@/constants/activitySectionIconsMap';
import { useActions, useAppSelector } from '@/hooks';
import { useGetHighlightColorsQuery } from '@/store/api/highlights';
import { ActivitySection, ActivityTabContentAvailability, Color } from '@/types';
import { HighlightingColorModal } from './highlightingColorModal';
import styles from './topSection.module.css';

const socialActivitySections: ActivitySection[] = [
  ActivitySection.Posts,
  ActivitySection.Highlights,
  ActivitySection.Images,
  ActivitySection.Videos,
];

const pinsActivitySections: ActivitySection[] = [ActivitySection.Pins, ActivitySection.Heatmap];

interface Props {
  back?: () => void;
  rowLayout?: boolean;
  title: string;
  onClose?: () => void;
}

export const TopSection: React.FC<Props> = ({ title, rowLayout, onClose }) => {
  const actions = useActions();
  const highlightModalBtnRef = useRef<HTMLButtonElement>(null);
  const selectSectionBtnRef = useRef<HTMLButtonElement>(null);
  const [isNewPostModalShown, setIsNewPostModalShown] = useState(false);
  const [showHighlightTextModal, setShowHighlightTextModal] = useState(false);
  const [showSelectSectionModal, setShowSelectSectionModal] = useState(false);
  const posts = useAppSelector((state) => state.posts);
  const activeSection = useAppSelector((state) => state.topSection.activeSection);
  const isHighlightingActive = useAppSelector((state) => state.topSection.isHighlightingActive);
  const { Color: selectedColor, HighlightColorId: selectedColorId } = useAppSelector(
    (state) => state.topSection.highlightColor,
  );
  const isExpandablePanelsOpen = useAppSelector((state) => state.topSection.isOpenExpandablePanels);
  const selectedActivity = useAppSelector((state) => state.selectedActivity);
  const selectedUserName = useAppSelector((state) => state.selectedUser.name);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const { data: highlightColors } = useGetHighlightColorsQuery(discussionId, {
    skip: !discussionId,
  });

  const isPinsActivity = selectedActivity.type === 4;
  const isCardScoreActivity = selectedActivity.type === 18;
  const sections = useMemo(() => {
    return isPinsActivity ? pinsActivitySections : socialActivitySections;
  }, [isPinsActivity]);

  useEffect(() => {
    if (highlightColors) {
      actions.setColorsMap(highlightColors);
    }
  }, [highlightColors, actions]);

  const activityTabContentAvailability = useMemo<ActivityTabContentAvailability>(() => {
    if (isPinsActivity) {
      return {
        [ActivitySection.Pins]: isPinsActivity,
        [ActivitySection.Heatmap]: isPinsActivity,
      };
    }

    return {
      [ActivitySection.Posts]: true,
      [ActivitySection.Highlights]: posts.some((post) => post.Highlights.length),
      [ActivitySection.Images]: posts.some((post) =>
        post.Attachments.some(
          (attachment) => attachment.FileType === 0 || attachment.FileType === 12,
        ),
      ),
      [ActivitySection.Videos]: posts.some((post) =>
        post.Attachments.some(
          (attachment) => attachment.FileType === 1 || attachment.FileType === 13,
        ),
      ),
    };
  }, [posts, isPinsActivity]);

  useEffect(() => {
    if (!activityTabContentAvailability[activeSection]) {
      actions.setActiveSection(sections[0]);
    }
  }, [actions, activityTabContentAvailability, activeSection, sections]);

  const handleHighlightingActivation = () => {
    if (!selectedColor) {
      return;
    }
    actions.setIsHighlightingActive(!isHighlightingActive);
  };

  const toggleNewPostModal = () => {
    setIsNewPostModalShown((prev) => !prev);
  };

  const toggleSidebar = () => {
    actions.toggleDrawer();
  };

  const showExpandablePanels = () => {
    actions.toggleExpandablePanels();
  };

  const toggleHighlightTextModal = () => {
    setShowHighlightTextModal((prev) => !prev);
  };

  const handleSectionChange = (section: ActivitySection) => {
    actions.setActiveSection(section);
    setShowSelectSectionModal(false);
  };

  const handleHighlightColor = (color: Color) => {
    if (color.HighlightColorId === selectedColorId) {
      actions.resetHighlightColor();
      actions.setIsHighlightingActive(false);
    } else {
      actions.setHighlightColor(color);
      actions.setIsHighlightingActive(true);
    }
    setShowHighlightTextModal(false);
  };

  return (
    <div className={cn(styles.topSection, { [styles.rowLayout]: rowLayout })}>
      <div className={styles.header}>
        {!rowLayout && (
          <Button className={styles.toggleMenuButton} onClick={toggleSidebar}>
            <Menu />
          </Button>
        )}
        <div className={styles.activityNameWrapper}>
          <div className={styles.title}>{title}</div>
          <div className={styles.verticalDivider} />
          <div className={styles.activityName} title={selectedActivity?.name || selectedUserName}>
            {selectedActivity?.name || selectedUserName}
          </div>
          {rowLayout && (
            <IconButton className={styles.actionButton} onClick={onClose}>
              <Close className={styles.buttonIcon} />
            </IconButton>
          )}
        </div>
      </div>
      <div className={styles.subHeader}>
        {!isCardScoreActivity && (
          <>
            <div className={styles.tabs}>
              {sections.map((section) => (
                <TabButton
                  icon={activitySectionIconsMap[section]}
                  isActive={activeSection === section}
                  isDisabled={!activityTabContentAvailability[section]}
                  key={section}
                  onClick={() => handleSectionChange(section)}
                  title={section}
                />
              ))}
            </div>

            <div className={styles.sectionSelectWrapper}>
              <button
                className={styles.selectSectionBtn}
                onClick={() => setShowSelectSectionModal((prev) => !prev)}
                ref={selectSectionBtnRef}
              >
                <div className={styles.sectionBtnWrapper}>
                  <div className={styles.iconWrapper}>{activitySectionIconsMap[activeSection]}</div>
                  <div className={styles.sectionName}>{activeSection}</div>
                </div>
                <ArrowDownIcon
                  className={cn(styles.arrowIcon, {
                    [styles.arrowIconActive]: showSelectSectionModal,
                  })}
                />
              </button>
              {showSelectSectionModal && (
                <SelectSectionModal
                  activityTabContentAvailability={activityTabContentAvailability}
                  onClose={() => setShowSelectSectionModal(false)}
                  onSectionSelect={handleSectionChange}
                  sections={sections}
                  selectSectionBtnRef={selectSectionBtnRef}
                />
              )}
            </div>
          </>
        )}

        <div className={styles.actionsWrapper}>
          <div className={styles.topSectionActions}>
            <IconButton className={styles.actionButton} onClick={toggleNewPostModal}>
              <AddCircleOutline className={styles.buttonIcon} />
            </IconButton>
            <IconButton className={styles.actionButton}>
              <Search className={styles.buttonIcon} />
            </IconButton>
            <div className={styles.buttonGroup}>
              <button
                className={cn(styles.highlightColorBtn, {
                  [styles.highlightColorBtnActive]: showHighlightTextModal,
                })}
                onClick={toggleHighlightTextModal}
                ref={highlightModalBtnRef}
              >
                <ArrowDownIcon
                  className={cn(styles.arrowIcon, {
                    [styles.arrowIconActive]: showHighlightTextModal,
                  })}
                />
              </button>
              <IconButton
                className={cn(styles.activateHighlightingBtn, {
                  [styles.activateHighlightingBtnActive]: selectedColor,
                })}
                onClick={handleHighlightingActivation}
                style={
                  selectedColor
                    ? isHighlightingActive
                      ? { backgroundColor: selectedColor, color: '#fff' }
                      : {
                          border: `2px solid ${selectedColor}`,
                          color: selectedColor,
                        }
                    : {}
                }
              >
                <PenIcon className={styles.activateHighlightingIcon} />
              </IconButton>
            </div>
            <IconButton
              className={cn(styles.actionButton, {
                [styles.activeButton]:
                  isExpandablePanelsOpen && activeSection === ActivitySection.Posts,
              })}
              disabled={activeSection !== ActivitySection.Posts}
              onClick={showExpandablePanels}
              sx={{
                '&.Mui-disabled': {
                  backgroundColor: '#eff0f5',
                },
              }}
            >
              <LayoutIcon className={styles.expandablePanelsIcon} />
            </IconButton>
          </div>
          <HighlightingColorModal
            highlightModalBtnRef={highlightModalBtnRef}
            isOpen={showHighlightTextModal}
            onClose={() => setShowHighlightTextModal(false)}
            onColorSelect={handleHighlightColor}
          />
        </div>
      </div>
      <NewPostModal isOpen={isNewPostModalShown} onClose={toggleNewPostModal} />
    </div>
  );
};
