import { useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteOutline, Edit, Feedback, MoreVertSharp, PushPin } from '@mui/icons-material';
import { Button, IconButton, Popover, Tooltip } from '@mui/material';
import cn from 'classnames';
import { DeleteModal } from '@/components/deleteModal';
import { useActions, useAppSelector } from '@/hooks';
import {
  useDeletePostMutation,
  useTogglePinActivityPostMutation,
  useToggleResponseMandatoryMutation,
} from '@/store/api';
import { ActivityPost } from '@/types';
import styles from './postControls.module.css';

interface Props {
  post: ActivityPost;
  onEdit: () => void;
}

export const PostControls: React.FC<Props> = ({ onEdit, post }) => {
  const actions = useActions();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [controlsAnchorEl, setControlsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const [togglePin] = useTogglePinActivityPostMutation();
  const [toggleResponseMandatory] = useToggleResponseMandatoryMutation();
  const [deletePost, { isLoading: isPostDeleting }] = useDeletePostMutation();
  const togglePinHandler = async () => {
    const response = await togglePin({ threadId: post.TopicThreadId, discussionId });

    if ('error' in response) {
      toast.error('Error: Something went wrong. Please try again later.');
      return;
    }
    actions.updatePost({ ...post, IsPinned: !post.IsPinned });
  };

  const toggleResponseMandatoryHandler = async () => {
    const newValue = !post.IsResponseMandatory;
    const response = await toggleResponseMandatory({
      discussionId,
      threadId: post.TopicThreadId,
      IsMandatory: newValue,
    });

    if ('error' in response) {
      toast.error('Error: Something went wrong. Please try again later.');
      return;
    }

    actions.updatePost({ ...post, IsResponseMandatory: newValue });
    setControlsAnchorEl(null);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
    setControlsAnchorEl(null);
  };

  const handlePostDelete = async () => {
    await deletePost({ discussionId, threadId: post.TopicThreadId });
    actions.deletePost(post);
    toggleDeleteModal();
  };

  const handleEdit = () => {
    onEdit();
    setControlsAnchorEl(null);
  };

  return (
    <>
      <div className={styles.postControls}>
        <Tooltip title={post.IsPinned ? 'Unpin post' : 'Pin post'}>
          <IconButton
            className={cn(styles.controlsButton, { [styles.pinnedBtn]: post.IsPinned })}
            onClick={togglePinHandler}
          >
            <PushPin className={cn(styles.controlsIcon, { [styles.pinnedIcon]: post.IsPinned })} />
          </IconButton>
        </Tooltip>

        {post.CanBeEdited && (
          <Tooltip title="Edit">
            <IconButton className={styles.controlsButton} onClick={handleEdit}>
              <Edit className={styles.controlsIcon} />
            </IconButton>
          </Tooltip>
        )}

        <IconButton
          className={styles.controlsButton}
          onClick={(e) => setControlsAnchorEl(e.currentTarget)}
        >
          <MoreVertSharp className={styles.controlsIcon} />
        </IconButton>

        <Popover
          anchorEl={controlsAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          classes={{ paper: 'popover' }}
          onClose={() => setControlsAnchorEl(null)}
          open={Boolean(controlsAnchorEl)}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <div className={styles.controlsPopoverContent}>
            <Button
              className={styles.controlsPopoverButton}
              onClick={toggleResponseMandatoryHandler}
            >
              <Feedback />
              <div className={styles.controlsPopoverItemText}>
                {post.IsResponseMandatory ? 'Response not required' : 'Response required'}
              </div>
            </Button>
            {post.CanBeDeleted && (
              <Button className={styles.controlsPopoverButton} onClick={toggleDeleteModal}>
                <DeleteOutline />
                <div className={styles.controlsPopoverItemText}>Delete</div>
              </Button>
            )}
          </div>
        </Popover>
      </div>
      <DeleteModal
        isOpen={showDeleteModal}
        loading={isPostDeleting}
        objectName="post"
        onClose={toggleDeleteModal}
        onConfirm={handlePostDelete}
      />
    </>
  );
};
