import { useState } from 'react';
import cn from 'classnames';
import { FilterPostByTag } from '@/components/filterPostByTag';
import { useActions, useAppSelector, usePosts } from '@/hooks';
import { ActivityCard } from '@/pages/activities/activityPosts/activityCard';
import { useGetUserPostsQuery } from '@/store/api/people';
import { useGetRecentTagsQuery } from '@/store/api/tags';
import { Tag } from '@/types';
import styles from './peoplePosts.module.css';

export enum PostTab {
  All = 'All',
  Unread = 'Unread',
}

const postTabs = [PostTab.All, PostTab.Unread] as const;

export const PeoplePosts: React.FC = () => {
  const actions = useActions();
  const [activeTab, setActiveTab] = useState(PostTab.All);
  const { pinnedPosts, unpinnedPosts } = usePosts(activeTab);
  const unreadPosts = useAppSelector((state) => state.selectedActivity?.unreadPosts);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const selectedUserId = useAppSelector((state) => state.selectedUser.id);

  const { data: userPosts } = useGetUserPostsQuery(
    { discussionId, personId: selectedUserId },
    {
      skip: !discussionId || !selectedUserId,
    },
  );

  const { data: contentTags = [] } = useGetRecentTagsQuery(
    { discussionId, context: 'thread' },
    { skip: !discussionId },
  );

  const handlePostsFiltering = (selectedTags: Tag[]) => {
    if (!userPosts) {
      return;
    }
    const filteredPosts = userPosts.filter((post) => {
      const postTags = post.Tags;

      return selectedTags.every((selectedTag) =>
        postTags?.find((tag) => tag.ContentTagId === selectedTag.ContentTagId),
      );
    });

    actions.setPosts(filteredPosts);
  };

  return (
    <div className={styles.peoplePostsWrapper}>
      <div className={styles.postsHeader}>
        <div className={styles.postsTabs}>
          {postTabs.map((tab) => (
            <div className={styles.postTabWrapper} key={tab}>
              <button
                className={cn(styles.postsTab, { [styles.postsTabActive]: tab === activeTab })}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
              {tab === PostTab.Unread && !!unreadPosts && (
                <div className={styles.unreadPosts}>{unreadPosts}</div>
              )}
            </div>
          ))}
        </div>
        <FilterPostByTag onFilter={handlePostsFiltering} tags={contentTags} />
      </div>
      {pinnedPosts.length || unpinnedPosts.length ? (
        <>
          {!!pinnedPosts.length && (
            <div className={cn(styles.postList, styles.pinnedPosts)}>
              {pinnedPosts.map((post) => (
                <ActivityCard isPeopleSection key={post.TopicThreadId} post={post} />
              ))}
            </div>
          )}
          {!!unpinnedPosts.length && (
            <div className={styles.postList}>
              {unpinnedPosts.map((post) => (
                <ActivityCard isPeopleSection key={post.TopicThreadId} post={post} />
              ))}
            </div>
          )}
        </>
      ) : (
        <h2 className={styles.noPosts}>No posts</h2>
      )}
    </div>
  );
};
