import cn from 'classnames';
import { ReactComponent as CheckmarkIcon } from '@/assets/icons/checkMark.svg';
import { Color } from '@/types';
import styles from './colorButton.module.css';

interface Props {
  color: Color;
  onClick: (color: Color) => void;
  active?: boolean;
  showTitle?: boolean;
  size?: number;
}

export const ColorButton: React.FC<Props> = ({ color, onClick, active, showTitle, size = 24 }) => {
  const clickHandler = () => {
    onClick(color);
  };

  const colorTitle =
    color.Title.length > 25
      ? color.Title.slice(0, 10) + '...' + color.Title.slice(-10)
      : color.Title;

  return (
    <button
      className={cn(styles.colorButton, { [styles.colorButtonWithoutTitle]: !showTitle })}
      onClick={clickHandler}
      title={color.Title}
    >
      <div
        className={cn(styles.colorBox, { [styles.colorBoxActive]: active })}
        style={{
          backgroundColor: color.Color,
          outlineColor: active ? color.Color : 'transparent',
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        {active && <CheckmarkIcon />}
      </div>
      {showTitle && <div className={styles.colorTitle}>{colorTitle}</div>}
    </button>
  );
};
