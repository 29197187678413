import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedUserState {
  id: string;
  name: string;
}

const initialState: SelectedUserState = {
  id: '',
  name: '',
};

export const selectedUserSlice = createSlice({
  name: 'selectedUser',
  initialState,
  reducers: {
    setSelectedUser(state, action: PayloadAction<SelectedUserState>) {
      return { ...state, ...action.payload };
    },
    resetSelectedUser() {
      return initialState;
    },
  },
});
