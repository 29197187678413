import { ReactComponent as HeatmapIcon } from '@/assets/icons/heatmap.svg';
import { ReactComponent as ImagesIcon } from '@/assets/icons/images.svg';
import { ReactComponent as PenIcon } from '@/assets/icons/pen.svg';
import { ReactComponent as PinsIcon } from '@/assets/icons/pins.svg';
import { ReactComponent as PostsIcon } from '@/assets/icons/posts.svg';
import { ReactComponent as VideosIcon } from '@/assets/icons/videos.svg';
import { ActivitySection } from '@/types';

export const activitySectionIconsMap = {
  [ActivitySection.Posts]: <PostsIcon />,
  [ActivitySection.Highlights]: <PenIcon />,
  [ActivitySection.Images]: <ImagesIcon />,
  [ActivitySection.Videos]: <VideosIcon />,
  [ActivitySection.Pins]: <PinsIcon />,
  [ActivitySection.Heatmap]: <HeatmapIcon />,
} as const;
