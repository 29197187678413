import { useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import cn from 'classnames';
import { ReactComponent as AddTagsIcon } from '@/assets/icons/addTags.svg';
import { PostTagsContext, Tag } from '@/types';
import { TagItem } from './tagItem';
import { TagsPopup } from './tagsPopup';
import styles from './tags.module.css';

interface Props {
  context: PostTagsContext;
  apiId: string;
  tags: Tag[];
  onChange: (tags: Tag[]) => void;
  isEditHighlightTags?: boolean;
}
export const Tags: React.FC<Props> = ({ tags, onChange, context, apiId, isEditHighlightTags }) => {
  const modalTriggerBtnRef = useRef<HTMLButtonElement>(null);
  const [isTagsPopupOpen, setIsTagsPopupOpen] = useState(false);

  const handleTag = (tag: Tag, checked: boolean) => {
    if (checked) {
      if (tag.TagValue.includes(':')) {
        /** uncheck other related tags with the same prefix */
        const [prefix] = tag.TagValue.split(':');
        const filteredTags = tags.filter(
          ({ TagValue }) => !TagValue.toLowerCase().includes(prefix.toLowerCase()),
        );
        onChange([...filteredTags, tag]);
      } else {
        onChange([...tags, tag]);
      }
    } else {
      onChange(tags.filter(({ ContentTagId }) => ContentTagId !== tag.ContentTagId));
    }
  };

  const handleTogglePopup = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsTagsPopupOpen((prev) => !prev);
  };

  return (
    <div className={styles.tags} onClick={(event) => event.stopPropagation()}>
      <div className={styles.modalWrapper}>
        <IconButton
          aria-label="Add or select tags"
          className={cn(styles.addTagsButton, { [styles.buttonActive]: isTagsPopupOpen })}
          onClick={handleTogglePopup}
          ref={modalTriggerBtnRef}
        >
          <AddTagsIcon
            className={cn(styles.addTagsIcon, { [styles.iconActive]: isTagsPopupOpen })}
          />
        </IconButton>
        {isTagsPopupOpen && (
          <TagsPopup
            apiId={apiId}
            context={context}
            isEditHighlightTags={isEditHighlightTags}
            onClose={() => setIsTagsPopupOpen(false)}
            onTagChange={handleTag}
            outsideTriggerBtnRef={modalTriggerBtnRef}
            tags={tags}
          />
        )}
      </div>
      {tags.length ? (
        tags.map((tag) => <TagItem key={tag.ContentTagId} tag={tag} />)
      ) : (
        <span className={styles.textNoTags}>No tags selected</span>
      )}
    </div>
  );
};
