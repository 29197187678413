import { useMemo } from 'react';
import { Avatar } from '@mui/material';
import cn from 'classnames';
import imagePlaceholder from '@/assets/imagePlaceholder.png';
import { Activity } from '@/types';
import styles from './activityIcon.module.css';

interface Props {
  activity: Activity;
  peopleSection?: boolean;
}
export const ActivityIcon: React.FC<Props> = ({ activity, peopleSection }) => {
  const iconImage = activity.IconImageUrl ? activity.IconImageUrl : imagePlaceholder;
  const isIconShown = useMemo(() => [1, 2, 3].includes(activity.IconStyle), [activity.IconStyle]);

  const { IconColor, IconBackgroundColor, IconBorderColor, IconClass } = activity.Icon;

  return (
    <Avatar className={styles.avatar}>
      {activity.IconStyle === 0 && (
        <img alt="Activity Image" className={styles.iconImage} src={iconImage} />
      )}

      {isIconShown && (
        <div
          className={cn(styles.iconWrapper, {
            [styles.circleIcon]: activity.IconStyle === 3,
          })}
          style={{ background: IconBackgroundColor, border: `2px solid ${IconBorderColor}` }}
        >
          <i
            className={cn(IconClass, styles.icon)}
            style={{
              color: IconColor,
            }}
          />
        </div>
      )}
      {!peopleSection && !!activity.ToDoCount && (
        <div className={styles.notifications}>{activity.ToDoCount}</div>
      )}
    </Avatar>
  );
};
