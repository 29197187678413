import React from 'react';
import ForumIcon from '@mui/icons-material/Forum';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import SmsIcon from '@mui/icons-material/Sms';
import VideocamIcon from '@mui/icons-material/Videocam';
import cn from 'classnames';
import styles from './badge.module.css';

type ItemType = 'comment' | 'post' | 'file' | 'photo' | 'video';

interface Props {
  className?: string;
  type: ItemType;
  iconColor: string;
  number?: number;
  outlined?: boolean;
  peopleItemBadge?: boolean;
  greenNumbers?: boolean;
}

export const Badge: React.FC<Props> = ({
  className,
  iconColor,
  type,
  number,
  outlined = false,
  peopleItemBadge = false,
  greenNumbers = false,
}) => {
  return (
    <div
      className={cn(styles.badge, className, {
        [styles.badgeOutlined]: outlined,
      })}
    >
      {type === 'comment' && <SmsIcon style={{ color: iconColor }} />}
      {type === 'post' && <ForumIcon className={styles.icon} />}
      {type === 'file' && <InsertDriveFileIcon className={styles.icon} />}
      {type === 'photo' && <InsertPhotoIcon className={styles.icon} />}
      {type === 'video' && <VideocamIcon className={styles.icon} />}
      {number ? (
        <span
          className={cn(styles.number, {
            [styles.peopleItemBadge]: peopleItemBadge,
            [styles.greenNumbers]: greenNumbers,
          })}
        >
          {number}
        </span>
      ) : null}
    </div>
  );
};
