import { RefObject, useEffect, useRef } from 'react';
import { useEscapeKey } from './useEscapeKey';

export const useClickOutside = (
  callback: () => void,
  outsideTriggerRef?: RefObject<HTMLElement>,
) => {
  const ref = useRef<HTMLDivElement>(null);

  useEscapeKey(callback);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clickedElem = event.target as Node;
      const portalModal = document.querySelector('[role="presentation"]'); //exclude MUI portalModal from outside click trigger.

      if (
        portalModal ||
        outsideTriggerRef?.current?.contains(clickedElem) ||
        ref.current?.contains(clickedElem)
      ) {
        return;
      }

      callback();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, outsideTriggerRef]);

  return ref;
};
