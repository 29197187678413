import { Button } from '@mui/material';
import cn from 'classnames';
import { MemberCount } from '@/types';
import styles from './filterUsersDropdown.module.css';

interface Props {
  memberCount: MemberCount;
  onSelect: (memberCount: MemberCount | null) => void;
}

export const FilterButton: React.FC<Props> = ({ memberCount, onSelect }) => {
  return (
    <Button
      className={cn(styles.filterButton, { [styles.filterButtonDisabled]: !memberCount.Count })}
      onClick={() => onSelect(memberCount)}
    >
      {memberCount.Id}
      {!!memberCount.Count && <div className={styles.numberInCircle}>{memberCount.Count}</div>}
    </Button>
  );
};
