import { createSlice } from '@reduxjs/toolkit';

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    total: 0,
    unread: 0,
  },
  reducers: {
    setTotal(state, action) {
      state.total = action.payload;
    },
    setUnread(state, action) {
      state.unread = action.payload;
    },
  },
});
