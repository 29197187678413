import { useEffect, useState } from 'react';
import { Loading } from '@/components/loading';
import { useAppSelector } from '@/hooks';
import { useGetActivityDetailsQuery, useGetFormActivityStatsQuery } from '@/store/api';
import { Activity, Question } from '@/types';
import { QuestionItem } from '../questionItem';
import styles from './statisticsTab.module.css';

interface Props {
  activity: Activity;
}

export const StatisticsTab: React.FC<Props> = ({ activity }) => {
  const [questionsList, setQuestionsList] = useState<Question[]>([]);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const activityId = activity.DiscussionTopicId;
  const { data: activityDetails, isFetching: isActivityDetailsFetching } =
    useGetActivityDetailsQuery({ discussionId, activityId }, { skip: activity.ContentType !== 6 });

  const { data: activityStats, isFetching: isActivityStatsFetching } = useGetFormActivityStatsQuery(
    { discussionId, activityId },
    { skip: activity.ContentType !== 6 },
  );

  useEffect(() => {
    if (
      isActivityDetailsFetching ||
      !activityDetails ||
      isActivityStatsFetching ||
      !activityStats
    ) {
      return;
    }

    if (activityDetails[0].SurveyOptions.Survey.Pages) {
      const questions = activityDetails[0].SurveyOptions.Survey.Pages[0].Questions;
      const choiceQuestions = questions.filter((question) => {
        return activityStats.ProfilePages[0]?.ProfileChoiceQuestions.find(
          (answer) => answer.ProfileQuestionId === question.ProfileQuestionId,
        );
      });
      const rankQuestions = questions.filter((question) => {
        return activityStats.ProfilePages[0]?.ProfileRankQuestions.find(
          (answer) => answer.ProfileQuestionId === question.ProfileQuestionId,
        );
      });

      const questionsList = [...choiceQuestions, ...rankQuestions];
      setQuestionsList(questionsList);
    }
  }, [activityDetails, isActivityDetailsFetching, activityStats, isActivityStatsFetching]);

  return (
    <>
      {isActivityDetailsFetching || isActivityStatsFetching ? (
        <Loading />
      ) : questionsList.length ? (
        <div className={styles.questionsList}>
          {questionsList.map((question) => (
            <QuestionItem
              activityStats={activityStats}
              key={question.ProfileQuestionId}
              question={question}
            />
          ))}
        </div>
      ) : (
        <h3>There is no statistics for this activity</h3>
      )}
    </>
  );
};
