import React, { useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrowDown.svg';
import { Loading } from '@/components/loading';
import { FilterUsersDropdown } from '@/pages/people/peopleList/filterUsersDropdown';
import { MemberCount, User } from '@/types';
import { PeopleItem } from './peopleItem';
import styles from './peopleList.module.css';

interface Props {
  isLoading: boolean;
  selectedMemberCount: null | MemberCount;
  memberCounts: MemberCount[];
  users: User[];
  onUserSelect: (user: User) => void;
  onUsersFilter: (memberCount: MemberCount | null) => void;
  selectedUserId: string;
}

enum UserRole {
  Participants = 'Participants',
  Moderators = 'Moderators',
  Observers = 'Observers',
}

export const PeopleList: React.FC<Props> = ({
  isLoading,
  memberCounts,
  selectedMemberCount,
  users,
  onUserSelect,
  onUsersFilter,
  selectedUserId,
}) => {
  const filterUsersBtnRef = useRef<HTMLButtonElement>(null);
  const [showFilteringDropdown, setShowFilteringPopup] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState<UserRole>(UserRole.Participants);

  const mainRoles = useMemo(() => {
    if (!memberCounts) {
      return;
    }

    return memberCounts.filter((memberCount) =>
      Object.values(UserRole).includes(memberCount.Id as UserRole),
    );
  }, [memberCounts]);

  const participantsMemberCounts = useMemo(() => {
    if (!memberCounts) {
      return;
    }

    return memberCounts.filter(
      (memberCount) =>
        !Object.values(UserRole).includes(memberCount.Id as UserRole) && memberCount.Id !== 'All',
    );
  }, [memberCounts]);

  const participantsMemberCount = useMemo(() => {
    return (
      memberCounts.find((memberCount) => memberCount.Id === UserRole.Participants) ||
      memberCounts[0]
    );
  }, [memberCounts]);

  const handleUsersFilter = (memberCount: MemberCount | null) => {
    if (memberCount?.Count === 0) {
      return;
    }

    onUsersFilter(memberCount);
    setShowFilteringPopup(false);
  };

  const handleRoleFiltering = (memberCount: MemberCount) => {
    setSelectedUserRole(memberCount.Id as UserRole);
    onUsersFilter(memberCount);
  };

  return (
    <div className={cn(styles.container, { [styles.expandContainer]: showFilteringDropdown })}>
      <div className={styles.filterWrapper}>
        {mainRoles &&
          mainRoles.map((mainRole) => (
            <button
              className={cn(styles.roleFilterButton, {
                [styles.roleFilterButtonActive]: selectedUserRole === mainRole.Id,
              })}
              key={mainRole.Id}
              onClick={() => handleRoleFiltering(mainRole)}
            >
              {mainRole.Id}
            </button>
          ))}
        {selectedUserRole === UserRole.Participants && (
          <button
            className={styles.filterUsersBtn}
            onClick={() => setShowFilteringPopup((prev) => !prev)}
            ref={filterUsersBtnRef}
          >
            {selectedMemberCount?.Id === 'Participants' ? 'All' : selectedMemberCount?.Id}
            <div className={styles.memberCount}>{selectedMemberCount?.Count}</div>
            <ArrowDownIcon
              className={cn(styles.buttonArrowIcon, {
                [styles.buttonArrowIconActive]: showFilteringDropdown,
              })}
            />
          </button>
        )}

        {showFilteringDropdown && participantsMemberCounts && (
          <FilterUsersDropdown
            memberCounts={participantsMemberCounts}
            onClose={() => setShowFilteringPopup(false)}
            onSelect={handleUsersFilter}
            outsideTriggerBtnRef={filterUsersBtnRef}
            participantsMemberCount={participantsMemberCount}
          />
        )}
      </div>
      <div className={styles.userList}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {users.map((user) => (
              <PeopleItem
                key={user.PersonId}
                onUserSelect={onUserSelect}
                selectedUserId={selectedUserId}
                user={user}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
