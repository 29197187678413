import MenuItem from '@mui/material/MenuItem';
import SelectMenu from '@mui/material/Select';

type Option = {
  label: string;
  value: number;
};

type SelectFieldProps = {
  value: number;
  onChange: (newValue: number) => void;
  options: Option[];
  className?: string;
  placeholder?: string;
};

export const Select: React.FC<SelectFieldProps> = ({
  value,
  onChange,
  options,
  className,
  placeholder = 'Please select...',
}) => {
  return (
    <SelectMenu
      className={className}
      displayEmpty
      MenuProps={{ disablePortal: true }}
      onChange={(event) => onChange(event.target.value as number)}
      renderValue={(value: number) =>
        options.find((option) => option.value === value)?.label || placeholder
      }
      value={value}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </SelectMenu>
  );
};
