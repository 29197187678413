import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '@/hooks';
import { ActivityCard } from '@/pages/activities/activityPosts/activityCard';
import { PinPost } from '@/pages/activities/pinsSection/pinPost';
import { useGetActivityDetailsQuery } from '@/store/api';
import { ActivityPost, ImageSize } from '@/types';
import { PinsImage } from './pinsImage';
import styles from './pinsSection.module.css';

interface Props {
  activityId: string;
}

export const PinsSection: React.FC<Props> = ({ activityId }) => {
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const posts = useAppSelector((state) => state.posts);
  const [postsList, setPostsList] = useState<ActivityPost[]>([]);

  const { data: activityDetails } = useGetActivityDetailsQuery({ discussionId, activityId });
  const [pinsImageOriginalSize, setPinsImageOriginalSize] = useState<ImageSize | null>(null);

  const pinImage = activityDetails?.[0].HeatmapOptions?.HeatMapImageUrl;

  const pinPosts = useMemo(() => {
    return posts.filter((post) => post.HeatMapX && post.HeatMapY);
  }, [posts]);

  useEffect(() => {
    setPostsList(posts);
  }, [posts]);

  useEffect(() => {
    if (activityDetails) {
      const img = new Image();
      img.onload = () => {
        setPinsImageOriginalSize({ width: img.naturalWidth, height: img.naturalHeight });
      };
      img.src = activityDetails[0].HeatmapOptions?.HeatMapImageUrl || '';
    }
  }, [activityDetails]);

  const filterPosts = useCallback(
    (filteredPosts: ActivityPost[]) => {
      if (!filteredPosts.length) {
        setPostsList(posts);
        return;
      }
      setPostsList(
        pinPosts.filter((post) => {
          return filteredPosts.some(
            (filteredPost) => filteredPost.TopicThreadId === post.TopicThreadId,
          );
        }),
      );
    },
    [pinPosts, posts],
  );

  return (
    <div className={styles.pinsSection}>
      {pinImage && <PinsImage imageUrl={pinImage} onFilter={filterPosts} pinPosts={pinPosts} />}
      {pinImage && pinsImageOriginalSize && !!postsList.length && (
        <div className={styles.postsWrapper}>
          {postsList.map((post) => {
            if (post.HeatMapX && post.HeatMapY) {
              return (
                <PinPost
                  imageUrl={pinImage}
                  key={post.TopicThreadId}
                  pinsImageOriginalSize={pinsImageOriginalSize}
                  post={post}
                />
              );
            }
            return <ActivityCard isPinsActivity key={post.TopicThreadId} post={post} />;
          })}
        </div>
      )}
    </div>
  );
};
