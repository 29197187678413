import { useMemo } from 'react';
import { ActivityPost, PostHighlight } from '@/types';
import { ExpandablePanel } from '../expandablePanel';
import { HighlightCard } from './highlightCard';

interface Props {
  post: ActivityPost;
  highlights: PostHighlight[];
  setPostHighlights: React.Dispatch<React.SetStateAction<PostHighlight[]>>;
}

export const Highlights: React.FC<Props> = ({ post, highlights, setPostHighlights }) => {
  const sortedHighlightsByDate = useMemo(
    () =>
      highlights.toSorted(
        (a, b) => new Date(b.CreateDate).getTime() - new Date(a.CreateDate).getTime(),
      ),
    [highlights],
  );

  return (
    <ExpandablePanel
      heading="Highlights"
      isModeratorPost={post.ThreadCreatorMembershipStatus === 6}
    >
      {sortedHighlightsByDate.map((highlight) => (
        <HighlightCard
          highlight={highlight}
          key={highlight.HighlightId}
          setPostHighlights={setPostHighlights}
          small
        />
      ))}
    </ExpandablePanel>
  );
};
