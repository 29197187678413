import React, { useMemo } from 'react';
import { Avatar } from '@mui/material';
import cn from 'classnames';
import styles from './userAvatar.module.css';

interface Props {
  className: string;
  imageUrl: string;
  userRole: number;
}

export const UserAvatar: React.FC<Props> = ({ className, imageUrl, userRole }) => {
  const borderColor = useMemo(() => {
    switch (userRole) {
      case 6:
        return '#f39c12';
      case 7:
        return '#037358';
      default:
        return '#3980ea';
    }
  }, [userRole]);
  return (
    <div className={styles.avatarWrapper} style={{ borderColor }}>
      <Avatar alt="Avatar" className={cn(styles.personAvatar, className)} src={imageUrl} />
    </div>
  );
};
