import { Fragment, useEffect, useMemo, useState } from 'react';
import Emoji from 'react-emoji-render';
import {
  DeleteOutline,
  DriveFileRenameOutline,
  MoreVertSharp,
  PictureAsPdf,
} from '@mui/icons-material';
import { Alert, Button, IconButton, Popover, Snackbar } from '@mui/material';
import cn from 'classnames';
import { DeleteModal } from '@/components/deleteModal';
import { ImagesGallery } from '@/components/imagesGallery';
import { ThreadForm } from '@/components/threadForm';
import { UserAvatar } from '@/components/userAvatar';
import { NOTIFICATION_DURATION } from '@/constants';
import { useAppSelector } from '@/hooks';
import { useDeleteReplyMutation, useEditReplyMutation } from '@/store/api/replies';
import { ActivityPost, Attachment, ThreadData } from '@/types';
import { formatDateTime } from '@/utils/formatDateTime';
import styles from './activityComment.module.css';

interface Props {
  comment: ActivityPost;
  isAuthor: boolean;
  isChat?: boolean;
  onEdit: (post: ActivityPost, remove?: boolean) => void;
  isFirstComment?: boolean;
  isLastComment?: boolean;
}

export const ActivityComment: React.FC<Props> = ({
  comment,
  isAuthor,
  isChat,
  onEdit,
  isFirstComment,
  isLastComment,
}) => {
  const [edit, setEdit] = useState(false);
  const [editText, setEditText] = useState('');
  const [editAttachments, setEditAttachments] = useState<Attachment[]>([]);
  const [editReply, { isLoading: isReplyUpdating }] = useEditReplyMutation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [isRecentComment, setRecentComment] = useState(false);
  const [controlsAnchorEl, setControlsAnchorEl] = useState<HTMLButtonElement | null>(null);

  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const activityId = useAppSelector((state) => state.selectedActivity.id);
  const [deleteComment, { isLoading: isCommentDeleting }] = useDeleteReplyMutation();

  useEffect(() => {
    const timeDiff = Date.now() - Date.parse(comment.CreateDate);
    if (timeDiff < 5000) {
      setRecentComment(true);
    }
  }, [comment.CreateDate]);

  const toggleEdit = () => {
    setEdit((prevState) => !prevState);
    setEditText(comment.ThreadContent);
    setEditAttachments(comment.Attachments);
    closeControlsPopover();
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const handleEdit = async ({ text }: ThreadData) => {
    const updatedComment = { ...comment, ThreadContent: text };
    const res = await editReply({
      body: updatedComment,
      threadId: comment.TopicThreadId,
      discussionId,
      activityId,
    });
    if ('data' in res) {
      onEdit(updatedComment);
      setEditText('');
      setEdit(false);
    } else {
      console.error(res.error);
    }
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
    closeControlsPopover();
  };

  const handleErrorNotificationClose = () => {
    setShowErrorNotification(false);
  };

  const openControlsPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setControlsAnchorEl(event.currentTarget);
  };

  const closeControlsPopover = () => {
    setControlsAnchorEl(null);
  };

  const imageAttachments = useMemo(
    () => comment.Attachments.filter((attachment) => attachment.FileType === 0),
    [comment],
  );

  const pdfAttachments = comment.Attachments.filter((attachment) => attachment.FileType === 2);
  const otherAttachments = comment.Attachments.filter(
    (attachment) => attachment.FileType !== 0 && attachment.FileType !== 2,
  );

  const handleDelete = async () => {
    await deleteComment({ discussionId, postId: comment.TopicThreadId });
    onEdit && onEdit(comment, true);
    toggleDeleteModal();
  };

  return (
    <div
      className={cn(styles.container, {
        [styles.chatContainer]: isChat,
        [styles.newComment]: isRecentComment && !isChat,
        [styles.newChatComment]: isRecentComment && isChat,
        [styles.authorsComment]: isAuthor,
        [styles.firstComment]: isFirstComment,
        [styles.lastComment]: isLastComment,
      })}
    >
      <div className={styles.body}>
        {isChat && (
          <div className={styles.commentInfo}>
            <p className={styles.commentAuthorName}>{comment.ThreadCreatorName}</p>

            <time className={styles.published}>{formatDateTime(comment.CreateDate)}</time>
          </div>
        )}
        <div className={cn(styles.comment, { [styles.chatComment]: isChat })}>
          {isChat && (
            <UserAvatar
              className={styles.chatAvatarSize}
              imageUrl={comment.ThreadCreatorUserImage}
              userRole={comment.ThreadCreatorMembershipStatus}
            />
          )}

          <div className={styles.contentWrapper}>
            {(comment.CanBeEdited || comment.CanBeDeleted) && (
              <>
                <IconButton className={styles.controlsButton} onClick={openControlsPopover}>
                  <MoreVertSharp className={styles.controlsIcon} />
                </IconButton>

                <Popover
                  anchorEl={controlsAnchorEl}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: isAuthor ? 50 : -10,
                  }}
                  classes={{ paper: 'popover' }}
                  onClose={closeControlsPopover}
                  open={Boolean(controlsAnchorEl)}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: isAuthor ? 'left' : 'right',
                  }}
                >
                  <div className={styles.controlsPopoverContent}>
                    <figure
                      className={cn(styles.decorativeTriangle, {
                        [styles.triangleLeft]: isAuthor,
                        [styles.triangleRight]: !isAuthor,
                      })}
                    />
                    {comment.CanBeEdited && (
                      <Button className={styles.controlsPopoverButton} onClick={toggleEdit}>
                        <DriveFileRenameOutline className={styles.controlsPopoverItemIcon} />
                        <div className={styles.controlsPopoverItemText}>Edit</div>
                      </Button>
                    )}
                    {comment.CanBeDeleted && (
                      <Button className={styles.controlsPopoverButton} onClick={toggleDeleteModal}>
                        <DeleteOutline className={styles.controlsPopoverItemIcon} />
                        <div className={styles.controlsPopoverItemText}>Delete</div>
                      </Button>
                    )}
                  </div>
                </Popover>
              </>
            )}

            {edit ? (
              <ThreadForm
                attachments={editAttachments}
                edit={edit}
                isChat={isChat}
                loading={isReplyUpdating}
                onCancel={closeEdit}
                onSubmit={handleEdit}
                submitButtonText={'Save'}
                text={editText}
              />
            ) : (
              <div
                className={cn(styles.content, styles.bubble, styles.right, {
                  [styles.chatContent]: isChat,
                })}
              >
                {!!pdfAttachments.length && (
                  <div className={styles.filesWrapper}>
                    {pdfAttachments.map((attachment) => (
                      <Fragment key={attachment.AttachmentId}>
                        {attachment.FileType === 2 && (
                          <a
                            className={styles.attachmentWrapper}
                            href={attachment.Files[0].Link.split('?')[0]}
                            key={attachment.AttachmentId}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <div className={styles.attachmentFiller}>
                              <PictureAsPdf className={styles.attachmentIcon} />
                              <p className={styles.attachmentName}>{attachment.Files[0].Name}</p>
                            </div>
                          </a>
                        )}
                      </Fragment>
                    ))}
                  </div>
                )}

                <p className={styles.text}>
                  <Emoji text={comment.ThreadContent} />
                </p>

                {!!imageAttachments.length && <ImagesGallery imageAttachments={imageAttachments} />}

                {otherAttachments.map((attachment) => (
                  <Fragment key={attachment.AttachmentId}>
                    {attachment.FileType === 1 && (
                      <div className={styles.attachmentWrapper}>
                        <div className={styles.videoAttachmentWrapper}>
                          <video
                            controls
                            height="100%"
                            src={attachment.Files[0].Link}
                            width="100%"
                          ></video>
                        </div>
                      </div>
                    )}

                    {attachment.FileType === 19 && (
                      <div key={attachment.AttachmentId}>
                        <div className={styles.audioAttachmentWrapper}>
                          <audio className={styles.attachmentAudio} controls>
                            <source
                              className={styles.audio}
                              src={attachment.Files[0].Link}
                              type="audio/mpeg"
                            />
                          </audio>
                        </div>
                        <p className={styles.attachmentNameAudio}>{attachment.Files[0].Name}</p>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
          </div>
        </div>

        {!isChat && (
          <div className={styles.info}>
            <>
              <p className={styles.commentAuthorName}>{comment.ThreadCreatorName}</p>•
              {comment.VisibleToId && (
                <div className={styles.mentionTo}>{`@ ${comment.VisibleToFullName} •`}</div>
              )}
            </>
            <time className={styles.published}>{formatDateTime(comment.CreateDate)}</time>
          </div>
        )}
      </div>

      {!isChat && (
        <UserAvatar
          className={styles.avatarSize}
          imageUrl={comment.ThreadCreatorUserImage}
          userRole={comment.ThreadCreatorMembershipStatus}
        />
      )}

      <DeleteModal
        isOpen={showDeleteModal}
        loading={isCommentDeleting}
        objectName="comment"
        onClose={toggleDeleteModal}
        onConfirm={handleDelete}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={NOTIFICATION_DURATION}
        onClose={handleErrorNotificationClose}
        open={showErrorNotification}
      >
        <Alert onClose={handleErrorNotificationClose} severity="error" sx={{ width: '100%' }}>
          Error. Please try again later
        </Alert>
      </Snackbar>
    </div>
  );
};
