declare global {
  interface Window {
    baseUrl?: string;
  }
}

const fallbackBaseUrl = 'https://demo.api.staging.qualzy.co.uk/api';

export const getBaseUrl = (): string => {
  const baseUrl = window.baseUrl || '';
  const currentUrl = window.location.href;
  const subdomainRegex = /^(https?:\/\/)([^./]+)\./;
  const matchResult = currentUrl.match(subdomainRegex);

  if (matchResult) {
    const firstSubdomain = matchResult[2];
    return baseUrl.replace('*', firstSubdomain);
  } else if (baseUrl.includes('*')) {
    const domainRegex = /^https?:\/\/([^./]+)/;
    const [, domain] = baseUrl.match(domainRegex) || [];
    if (domain) {
      const outputUrl = baseUrl.replace('*', domain);
      return outputUrl.includes('*') ? fallbackBaseUrl : outputUrl;
    }
  }

  return baseUrl;
};
