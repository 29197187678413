import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Modal } from '@mui/material';
import styles from './cookiesConsent.module.css';

export const CookiesConsent = () => {
  const [showModal, setShowModal] = useState(true);
  const handleClose = () => setShowModal(false);

  const handleConsent = () => {
    document.cookie = 'cookiesConsent=true;max-age=' + 60 * 60 * 24 * 30;
    // set cookies for 1 month
    handleClose();
  };

  const hasConsent = document.cookie.includes('cookiesConsent');

  return (
    <div>
      {showModal && !hasConsent && (
        <Modal
          aria-describedby="modal-modal-description"
          aria-labelledby="modal-modal-title"
          onClose={handleClose}
          open={showModal}
        >
          <Box className={styles.container}>
            <header className={styles.headerContainer}>
              <h2 className={styles.modalHeading}>Cookie consent</h2>
              <Button onClick={handleClose}>
                <CloseIcon />
              </Button>
            </header>
            <p>
              This website stores cookies on your computer. These cookies are used to collect
              information about how you interact with our website and allow us to remember you. We
              use this information in order to improve and customize your browsing experience and
              for analytics and metrics about our visitors both on this website and other media.
            </p>
            <p>
              If you decline, your information won’t be tracked when you visit this website. A
              single cookie will be used in your browser to remember your preference not to be
              tracked.
            </p>
            <hr className={styles.horizontalLine} />
            <div className={styles.buttonContainer}>
              <Button variant="text">Cookie settings</Button>
              <Button onClick={handleConsent} variant="contained">
                Accept all
              </Button>
              <Button className={styles.declineButton} onClick={handleClose} variant="text">
                Decline
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};
