import { useCallback, useMemo } from 'react';
import TextBalancer from 'react-wrap-balancer';
import { FirstPage as FirstPageIcon, Replay as ReplayIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { ReplySuggestion } from '@/types';
import styles from './suggestions.module.css';

interface Props {
  prompt?: string;
  suggestions: ReplySuggestion[];
  onSelect: (suggestion: ReplySuggestion) => void;
  onClose: () => void;
  onRegenerate: () => void;
}

export const Suggestions: React.FC<Props> = ({
  prompt,
  suggestions,
  onSelect,
  onClose,
  onRegenerate,
}) => {
  const suggestionsByGroup = useMemo(() => {
    if (!suggestions.length) {
      return null;
    }
    return suggestions.reduce<Record<number, ReplySuggestion[]>>((acc, item) => {
      if (acc[item.SuggestionType]) {
        acc[item.SuggestionType].push(item);
      } else {
        acc[item.SuggestionType] = [item];
      }
      return acc;
    }, {});
  }, [suggestions]);

  const getSuggestionsTypeLabels = useCallback(
    (type: number | string) => {
      /*
        1 - ThankYou: This is to simply thank a participant for a post.
        2 - TellMeMore: This is to thank a participant for a post AND ask them some follow-up questions to encourage them to tell some more aspects of their post.
        3 - Custom: AI Suggestions created when someone asks for custom ones.
      */
      return {
        1: 'Thank You',
        2: 'Request more info',
        3: prompt,
      }[type];
    },
    [prompt],
  );

  return (
    <>
      {suggestionsByGroup && (
        <div className={styles.suggestionsContainer}>
          {Object.entries(suggestionsByGroup).map(([group, suggestionsGroup]) => (
            <div className={styles.suggestionsGroup} key={group}>
              <label className={styles.suggestionsGroupName}>
                {getSuggestionsTypeLabels(group)}
              </label>
              {suggestionsGroup.map((suggestion) => (
                <button
                  className={styles.suggestion}
                  key={suggestion.SuggestionId}
                  onClick={() => onSelect(suggestion)}
                >
                  <TextBalancer className={styles.suggestionText}>
                    {suggestion.SuggestionText}
                  </TextBalancer>
                </button>
              ))}
            </div>
          ))}

          <div className={styles.footer}>
            {prompt && (
              <Button
                className={styles.footerButton}
                disableRipple
                onClick={onRegenerate}
                variant="text"
              >
                <ReplayIcon className={styles.iconReplay} />
                Generate again
              </Button>
            )}
            <Button className={styles.footerButton} disableRipple onClick={onClose} variant="text">
              <FirstPageIcon className={styles.iconHide} />
              Hide
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
