import WarningIcon from '@mui/icons-material/Warning';
import styles from './accessDenied.module.css';

export const AccessDenied = () => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <WarningIcon className={styles.warningIcon} />
        <p className={styles.warningText}>Invalid request</p>
      </div>
    </div>
  );
};
