import DeleteForever from '@mui/icons-material/DeleteForever';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Modal } from '@mui/material';
import cn from 'classnames';
import styles from './deleteModal.module.css';

interface Props {
  objectName: string;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
}

export const DeleteModal: React.FC<Props> = ({
  objectName,
  isOpen,
  onConfirm,
  onClose,
  loading,
}) => {
  return (
    <Modal
      aria-describedby="Modal window requires confirmation or canceling of delete action"
      aria-labelledby="Delete confirmation modal window"
      onClose={onClose}
      open={isOpen}
    >
      <div className={styles.deleteModal}>
        <DeleteForever className={styles.deleteIcon} />
        <h3 className={styles.modalTitle}>
          {`Are you sure you want to delete this ${objectName}`}
        </h3>
        <div className={styles.modalButtons}>
          <LoadingButton
            className={cn(styles.modalButton, styles.modalButtonDanger)}
            loading={loading}
            onClick={onConfirm}
            variant="contained"
          >
            Delete
          </LoadingButton>
          <Button className={styles.modalButton} onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
