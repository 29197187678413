import { Fragment, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { PictureAsPdf } from '@mui/icons-material';
import { ImagesGallery } from '@/components/imagesGallery';
import { VideoList } from '@/components/videoList';
import { useAppSelector } from '@/hooks';
import { useGetVideosWithTranscriptionQuery } from '@/store/api/videos';
import { ActivityPost, PostHighlight } from '@/types';
import { HighlightableText } from '../highlightableText';
import styles from './socialActivityPost.module.css';

interface Props {
  post: ActivityPost;
  postHighlights: PostHighlight[];
  setPostHighlights: React.Dispatch<React.SetStateAction<PostHighlight[]>>;
}

export const SocialActivityPost: React.FC<Props> = ({
  post,
  postHighlights,
  setPostHighlights,
}) => {
  const [isOverlapping, setIsOverlapping] = useState(false);
  const isVideoInPost = post.Attachments.some((attachment) => attachment.FileType === 1);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const { data: videoAttachments } = useGetVideosWithTranscriptionQuery(
    {
      discussionId,
      threadId: post.TopicThreadId,
    },
    {
      skip: !isVideoInPost,
    },
  );

  useEffect(() => {
    if (isOverlapping) {
      toast.warn('Selected text is not highlightable');
      setIsOverlapping(false);
    }
  }, [isOverlapping]);

  const { imageAttachments, pdfAttachments, musicAttachments } = useMemo(() => {
    const imageAttachments = post.Attachments.filter(
      (attachment) => attachment.FileType === 0 && attachment.Files.length,
    );

    const pdfAttachments = post.Attachments.filter((attachment) => attachment.FileType === 2);
    const musicAttachments = post.Attachments.filter((attachment) => attachment.FileType === 19);
    return { imageAttachments, pdfAttachments, musicAttachments };
  }, [post]);

  return (
    <>
      {!!pdfAttachments.length && (
        <div className={styles.filesWrapper}>
          {pdfAttachments.map((attachment) => (
            <Fragment key={attachment.AttachmentId}>
              <a
                className={styles.attachmentWrapper}
                href={attachment.Files[0].Link.split('?')[0]}
                key={attachment.AttachmentId}
                rel="noreferrer"
                target="_blank"
              >
                <div className={styles.attachmentFiller}>
                  <PictureAsPdf className={styles.attachmentIcon} />
                  <p className={styles.attachmentName}>{attachment.Title}</p>
                </div>
              </a>
            </Fragment>
          ))}
        </div>
      )}

      {post.ThreadContent && (
        <HighlightableText
          highlights={postHighlights}
          postId={post.TopicThreadId}
          setHighlights={setPostHighlights}
          textContent={post.ThreadContent}
        />
      )}

      {!!imageAttachments.length && <ImagesGallery imageAttachments={imageAttachments} />}

      {videoAttachments && <VideoList videoAttachments={videoAttachments} />}

      {!!musicAttachments.length && (
        <div>
          {musicAttachments.map((attachment) => (
            <Fragment key={attachment.AttachmentId}>
              <div key={attachment.AttachmentId}>
                <div className={styles.audioAttachmentWrapper}>
                  <audio className={styles.audio} controls>
                    <source src={attachment.Files[0].Link.split('?')[0]} type="audio/mpeg" />
                  </audio>
                </div>
                <p className={styles.attachmentNameAudio}>{attachment.Title}</p>
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
};
