import { ActivityPost, ImageSize } from '@/types';

export const calculatePinPopupPosition = (
  post: ActivityPost,
  imageSize: ImageSize,
  windowWidth: number,
) => {
  const halfOfPopupWidth = 250 / 2;
  const halfOfPinWidth = windowWidth > 768 ? 12 / 2 : 6 / 2;
  const pinBorder = windowWidth > 768 ? 6 : 3;
  const halfOfPinBorder = pinBorder / 2;
  const centerCoords = String(-(halfOfPopupWidth - halfOfPinWidth - halfOfPinBorder)) + 'px';
  const notEnoughSpaceCoords = String(-pinBorder) + 'px';
  const topOffset = '20px';

  const freeSpaceAtRight = imageSize.width - imageSize.width * post.HeatMapX;
  const freeSpaceAtLeft = imageSize.width * post.HeatMapX;
  if (post.HeatMapX > 0.5) {
    if (freeSpaceAtRight < halfOfPopupWidth) {
      return { right: notEnoughSpaceCoords, top: topOffset };
    } else {
      return {
        left: centerCoords,
        top: topOffset,
      };
    }
  } else {
    if (freeSpaceAtLeft < halfOfPopupWidth) {
      return { left: notEnoughSpaceCoords, top: topOffset };
    } else {
      return {
        right: centerCoords,
        top: topOffset,
      };
    }
  }
};
