import { RefObject } from 'react';
import { Button } from '@mui/material';
import { useClickOutside } from '@/hooks';
import { FilterButton } from '@/pages/people/peopleList/filterButton';
import { MemberCount } from '@/types';
import styles from './filterUsersDropdown.module.css';

interface Props {
  memberCounts: MemberCount[];
  participantsMemberCount: MemberCount;
  onSelect: (memberCount: MemberCount | null) => void;
  onClose: () => void;
  outsideTriggerBtnRef: RefObject<HTMLElement>;
}

export const FilterUsersDropdown: React.FC<Props> = ({
  memberCounts,
  participantsMemberCount,
  onSelect,
  onClose,
  outsideTriggerBtnRef,
}) => {
  const dropdownRef = useClickOutside(onClose, outsideTriggerBtnRef);

  return (
    <div className={styles.filterUsersDropdown} ref={dropdownRef}>
      <Button className={styles.filterButton} onClick={() => onSelect(participantsMemberCount)}>
        All
        <div className={styles.numberInCircle}>{participantsMemberCount.Count}</div>
      </Button>
      {memberCounts.map((memberCount) => (
        <FilterButton key={memberCount.Id} memberCount={memberCount} onSelect={onSelect} />
      ))}
    </div>
  );
};
