import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Alert, Avatar, Snackbar } from '@mui/material';
import cn from 'classnames';
import { LikesCounter } from '@/components/likesCounter';
import { Loading } from '@/components/loading';
import { ThreadForm } from '@/components/threadForm';
import { NOTIFICATION_DURATION, REPLY_SUGGESTIONS_COLLAPSE_DURATION } from '@/constants';
import { useActions, useAppSelector } from '@/hooks';
import { usePostComments } from '@/hooks/usePostComments';
import { CardScoreActivityPost } from '@/pages/activities/activityPosts/cardScoreActivityPost/cardScoreActivityPost';
import { RepliesChat } from '@/pages/activities/activityPosts/repliesChat';
import { useEditActivityPostMutation } from '@/store/api';
import { useGetPostRepliesQuery, useSendReplyMutation } from '@/store/api/replies';
import { ActivityPost, PostHighlight, ThreadData } from '@/types';
import { formatDateTime } from '@/utils/formatDateTime';
import { ActivityIcon } from '../activityList/activityIcon';
import { FormActivityPost } from './formActivityPost/formActivityPost';
import { SocialActivityPost } from './socialActivityPost/socialActivityPost';
import { PostContentTags } from './tags/postContentTags';
import { PostUserTags } from './tags/postUserTags';
import { ActivityComment } from './activityComment';
import { ExpandablePanels } from './expandablePanels';
import { PostControls } from './postControls';
import { ReplySuggestions } from './replySuggestions';
import styles from './activityCard.module.css';

enum ActivityContentType {
  FORM,
  CARD_SCORE,
  SOCIAL_POST,
}

interface Props {
  post: ActivityPost;
  isPinsActivity?: boolean;
  isPeopleSection?: boolean;
  rowLayout?: boolean;
}

export const ActivityCard: React.FC<Props> = ({
  post,
  rowLayout = false,
  isPinsActivity,
  isPeopleSection,
}) => {
  const actions = useActions();
  const [comments, setComments] = useState<ActivityPost[]>([]);
  const [edit, setEdit] = useState(false);
  const [editText, setEditText] = useState('');
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [isRepliesLoading, setIsRepliesLoading] = useState(false);
  const [isChatRepliesLoading, setIsChatRepliesLoading] = useState(false);
  const isOpenExpandablePanels = useAppSelector((state) => state.topSection.isOpenExpandablePanels);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const activities = useAppSelector((state) => state.activities);
  const postCreationDate = formatDateTime(post.CreateDate);
  const [postRef, inView] = useInView({ triggerOnce: true });
  const [postHighlights, setPostHighlights] = useState<PostHighlight[]>(post.Highlights);

  const { data: commentsRes, isFetching } = useGetPostRepliesQuery(
    { discussionId, threadId: post.TopicThreadId },
    { skip: !inView },
  );

  useEffect(() => {
    if (!commentsRes) {
      return;
    }
    setComments(
      commentsRes.toSorted(
        (commentA: ActivityPost, commentB: ActivityPost) =>
          Number(new Date(commentA.CreateDate)) - Number(new Date(commentB.CreateDate)),
      ),
    );
  }, [commentsRes]);

  const [sendReply] = useSendReplyMutation();
  const [editPost, { isLoading: isPostUpdating }] = useEditActivityPostMutation();

  const handleSend = async ({ text, attachments, mentionInfo }: ThreadData) => {
    const body = {
      ThreadContent: text.trim(),
      Attachments: attachments,
      Visibility: 0,
      VisibleToId: '',
      VisibleToFullName: '',
    };

    if (mentionInfo) {
      body.Visibility = 1;
    }

    if (mentionInfo?.userId) {
      body.VisibleToId = mentionInfo.userId;
      body.VisibleToFullName = mentionInfo.userName;
    }

    if (!mentionInfo || mentionInfo?.userId) {
      setIsRepliesLoading(true);
    } else {
      setIsChatRepliesLoading(true);
    }

    const res = await sendReply({
      discussionId,
      activityId: post.DiscussionTopicId,
      threadId: post.TopicThreadId,
      body,
    });

    if ('data' in res) {
      setComments((comments) => [...comments, res.data]);
      setIsRepliesLoading(false);
      setIsChatRepliesLoading(false);
    } else {
      console.error(res.error);
      setShowErrorNotification(true);
    }
  };

  const handlePostEdit = async ({ text, attachments }: ThreadData) => {
    const res = await editPost({
      body: {
        ...post,
        ThreadContent: text,
        Attachments: attachments,
      },
      discussionId,
      activityId: post.DiscussionTopicId,
      threadId: post.TopicThreadId,
    });

    if ('data' in res) {
      actions.updatePost(res.data);
      setEditText('');
      setEdit(false);
    } else {
      console.error(res.error);
      setShowErrorNotification(true);
    }
  };

  const handleCommentEdit = useCallback(
    (comment: ActivityPost, remove?: boolean) => {
      setComments((prev) => {
        if (remove) {
          return prev.filter((item) => item.TopicThreadId !== comment.TopicThreadId);
        }
        return prev.map((item) => (item.TopicThreadId === comment.TopicThreadId ? comment : item));
      });
    },
    [setComments],
  );

  const handleErrorNotificationClose = () => {
    setShowErrorNotification(false);
  };

  const closePostEdit = () => {
    setEdit(false);
  };

  const activity = useMemo(() => {
    return (
      activities.find((activity) => activity.DiscussionTopicId === post.DiscussionTopicId) ||
      activities[0]
    );
  }, [activities, post.DiscussionTopicId]);

  const activityContentType: ActivityContentType = useMemo(() => {
    switch (activity.ContentType) {
      case 6:
        return ActivityContentType.FORM;
      case 18:
        return ActivityContentType.CARD_SCORE;
      default:
        return ActivityContentType.SOCIAL_POST;
    }
  }, [activity.ContentType]);

  const summaryText = useMemo(() => post.Summaries?.[0]?.SummaryText, [post]);

  const { mainComments, chatComments } = usePostComments(comments);

  const [suggestReplyText, setSuggestReplyText] = useState('');
  const threadFormContainer = useRef<HTMLDivElement | null>(null);

  const applySuggestionText = useCallback(
    (text: string) => {
      setSuggestReplyText(text);
      setTimeout(() => {
        //* Expecting the suggestions to finish their collapse animation before focusing on Input */
        threadFormContainer.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, REPLY_SUGGESTIONS_COLLAPSE_DURATION);
    },
    [setSuggestReplyText],
  );

  const toggleEdit = () => {
    setEdit((prevState) => !prevState);
    setEditText(post.ThreadContent);
  };

  const postHasVideo = useMemo(() => {
    return post.Attachments.some((attachment) => [1].includes(attachment.FileType));
  }, [post.Attachments]);

  return (
    <div
      className={cn(styles.cardWrapper, {
        [styles.moderatorsCardWrapper]: post.IsModerator,
        [styles.rowLayoutWrapper]: rowLayout,
        [styles.cardWrapperPinsActivity]: isPinsActivity,
      })}
      ref={postRef}
    >
      <div className={styles.mainContent}>
        <div
          className={cn(styles.card, {
            [styles.pinned]: post.IsPinned,
          })}
        >
          <header
            className={cn(styles.header, {
              [styles.headerModerator]: post.IsModerator,
            })}
          >
            <div
              className={cn(styles.userAndControlsWrapper, {
                [styles.answerRequireRibbon]: post.IsResponseMandatory,
              })}
            >
              <div className={styles.userContainer}>
                <div
                  className={cn(styles.avatarContainer, {
                    [styles.avatarWithoutBorder]: isPeopleSection,
                  })}
                >
                  {isPeopleSection ? (
                    <ActivityIcon activity={activity} peopleSection />
                  ) : (
                    <Avatar
                      alt="Avatar"
                      className={styles.avatar}
                      src={post.ThreadCreatorUserImage}
                    />
                  )}
                </div>

                <div className={styles.userInfo}>
                  <div className={styles.userName}>
                    {isPeopleSection ? activity.Title : post.ThreadCreatorName}
                  </div>
                  <div className={styles.publishInfo}>
                    {post.IsModerator && (
                      <>
                        <div className={styles.userStatus}>moderator</div>
                        <span className={styles.dividerDot}>•</span>
                      </>
                    )}
                    <time className={styles.published}>{postCreationDate}</time>
                  </div>
                </div>
              </div>
              <PostControls onEdit={toggleEdit} post={post} />
            </div>
            <div className={styles.userTagsWrapper}>
              <PostUserTags userId={post.ThreadCreatorId} />
            </div>
          </header>
          <div className={styles.threadContent}>
            <PostContentTags post={post} />
            {edit ? (
              <ThreadForm
                attachments={post.Attachments}
                edit={edit}
                loading={isPostUpdating}
                onCancel={closePostEdit}
                onSubmit={handlePostEdit}
                submitButtonText="Confirm"
                text={editText}
              />
            ) : (
              <div className={styles.content}>
                {summaryText && (
                  <div className={styles.summary}>
                    <div className={styles.summaryHeader}>Summary</div>
                    <div className={styles.summaryText}>{summaryText}</div>
                  </div>
                )}

                {activityContentType === ActivityContentType.FORM && (
                  <FormActivityPost
                    post={post}
                    postHighlights={postHighlights}
                    setPostHighlights={setPostHighlights}
                  />
                )}
                {activityContentType === ActivityContentType.CARD_SCORE && (
                  <CardScoreActivityPost post={post} />
                )}
                {activityContentType === ActivityContentType.SOCIAL_POST && (
                  <SocialActivityPost
                    post={post}
                    postHighlights={postHighlights}
                    setPostHighlights={setPostHighlights}
                  />
                )}
                <LikesCounter className={cn({ [styles.absoluteLeftBottom]: postHasVideo })} />
              </div>
            )}

            <div className={styles.commentSection}>
              <div className={styles.commentList}>
                {isFetching && <Loading overContent />}
                {mainComments.map((comment, index, comments) => (
                  <ActivityComment
                    comment={comment}
                    isAuthor={post.ThreadCreatorId === comment.ThreadCreatorId}
                    isFirstComment={index === 0}
                    isLastComment={index === comments.length - 1}
                    key={comment.TopicThreadId}
                    onEdit={handleCommentEdit}
                  />
                ))}
              </div>
            </div>

            <div className={styles.replySuggestionsWrapper}>
              <ReplySuggestions onSelect={applySuggestionText} post={post} />
            </div>

            <div ref={threadFormContainer}>
              <ThreadForm
                comments={comments}
                loading={isRepliesLoading}
                onCancel={() => setSuggestReplyText('')}
                onSubmit={handleSend}
                post={post}
                text={suggestReplyText}
              />
            </div>
          </div>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={NOTIFICATION_DURATION}
            onClose={handleErrorNotificationClose}
            open={showErrorNotification}
          >
            <Alert onClose={handleErrorNotificationClose} severity="error" sx={{ width: '100%' }}>
              Error. Please try again later
            </Alert>
          </Snackbar>
        </div>
        <RepliesChat
          chatComments={chatComments}
          isChatRepliesLoading={isChatRepliesLoading}
          onEdit={handleCommentEdit}
          onSubmit={handleSend}
          post={post}
        />
      </div>

      {isOpenExpandablePanels && (
        <ExpandablePanels
          activityType={activity.ContentType}
          highlights={postHighlights}
          post={post}
          setPostHighlights={setPostHighlights}
          skip={!inView}
        />
      )}
    </div>
  );
};
