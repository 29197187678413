import React from 'react';
import { CircularProgress } from '@mui/material';
import cn from 'classnames';
import styles from './loading.module.css';

interface loading {
  overContent?: boolean;
  noOffset?: boolean;
  className?: string;
}

export const Loading: React.FC<loading> = ({ overContent, noOffset, className }) => {
  return (
    <div
      className={cn(className, {
        [styles.overContentWrapper]: overContent,
        [styles.noOffset]: noOffset,
        [styles.wrapper]: !overContent && !noOffset,
      })}
    >
      <CircularProgress />
    </div>
  );
};
