import { useMemo } from 'react';
import cn from 'classnames';
import { ActivityPost, CardScale } from '@/types';
import styles from './questionScale.module.css';

interface Props {
  post: ActivityPost;
  question: CardScale;
}

export const QuestionScale: React.FC<Props> = ({ question, post }) => {
  const { cardWithHighestRating, cardWithLowestRating, formattedText } = useMemo(() => {
    const scaleCards = post.CardsScoreAnswers.find(
      (cardsScoreAnswer) => cardsScoreAnswer.CardsScaleId === question.CardScaleId,
    );

    const formattedScaleCards =
      scaleCards?.Cards.map((card) => ({ ...card, Value: card.Value.toFixed(1) })).toSorted(
        (a, b) => Number(b.Value) - Number(a.Value),
      ) || [];

    const cardWithHighestRating = formattedScaleCards[0];
    const cardWithLowestRating = formattedScaleCards[formattedScaleCards.length - 1];
    const formattedText = formattedScaleCards
      .slice(1, formattedScaleCards.length - 1)
      .map((card) => `${card.CardText} ${card.Value}`)
      .join(' · ');

    return { cardWithHighestRating, cardWithLowestRating, formattedText };
  }, [question.CardScaleId, post.CardsScoreAnswers]);

  return (
    <div className={styles.questionScale}>
      <div className={styles.questionTitle}>{question.Title.toUpperCase()}</div>
      <div className={styles.scaleContent}>
        <div className={styles.highAndLowRatingCards}>
          <div className={styles.highRatingCardTitle}>{cardWithHighestRating.CardText}</div>
          <div className={styles.lowRatingCardTitle}>{cardWithLowestRating.CardText}</div>
        </div>
        <div className={styles.scaleWrapper}>
          <div className={cn(styles.numberInCircle, styles.highRatingCard)}>
            {cardWithHighestRating.Value}
          </div>
          <div className={styles.middleCards}>{formattedText}</div>
          <div className={cn(styles.numberInCircle, styles.lowRatingCard)}>
            {cardWithLowestRating.Value}
          </div>
        </div>
      </div>
    </div>
  );
};
