import { createTheme } from '@mui/material';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Roboto, "Segoe UI", "Helvetica Neue", Arial, sans-serif',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: '#258E61',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: 10,
          minWidth: 44,
          height: 44,
          borderRadius: 8,
          textTransform: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 44,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 10,
          minHeight: 44,
          textTransform: 'none',
        },
      },
    },
  },
});
