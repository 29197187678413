import { Close } from '@mui/icons-material';
import { Avatar, Box, IconButton, Modal } from '@mui/material';
import { ThreadForm } from '@/components/threadForm';
import styles from '@/components/topSection/topSection.module.css';
import { useAppSelector, useUserRole } from '@/hooks';
import { ThreadData } from '@/types';

interface Props {
  closeModal: () => void;
  onSubmit: (prompt: string) => void;
}

export const ReplyPromptModal: React.FC<Props> = ({ closeModal, onSubmit }) => {
  const currentUser = useAppSelector((state) => state.user);
  const { userRole } = useUserRole();

  const submitHandler = async ({ text }: ThreadData) => {
    onSubmit(text);
  };

  return (
    <Modal
      aria-describedby="Modal window for generating reply suggestions"
      aria-labelledby="Generate suggestion replies"
      onClose={closeModal}
      open
    >
      <div className={styles.postModalWrapper}>
        <Box className={styles.postModal}>
          <div className={styles.postAuthor}>
            <Avatar alt="Avatar" className={styles.avatar} src={currentUser.ImageURL} />
            <div className={styles.authorInfo}>
              <h3 className={styles.authorName}>{currentUser.FullName}</h3>
              <span className={styles.authorStatus}>you ({userRole})</span>
            </div>
            <div className={styles.closeModal}>
              <IconButton className={styles.closeModalButton} onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
          </div>
          <ThreadForm
            onSubmit={submitHandler}
            placeholder="Request a reply to the post e.g. “ask them about the car”"
            submitButtonText="Generate"
            withoutAttachments
          />
        </Box>
      </div>
    </Modal>
  );
};
