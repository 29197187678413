import { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { Loading } from '@/components/loading';
import { useAppSelector } from '@/hooks';
import { useGetActivityDetailsQuery } from '@/store/api';
import { Activity } from '@/types';
import { purifyHtml } from '@/utils/purifyHtml';
import styles from './instructionsTab.module.css';

interface Props {
  activity: Activity;
}

export const InstructionsTab: React.FC<Props> = ({ activity }) => {
  const [isImageDownloaded, setIsImageDownloaded] = useState(false);
  const [isVideoDownloaded, setIsVideoDownloaded] = useState(false);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const activityId = activity.DiscussionTopicId;
  const { data: activityDetails } = useGetActivityDetailsQuery({ discussionId, activityId });

  const instructions = useMemo(() => {
    if (!activityDetails) {
      return '';
    }
    return purifyHtml(activityDetails[0].ThoroughDescription, {
      allowedAttr: ['style', 'src', 'class'],
    });
  }, [activityDetails]);

  const { instructionImageLink, instructionVideoLink } = useMemo(() => {
    if (!activityDetails) {
      return { instructionImageLink: '' };
    }
    const instructionImageLink =
      activityDetails[0].Attachments.find((attachment) => attachment.FileType === 15)?.Files[0]
        .Link || '';

    const instructionVideo = activityDetails[0].Attachments.find(
      (attachment) => [16, 17].includes(attachment.FileType), // 16 - Vimeo, 17 - Youtube
    );

    let instructionVideoLink = '';

    if (instructionVideo) {
      const instructionVideoCode = instructionVideo.Code || '';
      if (instructionVideo?.FileType === 16) {
        instructionVideoLink = `https://vimeo.com/${instructionVideoCode}`;
      }

      if (instructionVideo?.FileType === 17) {
        instructionVideoLink = `https://www.youtube.com/watch?v=${instructionVideoCode}`;
      }
    }

    return { instructionImageLink, instructionVideoLink };
  }, [activityDetails]);

  useEffect(() => {
    if (instructionImageLink) {
      const img = new Image();
      img.onload = () => setIsImageDownloaded(true);
      img.src = instructionImageLink;
    }
  }, [instructionImageLink]);

  return (
    <div className={styles.instructions}>
      {(!isImageDownloaded || !isVideoDownloaded) && <Loading overContent />}
      {isImageDownloaded && (
        <img
          alt="instruction image"
          className={styles.instructionImage}
          src={instructionImageLink}
        />
      )}
      <div className="wcc-editor-content" dangerouslySetInnerHTML={{ __html: instructions }} />
      <div style={{ width: '100%', aspectRatio: 16 / 9 }}>
        <ReactPlayer
          controls
          height="100%"
          onReady={() => setIsVideoDownloaded(true)}
          url={instructionVideoLink}
          width="100%"
        />
      </div>
    </div>
  );
};
