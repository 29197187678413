interface FileTypeData {
  fileType: number;
  innerFileType: number;
}

export const getFileTypes = (fileType: string): FileTypeData => {
  let fileTypeValue = -1;
  let innerFileTypeValue = -1;

  if (fileType.includes('image')) {
    fileTypeValue = 0;
    innerFileTypeValue = 1;
  } else if (fileType.includes('video')) {
    fileTypeValue = 1;
    innerFileTypeValue = 11;
  } else if (fileType.includes('audio')) {
    fileTypeValue = 19;
    innerFileTypeValue = 12;
  } else if (fileType.includes('pdf')) {
    fileTypeValue = 2;
    innerFileTypeValue = 8;
  }

  return { fileType: fileTypeValue, innerFileType: innerFileTypeValue };
};
