import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActivitySection, Color } from '@/types';

interface topSectionState {
  highlightColor: Color;
  colorsMap: Color[];
  isOpenExpandablePanels: boolean;
  isHighlightingActive: boolean;
  activeSection: ActivitySection;
}

const initialState: topSectionState = {
  highlightColor: {
    Color: '',
    CreateDate: '',
    EditDate: '',
    HighlightColorId: '',
    Title: '',
  },
  colorsMap: [],
  isOpenExpandablePanels: false,
  isHighlightingActive: false,
  activeSection: ActivitySection.Posts,
};

export const topSectionSlice = createSlice({
  name: 'topSection',
  initialState,
  reducers: {
    setIsHighlightingActive(state, action: PayloadAction<boolean>) {
      state.isHighlightingActive = action.payload;
    },
    setHighlightColor(state, action: PayloadAction<Color>) {
      state.highlightColor = action.payload;
    },
    resetHighlightColor(state) {
      state.highlightColor = initialState.highlightColor;
    },
    toggleExpandablePanels(state) {
      state.isOpenExpandablePanels = !state.isOpenExpandablePanels;
    },
    setColorsMap(state, action: PayloadAction<Color[]>) {
      state.colorsMap = action.payload;
    },
    setActiveSection(state, action: PayloadAction<ActivitySection>) {
      state.activeSection = action.payload;
    },
  },
});
