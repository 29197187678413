import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import styles from './tabButton.module.css';

interface Props {
  title: string;
  icon: React.ReactNode;
  isDisabled?: boolean;
  isActive: boolean;
  onClick: () => void;
}

export const TabButton: React.FC<Props> = ({ title, icon, isDisabled, isActive, onClick }) => {
  return (
    <Tooltip title={isActive ? '' : isDisabled ? `No ${title} in this activity` : title}>
      <div className={styles.wrapper}>
        <button
          className={cn(styles.tab, {
            [styles.tabActive]: isActive,
          })}
          disabled={isDisabled}
          onClick={onClick}
        >
          <div className={styles.iconWrapper}>{icon}</div>
          <span className={styles.text}>{title}</span>
        </button>
      </div>
    </Tooltip>
  );
};
