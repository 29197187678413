import React, { useEffect, useState } from 'react';
import Emoji from 'react-emoji-render';
import { MoreVertSharp } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import cn from 'classnames';
import { UserAvatar } from '@/components/userAvatar';
import { HighlightsPopover } from '@/pages/activities/activityPosts/expandablePanels/highlights/highlightsPopover';
import { TagItem } from '@/pages/activities/activityPosts/tags/tagItem';
import { headerTab } from '@/pages/activities/highlightsSection/highlightsSection';
import { PostHighlight, Theme } from '@/types';
import { formatDateTime } from '@/utils/formatDateTime';
import { ThemeBadge } from '../themeBadge';
import styles from './highlightCard.module.css';

interface Props {
  activeTab?: headerTab;
  highlight: PostHighlight;
  small?: boolean;
  setPostHighlights: React.Dispatch<React.SetStateAction<PostHighlight[]>>;
}

export const HighlightCard: React.FC<Props> = ({
  activeTab,
  highlight,
  setPostHighlights,
  small,
}) => {
  const [selectedThemes, setSelectedThemes] = useState<Theme[]>(highlight.DiscussionThemes);
  const [controlsAnchorEl, setControlsAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    setSelectedThemes(highlight.DiscussionThemes);
  }, [highlight]);

  return (
    <div className={styles.highlightCard} style={{ borderColor: highlight.Color.Color }}>
      <div className={styles.header}>
        {activeTab === headerTab.Theme && (
          <div className={styles.userAndThemesWrapper}>
            <div className={styles.user}>
              {highlight.creatorAvatar && highlight.creatorRole && (
                <UserAvatar
                  className={styles.imageSize}
                  imageUrl={highlight.creatorAvatar}
                  userRole={highlight.creatorRole}
                />
              )}
              <div className={styles.userName}>{highlight.creatorName}</div>
            </div>
            <ThemeBadge
              highlight={highlight}
              selectedThemes={selectedThemes}
              setPostHighlights={setPostHighlights}
              setSelectedThemes={setSelectedThemes}
            />
          </div>
        )}
        <div className={styles.timeWrapper}>
          <time className={styles.time}>{formatDateTime(highlight.CreateDate)}</time>

          <IconButton
            className={styles.controlsButton}
            onClick={(e) => setControlsAnchorEl(e.currentTarget)}
          >
            <MoreVertSharp className={styles.controlsIcon} />
          </IconButton>
        </div>

        <HighlightsPopover
          controlsAnchorEl={controlsAnchorEl}
          highlight={highlight}
          setControlsAnchorEl={setControlsAnchorEl}
          setHighlights={setPostHighlights}
        />
      </div>

      <div className={cn(styles.text, { [styles.textSmall]: small })}>
        <Emoji text={highlight.Text} />
      </div>

      {highlight.Note && (
        <div className={cn(styles.note, { [styles.noteSmall]: small })}>{highlight.Note}</div>
      )}

      {!!highlight.Tags.length && (
        <div className={styles.tagsContainer}>
          {highlight.Tags.map((tag) => (
            <TagItem key={tag.TagValue} small={small} tag={tag} />
          ))}
        </div>
      )}
    </div>
  );
};
