import { useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Tooltip } from '@mui/material';
import { CreatorBadge } from '@/components/creatorBadge/creatorBadge';
import { VideoTranscript } from '@/components/videoList/videoTranscript';
import { useAppSelector, useWindowDimensions } from '@/hooks';
import { useGetVideosWithTranscriptionQuery } from '@/store/api/videos';
import { VideoObject } from '@/types';
import { PlayButton } from './playButton';
import styles from './videosSection.module.css';

interface Props {
  video: VideoObject;
  rowLayout?: boolean;
}

export const VideoItem: React.FC<Props> = ({ video, rowLayout }) => {
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const [showOverlays, setShowOverlays] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const playerRef = useRef<ReactPlayer>(null);
  const thumbnailImageRef = useRef<HTMLImageElement>(null);
  const [progressTime, setProgressTime] = useState(0);
  const videoFile = video.files.find((file) => file.Type === 6);
  const thumbnailImage = video.files.find((file) => file.Type === 7);
  const { width: windowWidth } = useWindowDimensions();
  const { postCreatorAvatar, postCreatorName } = video;

  const { data: videoAttachments } = useGetVideosWithTranscriptionQuery({
    discussionId,
    threadId: video.postId,
  });

  const transcriptSentences = useMemo(() => {
    if (videoAttachments) {
      return videoAttachments.find((attachment) => attachment.AttachmentId === video.attachmentId)
        ?.Sentences;
    }

    return undefined;
  }, [videoAttachments, video.attachmentId]);

  const handleSentencePlay = (startTime: number, isCurrentSentence: boolean) => {
    if (isCurrentSentence) {
      setIsPlaying((prev) => !prev);
      return;
    }

    if (showOverlays && thumbnailImageRef.current) {
      thumbnailImageRef.current.click();
      setIsPlaying(false);

      setTimeout(() => {
        if (playerRef.current) {
          playerRef.current.seekTo(startTime);
          setIsPlaying(true);
        }
      }, 1000);
    } else {
      if (playerRef.current) {
        playerRef.current.seekTo(startTime);
        setIsPlaying(true);
      }
    }
  };

  const summaryText = useMemo(() => {
    const text = video.summaries?.[0]?.SummaryText || '';

    switch (true) {
      case rowLayout:
        return text.slice(0, 170);
      case windowWidth < 480:
        return text.slice(0, 300);
      case windowWidth < 768:
        return text.slice(0, 450);
      case windowWidth < 1024:
        return text.slice(0, 300);
      case windowWidth < 1440:
        return text.slice(0, 800);
      default:
        return text.slice(0, 320);
    }
  }, [windowWidth, video.summaries, rowLayout]);

  return (
    <div className={styles.videoWithTranscript}>
      <div className={styles.layoutWrapper}>
        <div className={styles.playerWrapper}>
          <ReactPlayer
            className={styles.player}
            controls={true}
            height="100%"
            light={
              <img
                alt="Thumbnail"
                className={styles.thumbnailImage}
                height="100%"
                ref={thumbnailImageRef}
                src={thumbnailImage?.Link}
                width="100%"
              />
            }
            onClickPreview={() => setShowOverlays(false)}
            onProgress={({ playedSeconds }) => {
              setProgressTime(playedSeconds);
            }}
            playIcon={<PlayButton />}
            playing={isPlaying}
            ref={playerRef}
            url={videoFile?.Link}
            width="100%"
          />
          {showOverlays && (
            <>
              <CreatorBadge
                avatar={postCreatorAvatar}
                date={videoFile?.CreateDate || ''}
                name={postCreatorName}
              />
              {summaryText && (
                <div className={styles.summaryWrapper}>
                  {video.summaries && summaryText.length < video.summaries[0].SummaryText.length ? (
                    <Tooltip title={video.summaries[0].SummaryText}>
                      <div className={styles.summary}>{summaryText + '...'}</div>
                    </Tooltip>
                  ) : (
                    <div className={styles.summary}>{summaryText}</div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.layoutWrapper}>
        {transcriptSentences && (
          <VideoTranscript
            className={styles.transcriptWrapper}
            isPlaying={isPlaying}
            onSentencePlay={handleSentencePlay}
            progressTime={progressTime}
            transcriptSentences={transcriptSentences}
          />
        )}
      </div>
    </div>
  );
};
