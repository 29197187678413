import { useMemo } from 'react';
import cn from 'classnames';
import { ReactComponent as EyeCrossed } from '@/assets/icons/eyeCrossed.svg';
import { ReactComponent as Person } from '@/assets/icons/person.svg';
import { Tag } from '@/types';
import styles from './tags.module.css';

interface Props {
  tag: Tag;
  small?: boolean;
}

export const TagItem: React.FC<Props> = ({ tag, small }) => {
  const Icon = useMemo(
    () =>
      ({
        0: EyeCrossed,
        2: Person,
      })[tag.Visibility] || null,
    [tag.Visibility],
  );

  return (
    <div className={styles.tagWrapper}>
      <div
        className={cn(styles.tag, { [styles.tagSmall]: small })}
        style={{
          borderColor: tag.TagColor,
          color: tag.TagColor,
          backgroundColor: tag.TagColor,
        }}
      >
        {tag.TagValue}
        {Icon && (
          <Icon className={cn({ [styles.tagIconSmall]: small })} style={{ color: tag.TagColor }} />
        )}
      </div>
    </div>
  );
};
