import { useState } from 'react';
import { ThreadForm } from '@/components/threadForm';
import { UserAvatar } from '@/components/userAvatar';
import { useAppSelector } from '@/hooks';
import { ActivityComment } from '@/pages/activities/activityPosts/activityComment';
import { ActivityPost, ThreadData } from '@/types';
import styles from './repliesChat.module.css';

interface Props {
  chatComments: ActivityPost[];
  post: ActivityPost;
  onEdit: (post: ActivityPost, remove?: boolean) => void;
  onSubmit: (threadData: ThreadData) => void;
  isChatRepliesLoading: boolean;
}

export const RepliesChat: React.FC<Props> = ({
  chatComments,
  post,
  onEdit,
  onSubmit,
  isChatRepliesLoading,
}) => {
  const [showChat, setShowChat] = useState(false);
  const loggedUser = useAppSelector((state) => state.user);
  const isModeratorOrObserver = [6, 7].includes(loggedUser.TaskStatus);

  const startChatText = chatComments.length ? 'Show chat' : 'Start chatting about this post';

  return (
    <>
      {isModeratorOrObserver && (
        <div className={styles.startChatWrapper}>
          <button className={styles.startChatBtn} onClick={() => setShowChat((prev) => !prev)}>
            {showChat ? 'Hide chat' : startChatText}
            {!showChat && !!chatComments.length && (
              <div className={styles.messagesNumber}>{chatComments.length}</div>
            )}
          </button>
        </div>
      )}

      {showChat && (
        <div className={styles.commentList}>
          {chatComments.map((comment) => (
            <ActivityComment
              comment={comment}
              isAuthor={post.PostedById === comment.PostedById}
              isChat
              key={comment.TopicThreadId}
              onEdit={onEdit}
            />
          ))}
          <div className={styles.taggedChatForm}>
            <div>
              <UserAvatar
                className={styles.chatAvatarSize}
                imageUrl={loggedUser.ImageURL}
                userRole={loggedUser.TaskStatus}
              />
            </div>
            <ThreadForm
              comments={chatComments}
              isChat
              loading={isChatRepliesLoading}
              onSubmit={onSubmit}
              post={post}
            />
          </div>
        </div>
      )}
    </>
  );
};
