export interface Choice {
  ChoiceId: string;
  ChoiceOrder: number;
  ChoiceText: string;
  ChoiceValue: number;
}

interface ChoiceOption {
  ChoiceRatingType: number;
  ChoiceSelectionLimitMode: number;
  DisplayLayoutType: number;
  SingleChoiceControlType: number;
  VisibilityTagModeType: number;
}

export interface Question {
  ChoiceOptions: ChoiceOption;
  Choices: Choice[];
  ProfileQuestionId: string;
  QuestionTitle: string;
  QuestionType: number;
}

interface SurveyPage {
  PageId: string;
  PageName: string;
  PageOrder: number;
  Questions: Question[];
}

interface Survey {
  Pages: SurveyPage[];
}

interface SurveyOptions {
  Survey: Survey;
  SurveyAllowsMultipleResponses: boolean;
}

export interface Activity {
  CreateDate: string;
  DiscussionId: string;
  DiscussionTopicId: string;
  ContentType: number;
  TopicContentType: number;
  QuickSell: string | null;
  // "TopicContentType Description"
  // 0 = Text Comments (Comments with some optional media)
  // 1 = Text Articles (Comments with a big text input field (deprecated))
  // 3 = Collection of Documents (Multiple attachments without comment text)
  // 4 = Heatmap (Comments pinned to some position at provided image)
  // 5 = Multiple Comments (Topic contains multiple questions each of that must be answered by participants)
  // 6 = Survey (Topic contains a survey. Each question of a survey assigned a tag to TaskMember record)
  // 7 = VoxPop (Each comment and reply in this topic can only contain a video without any text)
  // 8 = Ideation (Participants enter Ideas and go through the Ideation Process of entering Ideas and then Ranking them)
  // 9 = VirtualPinBoard (A Participant can create a virtual pin board (or mood board) by putting images and text on a virtual board to create an image/collage.)
  // 10 = InformationOnly (This is a read-only kind of topic - NO COMMENTS CAN BE ENTERED. )
  // 11 = VideoPresentation (Participants will watch videos (probably in a full-screen mode) and leave some emojies and comments while watching it )
  // 12 = MediaOnly (This topic is for media only - Either pictures or videos)
  // 13 = ScreenRecording (This topic is for screen recording)
  // 14 = ExternalSurvey (External survey as a topic)
  // 16 = InternalSurvey (Internal survey)
  // 17 = CardSort (Participants will be able to add predefined cards to different card groups)
  // 18 = CardScore (Participants will be able to add predefined cards to different scales)
  // 19 = AudioUpload (Participants will be able to upload audio files )
  // 20 = Chat (Participants will be able to chat inside Whatsapp like UI)
  TopicType: number;
  PostCreatorRestrictionType: number;
  IdeationStage: number;
  IdeationStagingStage: number;
  TopicCompletionNotificationsType: number;
  SurveyAllowsMultipleResponses: boolean;

  IsGroup: boolean;
  IsAnswered: boolean;
  Icon: Icon;
  IconImageUrl: string;
  GroupId: string | null;
  Title: string;
  ToDoCount: number;
  TotalPostsCount: number | null;
  TopicOrder: number;
  IsFeatured: boolean;
  CommentsVisibility: number;
  CommentsAdditionalVisibility: number;
  IconClass: null;
  IconColor: string;
  IconStyle: number;
  // IconStyle is an enum with the following values:
  // 0 = use Media Image
  // 1 = use Font Awesome Icon
  AnswerCount: number;
  PollHasAnswers: boolean;
  TopicVisibilityTags: any[];
  TopicVisibility: number;
  ThoroughDescription: string;
  VisibleAfter: string | null;
  VisibleBefore: string | null;
  SmallImageUrl: string;
  MediumImageUrl: string;
  LargeImageUrl: string;
  // VideoPresentationType: null;
  // LatestResponseDate: null;
  Media: string;
  // MediaImageHash: null;
  // QuickSell: null;
  // RecentPostsCount: null;
  // ToDoCountForObsPosts: null;
  // IconBackgroundColor: null;
  // IconBorderColor: null;
  // TaskMemberCode: null;
  Description: string;
}

export interface ActivityDetails {
  Attachments: Attachment[];
  DiscussionTopicId: string;
  DiscussionId: string;
  ContentType: number;
  IsGroup: boolean;
  GroupId: string;
  Title: string;
  QuickSell: string;
  TopicOrder: number;
  IconStyle: number;
  Icon: Icon;
  IsFeatured: boolean;
  VisibleBefore: string;
  CompletionNotificationsType: number;
  CommentsVisibility: number;
  TotalPostsCount: number;
  ToDoCount: number;
  Visibility: number;
  VisibilityTags: any[];
  Availability: number;
  HeatmapOptions?: HeatmapOptions;
  SmallImageUrl: string;
  SurveyOptions: SurveyOptions;
  CardScoreOptions?: CardScoreOption;
  MediumImageUrl: string;
  LargeImageUrl: string;
  ThoroughDescription: string;
}

interface CardScoreOption {
  CardDisplayType: number;
  ScaleMarkingType: number;
  CardScales: CardScale[];
  Cards: ScoreOptionCard[];
}

export interface CardScale {
  CardScaleId: string;
  Title: string;
  Description: string;
  Order: number;
  LeftName: string;
  RightName: string;
  OutOf: number;
  IncludeQuestion: boolean;
  QuestionText: string;
  QuestionIsMandatory: boolean;
}

export interface ScoreOptionCard {
  CardId: string;
  Text: string;
  IsPinned: boolean;
  Order: number;
  CardType: number;
}

interface HeatmapOptions {
  UnlimitedPins: boolean;
  MinPins: number;
  MaxPins: number;
  HeatMapImageUrl: string;
  HeatMapOption: number;
}

export interface Icon {
  IconBackgroundColor: string;
  IconBorderColor: string;
  IconClass: string;
  IconColor: string;
}

export interface SurveyAnswer {
  DataDate: string;
  DataLocation1: number;
  DataLocation2: number;
  DataProfileQuestionChoiceId: string;
  DataTextLong1: string;
  DataTextLong2: string;
  DataTextShort1: string;
  DataTextShort2: string;
  DataTextShort3: string;
  DataTextShort4: string;
  DataTextShort5: string;
  Order: number;
  QuestionId: string;
}

interface Summary {
  SummaryId: string;
  SummaryText: string;
  TargetType: number;
}

export interface NewTag {
  SpecialType: number;
  TagColor: string;
  TagValue: string;
  Visibility: number;
}

export interface Tag extends NewTag {
  ContentTagId: string;
}

interface ActivityPostNote {
  NoteId: string;
  TopicThreadId: string;
  Text: string;
  CreateDate: string;
  Creator: {
    PersonId: string;
    FirstName: string;
    LastName: string;
  };
}

interface CardScoreAnswer {
  CardsScoreAnswerId: string;
  CardsScaleId: string;
  ContentId: string;
  OutOf: number;
  Cards: AnswerCard[];
}

export interface AnswerCard {
  CardsScoreAnswerCardId: string;
  CardText: string;
  Value: number;
}

export interface ActivityPost {
  AnonymousName: string | null;
  ApproveDate: null;
  Attachments: Attachment[];
  CanBeDeleted: boolean;
  CanBeEdited: boolean;
  CardsScoreAnswers: CardScoreAnswer[];
  CreateDate: string;
  CreatorNickname: null;
  ThreadCreatorMembershipStatus: number;
  DiscussionTopicId: string;
  DislikesCount: number;
  DownvotesCount: null;
  EditInfo: string;
  HasReplies: boolean;
  HasTags: boolean;
  HeatMapX: number;
  HeatMapY: number;
  HideFromReporting: boolean;
  Highlights: PostHighlight[];
  IsApproved: boolean;
  IsCollectionOfDocuments: boolean;
  IsDisliked: boolean;
  IsFollowed: boolean;
  IsHighlight: boolean;
  IsInToDoList: boolean;
  IsLiked: boolean;
  IsMobile: boolean;
  IsModerator: boolean;
  IsModeratorIdea: boolean;
  IsObserver: boolean;
  IsPinned: boolean;
  IsReportedAsAbuse: boolean;
  IsResponseMandatory: boolean;
  IsThreadCreator: boolean;
  IsVoxPop: boolean;
  LikesCount: number;
  MoodTag: null;
  MoodTagId: string;
  Notes: ActivityPostNote[];
  OriginalThreadContent: null;
  ParentThreadId: null;
  PostedById: string;
  ThreadCreatorId: string;
  QuestionTag: null;
  RepliesCount: number;
  ReplySuggestions?: ReplySuggestion[];
  SlideId: null;
  SpamComplaintCount: number;
  SurveyAnswers: SurveyAnswer[];
  Summaries?: Summary[];
  Tags: Tag[];
  TestOnly: boolean;
  ThreadContent: string;
  ThreadCreator: string;
  ThreadCreatorName: string;
  ThreadCreatorAnonymousName: string;
  ThreadCreatorUserImage: string;
  ThreadParticipantIDs: [];
  ThreadType: null;
  ThreadVisibilityTagIDs: [];
  Title: string | null;
  TopicContentType: number;
  TopicQuestionId: null;
  TopicThreadId: string;
  TranslatedThreadContent: string;
  UAInfo: string;
  UpvotesCount: null;
  Visibility: number;
  VisibleToFullName: string;
  VisibleToId?: string;
}

export interface ReplySuggestion {
  SuggestionId: string;
  SuggestionType: number;
  SuggestionText: string;
}

export interface UserSettings {
  AccountId: string;
  DiscussionId: string;
  PersonToken: string;
  TaskMemberRole: number;
  // "TaskMemberRole Description:"
  // Unknown     = 0, This person is a participant and can complete activities in this project
  // Participant = 1,
  // Observer    = 2,
  // Moderator   = 3,
  // Complete    = 4 (This person no longer has access to this project)
}

export interface DiscussionsResponse {
  DiscussionId: string;
  AccountId: string;
  Type: number;
  OnlineStatus: number;
  VisibleAfter: string;
  VisibleBefore: string;
  Title: string;
  QuickSell: string;
  ThoroughDescription: string;
  ThoroughDescriptionSecondary: any;
  HeroImageIsShown: boolean;
  HeroImageUrl: string;
  CommentPlaceholder: any;
  ReplyPlaceholder: any;
  LanguageCode: string;
  ShowTour: boolean;
  PostsFilteringTypeForModerators: number;
  PostsFilteringTypeForObservers: number;
  PostsFilteringTypeForParticipants: number;
  TwoFactorAuth: number;
  TwoFactorAuthModObs: number;
  EditCommentTimeout: any;
  DeleteCommentTimeout: any;
  EnableNewUI: boolean;
  ActivitiesLayout: number;
  IncludeTopHeroImage: boolean;
  HeroImageOverlayType: number;
}

interface Dot {
  color: string;
  tooltip: string;
}

export interface StatusDots {
  1: Dot;
  2: Dot;
  3: Dot;
  4: Dot;
}

interface AdditionalUserData {
  GroupId: string;
  Status: number;
  Title: string;
  TopicId: string;
  TopicOrder: number;
}

export interface User {
  AccountId: string;
  TopicCompletionData: AdditionalUserData[];
  TaskMemberId: string;
  DisplayName: string;
  ImageUrl: string;
  PersonId: string;
  Tags: Tag[];
  TotalPosts: number;
  LastPost?: string;
  EngagementTime?: string;
  Role: number;
  UnreadPosts: number;
}

export interface CurrentUser {
  AccountId: string;
  AgreedToTermsAndConditions: true;
  AnonymousName: string;
  CanBeContacted: boolean;
  CanBeSentSMS: boolean;
  CreateDate: string;
  EmailAddress: string;
  FirstName: string;
  FullName: string;
  ImageURL: string;
  IsAdmin: boolean;
  IsRegistrationIncomplete: boolean;
  IsTwoFactorAuthVerified: boolean;
  LastName: string;
  Nickname: string;
  PersonId: string;
  TaskMemberCode: string;
  TaskStatus: number;
  Token: string;
  Verified: boolean;
}

export interface ThreadData {
  attachments: Attachment[];
  mentionInfo?: MentionRequestInfo | null;
  text: string;
}

export interface MentionList {
  userId: string;
  userImage: string;
  userMemberStatus: number;
  userName: string;
}

export interface MentionRequestInfo {
  userId: string | null;
  userName: string;
}

export interface Attachment {
  rawFile?: File;
  AttachmentId: string;
  UserFileId: any;
  Code: string;
  ClientContentId: string;
  Title: string;
  QuickSell: string;
  Link: string;
  OriginalLink: any;
  CannedText: any;
  CDNFileName: string;
  OriginalFileName: string;
  FileExtension: string;
  FileHash: string;
  FileType: number;
  Width: any;
  Height: any;
  HasHQVersion: boolean;
  HasThumbnail: boolean;
  Duration: any;
  HQFileLength: any;
  LQFileLength: any;
  TranscriptionEnabled: boolean;
  TranscriptStatus: number;
  TranscriptContent: any;
  TranscriptGoStatus: number;
  TranscriptGoContent: any;
  WordsDensity: any;
  AverageConfidence: any;
  Summaries: Summary[];
  TranscriptionSentences: any;
  CreateDate: string;
  IsHidden: boolean;
  IsWatched: boolean;
  IsIgnored: boolean;
  EditDate: any;
  Files: File[];
}

export interface File {
  UserFileId: string;
  Name: string;
  OriginalName: string;
  Token: any;
  Extension: string;
  Link: string;
  Hash: string;
  Width: number;
  Height: number;
  Type: number;
  CreateDate: string;
}

export interface Emoji {
  native: string;
}

export interface ActivityStats {
  DiscussionTopicId: string;
  ProfilePages: ProfilePage[];
}

interface ProfilePage {
  ProfilePageId: string;
  ProfileChoiceQuestions: ProfileChoiceQuestion[];
  ProfileRankQuestions: ProfileRankQuestion[];
}

interface ProfileChoiceQuestion {
  ProfileQuestionId: string;
  RespondentsCount: number;
  IsAllNumeric: boolean;
  ProfileAnswers: ProfileChoiceAnswer[];
}

interface ProfileChoiceAnswer {
  ProfileQuestionChoiceId: string;
  TextValue: string;
  Value: number;
  IsNA: boolean;
}

interface ProfileRankQuestion {
  ProfileQuestionId: string;
  ProfileAnswers: ProfileRankAnswer[];
}

interface ProfileRankAnswer {
  ProfileQuestionChoiceId: string;
  Average: number;
  TimesSelectedAsTop: number;
  TimesSelectedAsLast: number;
}

export interface StatsOverview {
  Invited: number;
  Participants: number;
  Started: number;
  UpToDate: number;
}

export interface PostHighlightInputBody extends Record<string, unknown> {
  //extends Record because API accepts list of tags in format like this: {Tags[0].ContentTagId: '111', Tags[1].ContentTagId: '222'}
  ThreadHighlightId?: string;
  End: number;
  'Color[HighlightColorId]': string;
  HighlightTagIds?: string[];
  KeyPointId?: string;
  Note?: string;
  ProfileQuestionId?: string;
  Start: number;
  Text: string;
  TextForReport?: string;
  ThreadId: string;
  QuestionId?: string;
}

export interface PostHighlight {
  Color: Color;
  creatorAvatar?: string;
  creatorName?: string;
  creatorRole?: number;
  CreateDate: string;
  End: number;
  HighlightId: string;
  Note?: string;
  Start: number;
  Tags: Tag[];
  Text: string;
  TextForReport: string;
  ThreadId: string;
  ThreadHighlightId?: string;
  QuestionId?: string;
  DiscussionThemes: Theme[];
}

interface SuggestedHighlightSentence {
  End: number;
  SentenceId: string;
  Start: number;
  Text: string;
}

export interface SuggestedHighlight {
  SuggestionId: string;
  ThreadId: string;
  KeyPointId: string;
  Sentences: SuggestedHighlightSentence[];
  Start: number;
  End: number;
  QuestionId?: string;
  HighlightText: string;
}

export interface Color {
  Color: string;
  CreateDate: string;
  EditDate: string;
  Title: string;
  HighlightColorId: string;
}

export interface PostRequestBody {
  ThreadContent: string;
  ThreadParticipantIDs?: string[];
  ThreadVisibilityTagIDs?: string[];
  Visibility: number;
  Attachments: Attachment[];
  VisibleToId?: string;
}

export enum ActivitySection {
  Posts = 'Posts',
  Highlights = 'Highlights',
  Images = 'Images',
  Videos = 'Videos',
  Pins = 'Pins',
  Heatmap = 'Heatmap',
}

export type ActivityTabContentAvailability = Partial<Record<ActivitySection, boolean>>;

export interface SidebarTab {
  title: string;
  icon: React.ReactNode;
  content: React.ReactNode;
}

export type PostTagsContext = 'thread' | 'participants';

export interface ClickPosition {
  top: number;
  left: number;
}

export interface ImageObject extends File {
  attachmentId: string;
  postCreatorName: string;
  postCreatorAvatar: string;
}

export interface VideoObject {
  attachmentId: string;
  postId: string;
  postCreatorName: string;
  postCreatorAvatar: string;
  summaries: Summary[];
  files: File[];
}

export interface Theme {
  DiscussionThemeId: string;
  DiscussionId: string;
  Name: string;
  Quicksell: string;
  Description: string;
  CreateDate: string;
  Image: Attachment;
}

export interface ThemesWithHighlights {
  id: string;
  name: string;
  highlights: PostHighlight[];
}

export interface PersonsWithHighlights extends ThemesWithHighlights {
  image: string;
  role: number;
}

export interface MemberCount {
  AggregationType: number;
  Count: number;
  Id: string;
}

export interface ImageSize {
  width: number;
  height: number;
}

export interface Coords {
  x: number;
  y: number;
}

export interface FilterRectangle {
  startX: number;
  endX: number;
  startY: number;
  endY: number;
}

export interface HeatmapData {
  x: number;
  y: number;
  value: number;
}

export interface VideoAttachment {
  AttachmentId: string;
  Clips: Clip[];
  CreateDate: string;
  Files: File[];
  Sentences: VideoAttachmentSentence[];
  Summary: VideoAttachmentSummary;
  TopicThreadId: string;
}

export interface Clip {
  AttachmentId: string;
  ClipId: string;
  CreateDate: string;
  EndTime: number;
  FirstWordId: string;
  IsPinned: boolean;
  LastWordId: string;
  Score: number;
  StartTime: number;
  Text: string;
  Thumbnail: string;
}

export interface VideoAttachmentSentence {
  AttachmentId: string;
  Duration: number;
  Position: number;
  Score: number;
  SentenceId: string;
  Time: number;
  TranslatedText: string;
  Words: Word[];
}

export interface Word {
  Duration: number;
  Name: string;
  Position: number;
  SentenceId: string;
  Time: number;
  WordId: string;
}

export interface VideoAttachmentSummary {
  AttachmentId: string;
  KeyPoints: KeyPoint[];
  ProbeSuggestions: string;
  SummaryError: string;
  SummaryId: string;
  SummaryText: string;
  SummaryTranslatedText: string;
  SummaryTranslationError: string;
  TargetType: number;
}

export interface KeyPoint {
  Duration: number;
  EndIndex: number;
  EndWordId: string;
  KeyPointText: string;
  KeyPointTranslatedText: string;
  OriginalSentences: OriginalSentence[];
  SentimentScore: number;
  StartIndex: number;
  StartWordId: string;
  Time: number;
}

export interface OriginalSentence {
  SentenceText: string;
  SentenceTranslatedText: string;
}
