import { useMemo } from 'react';
import { useAppSelector } from '@/hooks';
import { useGetActivityDetailsQuery } from '@/store/api';
import { ActivityPost } from '@/types';
import { QuestionScale } from './questionScale';
import styles from './cardScoreActivityPost.module.css';

interface Props {
  post: ActivityPost;
}

export const CardScoreActivityPost: React.FC<Props> = ({ post }) => {
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const { data: activityDetails } = useGetActivityDetailsQuery({
    discussionId,
    activityId: post.DiscussionTopicId,
  });

  const questionsList = useMemo(() => {
    if (activityDetails) {
      return activityDetails[0].CardScoreOptions?.CardScales.filter((cardScale) =>
        post.CardsScoreAnswers.some(
          (scoreAnswer) => scoreAnswer.CardsScaleId === cardScale.CardScaleId,
        ),
      ).sort((a, b) => a.Order - b.Order);
    }
    return [];
  }, [activityDetails, post.CardsScoreAnswers]);

  return (
    <>
      {questionsList && (
        <div className={styles.questionScalesList}>
          {questionsList.map((question) => (
            <QuestionScale key={question.CardScaleId} post={post} question={question} />
          ))}
        </div>
      )}
    </>
  );
};
