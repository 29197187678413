import { useMemo, useRef, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import cn from 'classnames';
import { isAfter, isBefore } from 'date-fns';
import { ReactComponent as EyeIcon } from '@/assets/icons/eye.svg';
import imagePlaceholder from '@/assets/imagePlaceholder.png';
import { ActivityDetailsDrawer } from '@/components/activityDetailsDrawer';
import { useAppSelector } from '@/hooks';
import { Activity } from '@/types';
import { scrollToTop } from '@/utils/scrollToTop';
import { ActivityIcon } from './activityIcon';
import styles from './activityList.module.css';

interface Props {
  layout: number;
  activity: Activity;
  onSelect: (id: string) => void;
  isSelected: boolean;
  rowLayout: boolean;
}

enum ActivityOpenStatus {
  OpeningSoon = 'openingSoon',
  Open = 'open',
  Closed = 'closed',
}

export const ActivityItem: React.FC<Props> = ({
  activity,
  layout,
  isSelected,
  onSelect,
  rowLayout,
}) => {
  const [showActivityDetails, setShowActivityDetails] = useState(false);
  const overviewButtonRef = useRef<HTMLButtonElement | null>(null);
  const heroImageIsShown = useAppSelector(
    (state) => state.userSettings.discussions?.HeroImageIsShown,
  );

  const handleActivityClick = () => {
    onSelect(activity.DiscussionTopicId);
    scrollToTop(heroImageIsShown ? 400 : 60);
  };

  const handleOverviewButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowActivityDetails(true);
  };

  const closeActivityOverview = () => {
    setShowActivityDetails(false);
  };

  const openStatus = useMemo(() => {
    const todayDate = new Date().toLocaleString('en-US', { timeZone: 'Europe/London' });
    const { VisibleBefore, VisibleAfter } = activity;

    if (VisibleAfter && isBefore(todayDate, new Date(VisibleAfter))) {
      return ActivityOpenStatus.OpeningSoon;
    }

    if (VisibleBefore && isAfter(todayDate, new Date(VisibleBefore))) {
      return ActivityOpenStatus.Closed;
    }

    return ActivityOpenStatus.Open;
  }, [activity]);

  const openStatusTitle = useMemo(() => {
    return {
      [ActivityOpenStatus.OpeningSoon]: 'Opening soon',
      [ActivityOpenStatus.Closed]: 'Closed',
      [ActivityOpenStatus.Open]: 'Open',
    }[openStatus];
  }, [openStatus]);

  const activityLayout = useMemo(() => (layout === 4 ? 3 : layout), [layout]);

  const activityImage = useMemo(() => {
    return {
      1: activity.SmallImageUrl,
      2: activity.MediumImageUrl,
      3: activity.LargeImageUrl,
    }[activityLayout];
  }, [activityLayout, activity.SmallImageUrl, activity.MediumImageUrl, activity.LargeImageUrl]);

  const backgroundImage = {
    backgroundImage: activityLayout === 3 ? `url(${activityImage || imagePlaceholder})` : 'unset',
  };

  return (
    <>
      <div className={cn({ [styles.activityWrapper]: rowLayout })}>
        <div
          className={cn(styles.layoutContainer, {
            [styles.layoutSmall]: activityLayout === 1,
            [styles.layoutMedium]: activityLayout === 2,
            [styles.layoutLarge]: activityLayout === 3,
            [styles.rowLayoutContainer]: rowLayout,
            [styles.selected]: isSelected,
          })}
          onClick={handleActivityClick}
        >
          <div className={styles.container} style={backgroundImage}>
            {activityLayout === 3 && !!activity.ToDoCount && (
              <div className={styles.notifications}>{activity.ToDoCount}</div>
            )}
            {activityLayout === 3 && <div className={styles.darkBackground} />}
            {[1, 2].includes(activityLayout) && (
              <div className={styles.activityImageWrapper}>
                <img
                  alt="Activity image"
                  className={styles.activityImage}
                  src={activityImage || imagePlaceholder}
                />
                {!!activity.ToDoCount && (
                  <div className={styles.notifications}>{activity.ToDoCount}</div>
                )}
              </div>
            )}

            <div className={styles.infoContainer}>
              <div className={styles.info}>
                {activityLayout === 0 && <ActivityIcon activity={activity} />}

                <div className={styles.text}>
                  <h3 className={styles.title}>{activity.Title}</h3>
                  <Tooltip title={activity.QuickSell}>
                    <p className={styles.description}>{activity.QuickSell}</p>
                  </Tooltip>
                </div>
                <Tooltip title="Show activity details">
                  <IconButton
                    className={styles.activityOverviewBtn}
                    onClick={handleOverviewButton}
                    ref={overviewButtonRef}
                  >
                    <EyeIcon />
                  </IconButton>
                </Tooltip>
              </div>

              <div className={styles.additional}>
                <div className={styles.statusesContainer}>
                  <div
                    className={cn(styles.status, {
                      [styles.statusPrivate]: activity.CommentsVisibility === 0,
                      [styles.statusOpeningSoon]: activity.CommentsVisibility === 1,
                      [styles.statusOpen]: activity.CommentsVisibility === 2,
                    })}
                  >
                    {['Private', 'Unbiased', 'Public'][activity.CommentsVisibility]}
                  </div>

                  <div
                    className={cn(styles.status, {
                      [styles.statusOpeningSoon]: openStatus === ActivityOpenStatus.OpeningSoon,
                      [styles.statusOpen]: openStatus === ActivityOpenStatus.Open,
                      [styles.statusClosed]: openStatus === ActivityOpenStatus.Closed,
                    })}
                  >
                    {openStatusTitle}
                  </div>
                </div>
                <div className={styles.posts}>
                  Posts: <span className={styles.postsValue}>{activity.TotalPostsCount || 0}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActivityDetailsDrawer
        activity={activity}
        isOpen={showActivityDetails}
        onClose={closeActivityOverview}
      />
    </>
  );
};
