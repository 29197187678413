import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag, User } from '@/types';

const initialState: User[] = [];

export const taskMembersSlice = createSlice({
  name: 'taskMembers',
  initialState,
  reducers: {
    setTaskMembers(state, { payload }: PayloadAction<User[]>) {
      return payload;
    },
    updateTaskMemberTags(state, { payload }: PayloadAction<{ taskMemberId: string; tags: Tag[] }>) {
      return state.map((user) =>
        user.TaskMemberId === payload.taskMemberId ? { ...user, Tags: payload.tags } : user,
      );
    },
  },
});
