import { useMemo } from 'react';
import { useAppSelector } from '@/hooks';
import { PostTab } from '@/pages/activities/activityPosts';
import { ActivityPost } from '@/types';

const sortByDate = (a: ActivityPost, b: ActivityPost) =>
  new Date(b.CreateDate).getTime() - new Date(a.CreateDate).getTime();

export const usePosts = (activeTab = PostTab.All) => {
  const posts = useAppSelector((state) => state.posts);

  return useMemo(() => {
    let postsCopy = [...posts];
    const pinnedPosts: ActivityPost[] = [];
    const unpinnedPosts: ActivityPost[] = [];

    if (activeTab === PostTab.Unread) {
      postsCopy = postsCopy.filter((post) => post.IsInToDoList);
    }

    postsCopy.forEach((post) => {
      if (post.IsPinned) {
        pinnedPosts.push(post);
      } else {
        unpinnedPosts.push(post);
      }
    });

    return {
      pinnedPosts: pinnedPosts.sort(sortByDate),
      unpinnedPosts: unpinnedPosts.sort(sortByDate),
    };
  }, [activeTab, posts]);
};
