export const NOTIFICATION_DURATION = 6000;

export const userRoles: Readonly<Record<number, string>> = {
  0: 'Unknown',
  1: 'Participant',
  4: 'Complete',
  6: 'Moderator',
  7: 'Observer',
};

export const REPLY_SUGGESTIONS_COLLAPSE_DURATION = 600;

export const tagColors = [
  '#B44C9F',
  '#C2543E',
  '#D4892C',
  '#D8BF3A',
  '#54AD41',
  '#59ADBC',
  '#577BDD',
  '#8B3AE1',
];
