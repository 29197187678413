import { VideoAttachment } from '@/types';
import { api } from '.';

interface videoAttachmentsArgs {
  discussionId: string;
  threadId: string;
}

export const { useGetVideosWithTranscriptionQuery } = api.injectEndpoints({
  endpoints: (builder) => ({
    getVideosWithTranscription: builder.query<VideoAttachment[], videoAttachmentsArgs>({
      query: ({ discussionId, threadId }) => ({
        url: `v3/videotools/discussions/${discussionId}/threads/${threadId}/videos`,
        method: 'GET',
      }),
    }),
  }),
});
