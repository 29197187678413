import { Video } from '@/components/videoList/video';
import { VideoAttachment } from '@/types';

interface Props {
  videoAttachments: VideoAttachment[];
}

export const VideoList: React.FC<Props> = ({ videoAttachments }) => {
  return (
    <div>
      {videoAttachments.map((videoAttachment) => (
        <Video key={videoAttachment.AttachmentId} videoAttachment={videoAttachment} />
      ))}
    </div>
  );
};
