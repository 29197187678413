import { RefObject } from 'react';
import { useClickOutside } from '@/hooks';
import { TagRowItem } from '@/pages/activities/activityPosts/tags/tagRowItem';
import { Tag } from '@/types';
import styles from './filterPostByTagPopup.module.css';

interface Props {
  tags: Tag[];
  selectedTags: Tag[];
  onClose: () => void;
  onToggle: (tag: Tag, checked: boolean) => void;
  outsideTriggerBtnRef: RefObject<HTMLElement>;
}

export const FilterPostByTagPopup: React.FC<Props> = ({
  tags,
  onClose,
  onToggle,
  selectedTags,
  outsideTriggerBtnRef,
}) => {
  const popupRef = useClickOutside(onClose, outsideTriggerBtnRef);
  return (
    <div className={styles.filterPostByTagPopup} ref={popupRef}>
      <div className={styles.popupTitle}>Tags</div>
      <div className={styles.tagList}>
        {tags.map((tag) => (
          <TagRowItem
            checked={selectedTags.some(({ ContentTagId }) => ContentTagId === tag.ContentTagId)}
            key={tag.ContentTagId}
            onToggle={onToggle}
            tag={tag}
          />
        ))}
      </div>
    </div>
  );
};
