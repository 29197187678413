export const calculateTextColor = (backgroundColor: string): string => {
  if (!backgroundColor) {
    return '#fff';
  }

  let useBlack = false;
  let colorArr: number[] = [];

  if (backgroundColor.length === 4) {
    const m = backgroundColor.match(/^#([0-9a-f]{3})$/i)?.[1];
    if (m) {
      // In three-character format, each value is multiplied by 0x11 to give an
      // even scale from 0x00 to 0xff
      colorArr = [
        parseInt(m.charAt(0), 16) * 0x11,
        parseInt(m.charAt(1), 16) * 0x11,
        parseInt(m.charAt(2), 16) * 0x11,
      ];
    }
  } else if (backgroundColor.length === 7) {
    const m = backgroundColor.match(/^#([0-9a-f]{6})$/i)?.[1];
    if (m) {
      colorArr = [
        parseInt(m.slice(0, 2), 16),
        parseInt(m.slice(2, 4), 16),
        parseInt(m.slice(4, 6), 16),
      ];
    }
  } else {
    const m = backgroundColor.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
    if (m) {
      colorArr = [parseInt(m[1], 10), parseInt(m[2], 10), parseInt(m[3], 10)];
    }
  }

  if (colorArr.length) {
    const gamma = 2.2;
    const l =
      0.2126 * Math.pow(colorArr[0] / 255, gamma) +
      0.7152 * Math.pow(colorArr[1] / 255, gamma) +
      0.0722 * Math.pow(colorArr[2] / 255, gamma);
    useBlack = l > 0.5;
  }

  return useBlack ? '#000' : '#fff';
};
