import { useEffect, useMemo, useRef } from 'react';
import { Pause, PlayArrow } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import cn from 'classnames';
import { VideoAttachmentSentence } from '@/types';
import { SubtitleWord } from './subtitleWord';
import styles from './transcriptSentence.module.css';

interface Props {
  sentence: VideoAttachmentSentence;
  onSentencePlay: (startTime: number, isCurrentSentence: boolean) => void;
  progressTime: number;
  isVideoPlaying: boolean;
  selectionStart: number | null;
  selectionEnd: number | null;
}

export const TranscriptSentence: React.FC<Props> = ({
  sentence,
  onSentencePlay,
  progressTime,
  isVideoPlaying,
  selectionStart,
  selectionEnd,
}) => {
  const sentenceRef = useRef<HTMLDivElement>(null);

  const sentenceStartTime = useMemo(() => {
    const sentenceTimeInSeconds = sentence.Time / 1000;

    const seconds = Math.round(sentenceTimeInSeconds % 60);
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
    const minutes = Math.floor((sentenceTimeInSeconds / 60) % 60);
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const hours = Math.floor(sentenceTimeInSeconds / 3600);
    const formattedHours = hours < 10 ? '0' + hours : hours;

    return hours
      ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
      : `${formattedMinutes}:${formattedSeconds}`;
  }, [sentence.Time]);

  const isSentenceActive = useMemo(() => {
    return progressTime > sentence.Time / 1000;
  }, [progressTime, sentence.Time]);

  const isCurrentSentence = useMemo(
    () =>
      progressTime > sentence.Time / 1000 &&
      progressTime < (sentence.Time + sentence.Duration) / 1000,
    [progressTime, sentence.Time, sentence.Duration],
  );

  useEffect(() => {
    if (isCurrentSentence && sentenceRef.current) {
      const parentElem = sentenceRef.current.parentElement;
      if (parentElem) {
        parentElem.scroll({
          top: sentenceRef.current.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }, [isCurrentSentence]);

  return (
    <div className={styles.sentenceWrapper} ref={sentenceRef}>
      <div className={styles.sentence}>
        <div className={styles.sentenceControls}>
          <IconButton
            className={cn(styles.playSentenceBtn, {
              [styles.playSentenceBtnActive]: isCurrentSentence,
            })}
            onClick={() =>
              onSentencePlay(Math.floor((sentence.Time / 1000) * 10) / 10, isCurrentSentence)
            }
          >
            {isVideoPlaying && isCurrentSentence ? <Pause /> : <PlayArrow />}
          </IconButton>
          <div className={styles.sentenceStartTime}>{sentenceStartTime}</div>
        </div>
        <div className={cn(styles.sentenceTextWrapper)}>
          <div className={styles.sentenceText}>
            {sentence.Words.map((word) => (
              <SubtitleWord
                key={word.WordId}
                progressTime={progressTime}
                selectionEnd={selectionEnd}
                selectionStart={selectionStart}
                word={word}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={cn(styles.sentenceScale, { [styles.sentenceScaleActive]: isSentenceActive })}
      />
    </div>
  );
};
