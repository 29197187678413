import { createSlice } from '@reduxjs/toolkit';

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    isOpen: false,
  },
  reducers: {
    toggleDrawer(state) {
      state.isOpen = !state.isOpen;
    },
    closeDrawer(state) {
      state.isOpen = false;
    },
  },
});
