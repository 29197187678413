import cn from 'classnames';
import styles from './overviewTab.module.css';

export const OverviewTab: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Some Title</div>
      <div className={styles.description}>
        Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown
        printer took a galley of type and scrambled it to make a type specimen book. It has survived
        not only five centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
        containing Lorem Ipsum passages,
      </div>
      <ul className={styles.features}>
        <li className={cn(styles.feature, styles.featurePositive)}>
          Lorem Ipsum has been the industry
        </li>
        <li className={cn(styles.feature, styles.featureNegative)}>
          Lorem Ipsum has been the industry standard
        </li>
        <li className={styles.feature}>Lorem Ipsum has been</li>
      </ul>
    </div>
  );
};
