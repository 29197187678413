import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Activity } from '@/types';

const initialState: Activity[] = [];

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    setActivities(state, { payload }: PayloadAction<Activity[]>) {
      return payload;
    },
  },
});
