import { useEffect, useState, useTransition } from 'react';
import cn from 'classnames';
import { FilterPostByTag } from '@/components/filterPostByTag';
import { Loading } from '@/components/loading';
import { SectionHeaderTab } from '@/components/sectionHeaderTab';
import { useActions, useAppSelector, usePosts } from '@/hooks';
import { useGetActivityPostsQuery, useGetTaskMembersQuery } from '@/store/api';
import { useGetRecentTagsQuery } from '@/store/api/tags';
import { Tag } from '@/types';
import { ActivityCard } from './activityCard';
import styles from './activityCards.module.css';

export enum PostTab {
  All = 'All',
  Unread = 'Unread',
}

interface Props {
  rowLayout?: boolean;
}

export const ActivityPosts: React.FC<Props> = ({ rowLayout }) => {
  const actions = useActions();
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const unreadPosts = useAppSelector((state) => state.selectedActivity.unreadPosts);

  const [activeTab, setActiveTab] = useState(PostTab.All);
  const { pinnedPosts, unpinnedPosts } = usePosts(activeTab);
  const [isPending, startTransition] = useTransition();
  const selectedActivityId = useAppSelector((state) => state.selectedActivity.id);

  const { data: userTags = [] } = useGetRecentTagsQuery(
    { discussionId, context: 'participants' },
    { skip: !discussionId },
  );

  const { data: activityPosts } = useGetActivityPostsQuery(
    { discussionId, activityId: selectedActivityId },
    { skip: !discussionId || !selectedActivityId },
  );

  const onTabClick = (tab: PostTab) => {
    startTransition(() => {
      setActiveTab(tab);
    });
  };

  const { data: taskMembers } = useGetTaskMembersQuery({ discussionId }, { skip: !discussionId });

  useEffect(() => {
    if (!taskMembers) {
      return;
    }

    actions.setTaskMembers(taskMembers);
  }, [taskMembers, actions]);

  const handlePostsFiltering = (selectedTags: Tag[]) => {
    if (!activityPosts || !taskMembers) {
      return;
    }

    const filteredPosts = activityPosts.filter((post) => {
      const postCreatorTags = taskMembers.find(
        (user) => user.PersonId === post.ThreadCreatorId,
      )?.Tags;

      return selectedTags.every((selectedTag) =>
        postCreatorTags?.find((tag) => tag.ContentTagId === selectedTag.ContentTagId),
      );
    });

    actions.setPosts(filteredPosts);
  };

  return (
    <div className={styles.activityPostsWrapper}>
      <div className={styles.postsHeader}>
        <div className={styles.postsTabs}>
          {[PostTab.All, PostTab.Unread].map((tab) => (
            <SectionHeaderTab
              counter={tab === PostTab.Unread ? unreadPosts : undefined}
              isActive={tab === activeTab}
              key={tab}
              onClick={onTabClick}
              tab={tab}
            />
          ))}
        </div>
        <FilterPostByTag onFilter={handlePostsFiltering} tags={userTags} />
      </div>

      {isPending ? (
        <Loading className={styles.loader} />
      ) : pinnedPosts.length || unpinnedPosts.length ? (
        <>
          {!!pinnedPosts.length && (
            <div className={cn(styles.postList, styles.pinnedPosts)}>
              {pinnedPosts.map((post) => (
                <ActivityCard key={post.TopicThreadId} post={post} rowLayout={rowLayout} />
              ))}
            </div>
          )}
          {!!unpinnedPosts.length && (
            <div className={styles.postList}>
              {unpinnedPosts.map((post) => (
                <ActivityCard key={post.TopicThreadId} post={post} rowLayout={rowLayout} />
              ))}
            </div>
          )}
        </>
      ) : (
        <h2 className={styles.noPostsMessage}>No posts</h2>
      )}
    </div>
  );
};
