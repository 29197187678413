import { useEffect, useLayoutEffect, useMemo, useRef, useState, useTransition } from 'react';
import { toast } from 'react-toastify';
import { Close, FirstPage as FirstPageIcon } from '@mui/icons-material';
import { Button, Collapse, IconButton } from '@mui/material';
import cn from 'classnames';
import { Loading } from '@/components/loading';
import { useAppSelector, useWindowDimensions } from '@/hooks';
import { Pin } from '@/pages/activities/pinsSection/pinsImage/pin';
import { ActivityPost, Coords, FilterRectangle, ImageSize } from '@/types';
import styles from './pinsImage.module.css';

interface Props {
  imageUrl: string;
  pinPosts: ActivityPost[];
  onFilter: (filteredPosts: ActivityPost[]) => void;
}

export const PinsImage: React.FC<Props> = ({ imageUrl, pinPosts, onFilter }) => {
  const { width: windowWidth } = useWindowDimensions();
  const imageRef = useRef<HTMLImageElement>(null);
  const [showImage, setShowImage] = useState(true);
  const [isMainImageLoaded, setIsMainImageLoaded] = useState(false);
  const [imageSize, setImageSize] = useState<ImageSize | null>(null);
  const [isFilteringActive, setIsFilteringActive] = useState(false);
  const [startPointCoords, setStartPointCoords] = useState<Coords | null>(null);
  const [endPointCoords, setEndPointCoords] = useState<Coords | null>(null);
  const [filterRectangle, setFilterRectangle] = useState<FilterRectangle | null>(null);
  const [isPending, startTransition] = useTransition();
  const discussions = useAppSelector((state) => state.userSettings.discussions);
  const isRowLayout = discussions?.ActivitiesLayout === 4;

  useLayoutEffect(() => {
    if (imageRef.current && isMainImageLoaded) {
      const width = imageRef.current.getBoundingClientRect().width;
      const height = imageRef.current.getBoundingClientRect().height;
      setImageSize({ width, height });
    }
  }, [windowWidth, isMainImageLoaded]);

  //checking if pin coordinates are between rectangle start and end coordinates
  useEffect(() => {
    const filteredPosts: ActivityPost[] = [];
    if (filterRectangle) {
      const { startX, endX, startY, endY } = filterRectangle;
      pinPosts.forEach((post) => {
        const isXBetween =
          post.HeatMapX > Math.min(startX, endX) && post.HeatMapX < Math.max(startX, endX);
        const isYBetween =
          post.HeatMapY > Math.min(startY, endY) && post.HeatMapY < Math.max(startY, endY);

        if (isXBetween && isYBetween) {
          filteredPosts.push(post);
        }
      });
      onFilter(filteredPosts);
    }
  }, [filterRectangle, pinPosts, onFilter]);

  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
    if (isPending) {
      return;
    }

    setStartPointCoords(null);
    setEndPointCoords(null);
    setFilterRectangle(null);

    if (imageSize) {
      setIsFilteringActive(true);
      setStartPointCoords({
        x: e.nativeEvent.offsetX / imageSize.width,
        y: e.nativeEvent.offsetY / imageSize.height,
      });
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLImageElement>) => {
    if (isFilteringActive && imageSize) {
      setEndPointCoords({
        x: e.nativeEvent.offsetX / imageSize.width,
        y: e.nativeEvent.offsetY / imageSize.height,
      });
    }
  };

  const handleMouseUp = () => {
    setIsFilteringActive(false);

    if (!endPointCoords) {
      resetPostsFiltering();
    }

    if (startPointCoords && endPointCoords && imageSize) {
      setFilterRectangle({
        startX: startPointCoords.x,
        endX: endPointCoords.x,
        startY: startPointCoords.y,
        endY: endPointCoords.y,
      });
    }

    setStartPointCoords(null);
    setEndPointCoords(null);
  };

  // calculating filtering rectangle css properties
  const rectangleStyles = useMemo(() => {
    let coords = { startX: 0, endX: 0, startY: 0, endY: 0 };

    if (filterRectangle) {
      coords = filterRectangle;
    }

    if (startPointCoords && endPointCoords) {
      coords = {
        startX: startPointCoords.x,
        endX: endPointCoords.x,
        startY: startPointCoords.y,
        endY: endPointCoords.y,
      };
    }

    if (imageSize) {
      const width =
        Math.abs(coords.endX * imageSize.width - coords.startX * imageSize.width) + 'px';
      const height =
        Math.abs(coords.endY * imageSize.height - coords.startY * imageSize.height) + 'px';
      let top;
      let left;

      if (coords.endX > coords.startX) {
        left = coords.startX * imageSize.width + 'px';
      } else {
        left =
          coords.startX * imageSize.width -
          (coords.startX * imageSize.width - coords.endX * imageSize.width) +
          'px';
      }

      if (coords.endY > coords.startY) {
        top = coords.startY * imageSize.height + 'px';
      } else {
        top =
          coords.startY * imageSize.height -
          (coords.startY * imageSize.height - coords.endY * imageSize.height) +
          'px';
      }

      return {
        width,
        height,
        top,
        left,
      };
    }

    return {};
  }, [startPointCoords, endPointCoords, imageSize, filterRectangle]);

  const resetPostsFiltering = () => {
    startTransition(() => {
      setStartPointCoords(null);
      setEndPointCoords(null);
      setFilterRectangle(null);
      onFilter([]);
    });
  };

  const isFilterRectangle = Boolean(
    !isPending && (filterRectangle || (startPointCoords && endPointCoords)),
  );

  return (
    <>
      {!isMainImageLoaded && <Loading />}
      <div
        className={cn(styles.header, {
          [styles.hide]: !isMainImageLoaded,
          [styles.headerRowLayout]: isRowLayout,
        })}
      >
        <Collapse in={showImage}>
          <div
            className={styles.mainPinsImageContainer}
            onMouseLeave={() => {
              if (isFilteringActive) {
                toast.warning('This area is not selectable');
              }
              setIsFilteringActive(false);
              setStartPointCoords(null);
              setEndPointCoords(null);

              if (!filterRectangle) {
                onFilter([]);
              }
            }}
          >
            <img
              alt="pins map"
              className={cn(styles.mainPinsImage, { [styles.mainPinsImagePending]: isPending })}
              draggable="false"
              onLoad={() => setIsMainImageLoaded(true)}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              ref={imageRef}
              src={imageUrl}
            />

            {imageSize &&
              pinPosts.map((post) => (
                <Pin imageSize={imageSize} key={post.TopicThreadId} post={post} />
              ))}
            {isFilterRectangle && (
              <div
                className={styles.selectRectangle}
                onMouseUp={handleMouseUp}
                style={rectangleStyles}
              >
                {filterRectangle && (
                  <IconButton
                    className={styles.resetFilteringButton}
                    onClick={resetPostsFiltering}
                    onMouseUp={(e) => e.stopPropagation()}
                  >
                    <Close />
                  </IconButton>
                )}
              </div>
            )}
          </div>
        </Collapse>
        <Button
          className={styles.pinsImageToggleBtn}
          disableRipple
          onClick={() => setShowImage((prev) => !prev)}
          variant="text"
        >
          <FirstPageIcon className={cn(styles.iconHide, { [styles.iconHideActive]: showImage })} />
          {showImage ? 'Hide' : 'Show pins image'}
        </Button>
      </div>
    </>
  );
};
