import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import styles from './noMatch.module.css';

export const NoMatch = () => {
  return (
    <div className={styles.errorContainer}>
      <img alt="page not found" src="https://i.imgur.com/JqX71P1.png" />
      <p>
        <Button color="success" variant="outlined">
          <Link className={styles.homePageLink} to="/">
            Open home page
          </Link>
        </Button>
      </p>
    </div>
  );
};
