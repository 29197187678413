import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { App } from './app';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { theme } from './theme';
import 'react-toastify/dist/ReactToastify.css';
import './global.css';

const cache = createCache({
  key: 'css',
  prepend: true,
});

const toastConfig: ToastContainerProps = {
  position: 'top-right',
  autoClose: 2000,
  closeOnClick: true,
  pauseOnHover: true,
};

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);
root.render(
  <CacheProvider value={cache}>
    <StylesProvider>
      <React.StrictMode>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <App />
              <ToastContainer {...toastConfig} />
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </React.StrictMode>
    </StylesProvider>
  </CacheProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
