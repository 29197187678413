import { useRef, useState } from 'react';
import cn from 'classnames';
import { VideoAttachmentSentence } from '@/types';
import { TranscriptSentence } from './transcriptSentence';
import styles from './videoTranscript.module.css';

interface Props {
  className?: string;
  transcriptSentences: VideoAttachmentSentence[];
  videosSectionWrapperRef?: React.RefObject<HTMLDivElement>;
  isPlaying: boolean;
  progressTime: number;
  onSentencePlay: (startTime: number, isCurrentSentence: boolean) => void;
}

export const VideoTranscript: React.FC<Props> = ({
  className,
  transcriptSentences,
  isPlaying,
  progressTime,
  onSentencePlay,
}) => {
  const sentenceListRef = useRef<HTMLDivElement>(null);
  const [selectionStart, setSelectionStart] = useState<number | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<number | null>(null);
  const handleSelection = () => {
    const selection = window.getSelection();

    if (!selection || selection.type === 'None') {
      return;
    }

    const selectionStart = Number(selection.getRangeAt(0).startContainer.parentElement?.id);
    const selectionEnd = Number(selection.getRangeAt(0).endContainer.parentElement?.id);

    if (selectionStart === selectionEnd) {
      return;
    }

    setSelectionStart(Number(selection.getRangeAt(0).startContainer.parentElement?.id));
    setSelectionEnd(Number(selection.getRangeAt(0).endContainer.parentElement?.id));
    selection.removeAllRanges();
  };
  return (
    <div
      className={cn(styles.sentenceList, className)}
      onPointerUp={handleSelection}
      ref={sentenceListRef}
    >
      {transcriptSentences.map((sentence) => (
        <TranscriptSentence
          isVideoPlaying={isPlaying}
          key={sentence.SentenceId}
          onSentencePlay={onSentencePlay}
          progressTime={progressTime}
          selectionEnd={selectionEnd}
          selectionStart={selectionStart}
          sentence={sentence}
        />
      ))}
    </div>
  );
};
