import React from 'react';
import cn from 'classnames';
import { ReactComponent as Like } from '@/assets/icons/likeTag.svg';
import { Tag } from '@/types';
import styles from './pinPostTag.module.css';

interface Props {
  tag: Tag;
}

export const PinPostTag: React.FC<Props> = ({ tag }) => {
  const isIconInTag = ['i like this', 'i dislike this'].includes(tag.TagValue.toLowerCase());
  return (
    <div
      className={styles.tag}
      style={{
        borderColor: tag.TagColor,
        color: tag.TagColor,
        backgroundColor: tag.TagColor,
      }}
    >
      {tag.TagValue}
      {isIconInTag && (
        <Like
          className={cn({
            [styles.dislikeIcon]: tag.TagValue.toLowerCase() === 'i dislike this',
          })}
          style={{ color: tag.TagColor }}
        />
      )}
    </div>
  );
};
