import { IconButton } from '@mui/material';
// import { ReactComponent as DownloadIcon } from '@/assets/icons/download.svg';
import { ReactComponent as FullScreenIcon } from '@/assets/icons/fullScreen.svg';
// import { ReactComponent as OpenPostIcon } from '@/assets/icons/openPost.svg';
import { CreatorBadge } from '@/components/creatorBadge/creatorBadge';
import { ImageObject } from '@/types';
// import { downloadFile } from '@/utils/downloadFile';
import styles from './imagesSection.module.css';

interface Props {
  image: ImageObject;
  onLoad: () => void;
  onClick: () => void;
}

export const ImageCard: React.FC<Props> = ({ image, onLoad, onClick }) => {
  const { postCreatorAvatar, postCreatorName, CreateDate } = image;

  return (
    <div className={styles.imageCard}>
      <img
        alt="attached image"
        className={styles.image}
        loading="lazy"
        onClick={onClick}
        onLoad={onLoad}
        src={image.Link}
      />
      <CreatorBadge avatar={postCreatorAvatar} date={CreateDate} name={postCreatorName} />
      <div className={styles.imageControls}>
        <IconButton className={styles.iconButton} onClick={onClick}>
          <FullScreenIcon />
        </IconButton>
        {/*
        <IconButton
          className={styles.iconButton}
          onClick={() => downloadFile(image.Link, image.OriginalName)}
        >
          <DownloadIcon />
        </IconButton>
        <IconButton className={styles.iconButton}>
          <OpenPostIcon />
        </IconButton>
        */}
      </div>
    </div>
  );
};
