import { SelectSectionButton } from '@/components/topSection/selectSectionButton';
import { useClickOutside } from '@/hooks';
import { ActivitySection, ActivityTabContentAvailability } from '@/types';
import styles from './selectSectionModal.module.css';

interface Props {
  activityTabContentAvailability: ActivityTabContentAvailability;
  onClose: () => void;
  sections: ActivitySection[];
  onSectionSelect: (section: ActivitySection) => void;
  selectSectionBtnRef: React.RefObject<HTMLButtonElement>;
}

export const SelectSectionModal: React.FC<Props> = ({
  activityTabContentAvailability,
  onClose,
  onSectionSelect,
  sections,
  selectSectionBtnRef,
}) => {
  const modalRef = useClickOutside(onClose, selectSectionBtnRef);

  return (
    <div className={styles.selectSectionModal} ref={modalRef}>
      {sections.map((section) => (
        <SelectSectionButton
          isDisabled={!activityTabContentAvailability[section]}
          key={section}
          onSectionSelect={onSectionSelect}
          section={section}
        />
      ))}
    </div>
  );
};
