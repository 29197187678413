import { useState } from 'react';
import { ActivityPost } from '@/types';
import { Tags } from './tags';

interface Props {
  post: ActivityPost;
}

export const PostContentTags: React.FC<Props> = ({ post }) => {
  const [contentTags, setContentTags] = useState(post.Tags);

  return (
    <Tags
      apiId={post.TopicThreadId}
      context="thread"
      onChange={setContentTags}
      tags={contentTags}
    />
  );
};
